import styled from 'styled-components';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useState } from 'react';
import UnitSwitch from '../../../components/unitSwitch/UnitSwitch';
import { OfficialPriceDataProps } from '../../data';
import colors from '../../../../color';

const OfficialPriceTransition = ({
  data,
}: {
  data: OfficialPriceDataProps;
}) => {
  const [unit, setUnit] = useState('평');

  const chartData = data.list.map((item) => ({
    year: item.year,
    price: unit === '평' ? item.pricePerPyeong : item.price,
  }));

  const tableData = data.list.map((item) => [
    item.year,
    unit === '평'
      ? `${item.pricePerPyeong.toLocaleString()}원`
      : `${item.price.toLocaleString()}원`,
  ]);

  const table = {
    th: ['기준연도', '공시지가 (단위: ' + (unit === '평' ? '평' : 'm²') + ')'],
    tb: tableData,
  };

  return (
    <AnalysisInfoContainer>
      <HeaderBox>공시지가 추이</HeaderBox>
      <AnalysisDataBox>
        <GraphBox>
          <UnitSwitchWrapper>
            <UnitSwitch unit={unit} setUnit={setUnit}></UnitSwitch>
          </UnitSwitchWrapper>
          <GraphDataBox>
            <ResponsiveContainer width="96%" height={180}>
              <LineChart
                data={chartData}
                margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="0.6">
                    <stop offset="5%" stopColor="#53df89" stopOpacity={1} />
                    <stop offset="95%" stopColor="#53df89" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid stroke="#ccc" strokeWidth={1} vertical={false} />
                <XAxis
                  dataKey="year"
                  tick={{
                    color: 'rgba(23,30,38,0.56)',
                    fontSize: 11,
                    fontFamily: 'Pretendard-Medium',
                    fontWeight: '400',
                  }}
                  tickLine={false}
                  axisLine={false} // X축 선을 제거
                  interval={2}
                  padding={{ right: 20 }} // 오른쪽 여백 추가
                />
                <YAxis
                  axisLine={false} // Y축 선을 제거
                  tick={{
                    color: 'rgba(23,30,38,0.56)',
                    fontSize: 11,
                    fontFamily: 'Pretendard-Medium',
                    fontWeight: '400',
                  }}
                  tickLine={false}
                  tickFormatter={(value) => value.toLocaleString()}
                />
                <Tooltip
                  labelFormatter={(label) => `${label}년`}
                  formatter={(value) => `${value.toLocaleString()}원`}
                  contentStyle={{
                    fontFamily: 'Pretendard-Medium', // 폰트 스타일 지정
                    fontSize: '12px', // 폰트 크기 지정
                    color: '#171E26', // 폰트 색상 지정
                  }}
                  labelStyle={{
                    fontFamily: 'Pretendard-Bold', // 라벨 폰트 스타일
                    fontSize: '12px', // 라벨 폰트 크기
                    color: '#171E26', // 라벨 폰트 색상
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="price"
                  stroke="#53df89"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                  name="공시지가"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </GraphDataBox>
        </GraphBox>
        <BoxTableWrapper>
          <BoxTable3 table={table}></BoxTable3>
        </BoxTableWrapper>
      </AnalysisDataBox>
      <GrowthBoxWrapper>
        <GrowthBox>
          최근 {data.list.length}년(
          {data.list[0].year}-{data.list[data.list.length - 1].year}
          ) <br />
          공시지가 연평균 성장률은
          <br />
          {data.CAGR}% 입니다.
        </GrowthBox>
      </GrowthBoxWrapper>
    </AnalysisInfoContainer>
  );
};

export default OfficialPriceTransition;

const AnalysisInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 579px;
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-bottom: 0px;
  color: #171e26;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const AnalysisDataBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 431px;
  padding: 24px 16px;
  padding-top: 0px;
  gap: 16px;
`;

const GraphBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 205px;
  justify-content: space-between;
`;

const UnitSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 51px;
  height: 24px;
  align-items: flex-end;
  align-self: flex-end;
`;

const GraphDataBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 181px;
`;

const BoxTableWrapper = styled.div`
  max-height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  /* 웹킷 스크롤바 스타일 */
  ::-webkit-scrollbar {
    width: 2px; /* 세로 스크롤바의 두께 */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* 스크롤바 색상 */
    border-radius: 4px; /* 둥글게 만들기 */
  }

  /* Firefox 스크롤바 스타일 */
  scrollbar-width: thin; /* 세로 스크롤바의 두께 */
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* 스크롤바 색상 */
`;

const GrowthBox = styled.div`
  width: 100%;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: #eaf8f2;
  color: ${colors.primary500};
  font-size: 14px;
  font-family: Pretendard-Medium;
  line-height: 20px;
  word-wrap: break-word;
`;

const GrowthBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 24px;
`;

interface TableProps {
  th: Array<string | number | undefined>;
  tb: Array<Array<string | number | undefined>>;
}

interface BoxTableProps {
  table: TableProps;
  right?: boolean;
  right2?: boolean;
}

const BoxTableCommon: React.FC<BoxTableProps> = ({ table, right, right2 }) => (
  <table
    className="box-table"
    style={{ borderCollapse: 'collapse', width: '100%' }}
  >
    <thead style={{ borderRadius: '8px', background: '#F7F8F9' }}>
      <tr>
        {table.th.map((el, i) => (
          <th
            key={i}
            style={{
              fontSize: '14px',
              fontFamily: 'Pretendard-Medium',
              color: i === 1 ? '#171E26' : 'rgba(23,30,38,0.78)',
              padding: i === 0 ? '4px 16px' : '4px 16px 4px 0',
            }}
          >
            {el}
          </th>
        ))}
      </tr>
    </thead>
    <tbody style={{ maxHeight: '148px' }}>
      {table.tb.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((cell, cellIndex) => (
            <td
              key={cellIndex}
              style={{
                fontSize: '14px',
                fontFamily: 'Pretendard-Medium',
                fontWeight: 500,
                color: cellIndex === 1 ? '#171E26' : '#171E26C7',
                padding: '4px 8px',
                textAlign: 'center',
              }}
            >
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const BoxTable3: React.FC<BoxTableProps> = ({ table, right2, right }) => (
  <BoxTableCommon table={table} right={right} />
);
