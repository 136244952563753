import { useNavigate } from 'react-router-dom';
import './PopularList.css';
import { useMapContext } from '../../../contexts/MapContext';
import SearchService from '../../../service/Search';

interface PopularSearchList {
  region: string;
  state: string;
  pnu: string;
}

interface PopularListProps {
  popularSearchList: PopularSearchList[];
}

const getState = (state: string) => {
  switch (state) {
    case 'up':
      return { stateSymbol: '▲', state: 'Up' };
    case 'down':
      return { stateSymbol: '▼', state: 'Down' };
    case 'stay':
      return { stateSymbol: '⏤', state: 'Stay' };
    case 'new':
      return { stateSymbol: 'NEW', state: 'New' };
    default:
      return { stateSymbol: state, state: 'black' }; // 기본 색상 및 예외 처리
  }
};

const formatDate = () => {
  const date = new Date();

  // 년, 월, 일
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 1월이 0이기 때문에 +1
  const day = String(date.getDate()).padStart(2, '0');

  // 시간, 분
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // 최종 포맷
  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

const PopularList = ({ popularSearchList }: PopularListProps) => {
  const currentDate = formatDate();
  const { setLatLng, setPnu, setZoomLevel } = useMapContext();
  const nav = useNavigate();

  const getCoord = async (pnu: string) => {
    let res = await SearchService.coord(pnu);
    if (res.length > 0) {
      setLatLng({ lat: res[0].lat, lng: res[0].lng });
      setPnu(pnu);
      setZoomLevel(16);
    }
    nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
  };

  return (
    <div className="PopularBox">
      <div className="PopularBox_top_bar">
        <div className="PopularBox_top_bar_title">인기검색</div>
        <div className="PopularBox_top_bar_date">{`${currentDate} 업데이트`}</div>
      </div>
      <div className="PopularSearchListBox">
        <div className="PopularSearchList">
          {popularSearchList.slice(0, 5).map((item, index) => {
            const { stateSymbol, state } = getState(item.state);
            return (
              <div
                className="PopularItem"
                key={index}
                onClick={() => {
                  getCoord(item.pnu);
                }}
              >
                <div className="PopularItem_Data">
                  <div className="PopularItem_Data_No">{index + 1}</div>{' '}
                  <div className="PopularItem_Data_Region">{item.region}</div>
                </div>
                <div className={`PopularItem_State PopularItem_State_${state}`}>
                  {stateSymbol}
                </div>
              </div>
            );
          })}
        </div>
        <div className="PopularSearchList">
          {popularSearchList.slice(5, 10).map((item, index) => {
            const { stateSymbol, state } = getState(item.state);
            return (
              <div
                className="PopularItem"
                key={index}
                onClick={() => {
                  getCoord(item.pnu);
                }}
              >
                <div className="PopularItem_Data">
                  <div className="PopularItem_Data_No">{index + 6}</div>{' '}
                  <div className="PopularItem_Data_Region">{item.region}</div>
                </div>
                <div className={`PopularItem_State PopularItem_State_${state}`}>
                  {stateSymbol}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopularList;
