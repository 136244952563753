import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Main from './page/main/Main';
import Search from './page/search/Search';
import More from './page/more/More';
import Detail from './page/detail/Detail';
import Brokerage from './page/brokerage/Brokerage';
import PensionInput from './page/detail/components/pension/input/PnesionInput';
import PensionReportMain from './page/detail/components/pension/reportResultPension/Main';
import HouseInput from './page/detail/components/house/input/house/report/Report';
import HouseReportMain from './page/detail/components/house/reportResultHouse/Main';
import Property from './page/property/Property';
import LandIncomeInput from './page/detail/components/landIncome/input/LandIncomeInput';
import LandIncomeResult from './page/detail/components/landIncome/result/LandIncomeResult';
import Login from './page/login/Login';
import SignUp from './page/signup/Signup';
import SignUpSns from './page/signup/SignUpSns';
import SignUpEmail from './page/signup/SignUpEmail';
import MyChon from './page/mychon/MyChon';
import { FilterProvider } from './contexts/FilterContext';
import { MapProvider } from './contexts/MapContext';
import { PropertyFilterProvider } from './contexts/PropertyContext';
import Inquiry from './page/inquiry/Inquiry';
import NoticeList from './page/notice/Notice';
import NoticeDetail from './page/notice/NoticeDetail';
import CompanyInfo from './page/companyInfo/CompanyInfo';
import NewsRoom from './page/newsRoom/NewsRoom';
import NewsRoomDetail from './page/newsRoom/NewsRoomDetail';
import ServiceTerms from './page/terms/Service';
import PrivacyTerms from './page/terms/Privacy';
import LocationTerms from './page/terms/Location';
import MyInfo from './page/myInfo/MyInfo';
import NotificationInfo from './page/terms/Notification';
import { SignUpInfoProvider } from './contexts/SignUpContext';
import AccountSetting from './page/account/AccountSetting';
import FindInfo from './page/findInfo/FindInfo';
import Delete from './page/delete/Delete';
function App() {
  return (
    <MapProvider>
      <PropertyFilterProvider>
        <FilterProvider>
          <SignUpInfoProvider>
            <Router>
              <div className="App">
                <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signUp" element={<SignUp />} />
                  <Route path="/sign/email" element={<SignUpEmail />} />
                  <Route path="/sign/sns" element={<SignUpSns />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/more" element={<More />} />
                  <Route path="/detail" element={<Detail />} />
                  <Route path="/pension/input" element={<PensionInput />} />
                  <Route
                    path="/pension/report"
                    element={<PensionReportMain />}
                  />
                  <Route path="/house/input" element={<HouseInput />} />
                  <Route path="/house/report" element={<HouseReportMain />} />
                  <Route path="/property" element={<Property />} />
                  <Route
                    path="/landIncome/input"
                    element={<LandIncomeInput />}
                  />
                  <Route
                    path="/landIncome/result"
                    element={<LandIncomeResult />}
                  />
                  <Route path="/brokerage" element={<Brokerage />} />
                  <Route path="/mychon" element={<MyChon />} />
                  <Route path="/inquiry" element={<Inquiry />} />
                  <Route path="/notice" element={<NoticeList />} />
                  <Route
                    path="/notice/detail/:articleIdx"
                    element={<NoticeDetail />}
                  />
                  <Route path="/companyinfo" element={<CompanyInfo />} />
                  <Route path="/newsRoom" element={<NewsRoom />} />
                  <Route
                    path="/newsRoom/detail/:articleIdx"
                    element={<NewsRoomDetail />}
                  />
                  <Route path="/terms/service" element={<ServiceTerms />} />
                  <Route path="/terms/privacy" element={<PrivacyTerms />} />
                  <Route path="/terms/location" element={<LocationTerms />} />
                  <Route
                    path="/terms/notification"
                    element={<NotificationInfo />}
                  />
                  <Route path="/myinfo" element={<MyInfo />} />
                  <Route path="/account" element={<AccountSetting />} />
                  <Route path="/find" element={<FindInfo />} />
                  <Route path="/delete" element={<Delete />} />
                </Routes>
              </div>
            </Router>
          </SignUpInfoProvider>
        </FilterProvider>
      </PropertyFilterProvider>
    </MapProvider>
  );
}

export default App;
