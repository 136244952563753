import styled from 'styled-components';
import {
  XAxis,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from 'recharts';
import { AnalysisProps } from '../../data';
import {
  formatToKoreanCurrency,
  transNum,
} from '../../../../function/function';

interface DummyData {
  name: string;
  price: number;
  gapOfPrice?: number; // 중위값과의 차이
}

interface AnalysisData {
  data: AnalysisProps;
  pyeong: number;
  price: number;
  dealDate: string;
}

const RealPriceChart = ({ data, pyeong, price, dealDate }: AnalysisData) => {
  const chartDataSet: DummyData[] = [
    {
      name: '해당 주소지',
      price: data.price - data.avg > 0 ? data.avg : data.price,
      gapOfPrice:
        data.avg !== 0 && data.price - data.avg > 0 ? data.price - data.avg : 0,
    },
    { name: '하위평당가', price: data.low_avg, gapOfPrice: 0 },
    { name: '평당가', price: data.avg, gapOfPrice: 0 },
    { name: '상위평당가', price: data.high_avg, gapOfPrice: 0 },
  ];

  const medianValue = data.avg;

  const formatDate = (dateStr: string) => {
    const [year, month, day] = dateStr.split('-');
    return `${year.slice(2)}.${month}`;
  };
  return (
    <>
      <TAnalyzeChartBox>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={328}
            height={176}
            data={chartDataSet}
            margin={{ top: 25, right: 20 }}
          >
            <XAxis
              axisLine={false} // X축 선을 제거
              tickLine={false} // X축 tick을 제거
              dataKey="name"
              interval={0}
              tick={({ x, y, payload }) => {
                const isHighlighted = payload.value === '해당 주소지';
                const padding = isHighlighted ? 0 : -4;
                return (
                  <g transform={`translate(${x + padding},${y})`}>
                    <text
                      dy={16}
                      fill={
                        isHighlighted ? '#222222' : 'rgba(55, 56, 60, 0.61)'
                      }
                      fontSize="14px"
                      fontFamily="Pretendard-Medium"
                      fontWeight="500"
                      textAnchor="middle"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {payload.value}
                    </text>
                  </g>
                );
              }}
            />
            <Bar dataKey="price" barSize={40} stackId="a" radius={[0, 0, 4, 4]}>
              {chartDataSet.map((entry, index) => {
                // 색상 결정
                let fillColor = '#D2D8DF'; // 기본 색상 (검정색)
                if (entry.name === '해당 주소지' && entry.gapOfPrice === 0) {
                  fillColor = '#1E9B6B';
                  return (
                    <Cell key={`cell-${index}`} fill={fillColor} radius={4} />
                  );
                }
                if (entry.name === '해당 주소지') {
                  fillColor = '#1E9B6B';
                  return <Cell key={`cell-${index}`} fill={fillColor} />;
                } else {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.name === '평당가' ? '#484F55' : '#D2D8DF'}
                      radius={4}
                    />
                  );
                }
              })}
            </Bar>
            <Bar
              dataKey="gapOfPrice"
              barSize={40}
              stackId="a"
              radius={[4, 4, 0, 0]}
              fill="#25805E"
            >
              <LabelList
                dataKey="price"
                position="top"
                content={({ x, y, value, index }) => {
                  if (index !== undefined) {
                    const gapOfPrice =
                      chartDataSet.find((item) => item.name === '해당 주소지')
                        ?.gapOfPrice || 0;
                    const total = Number(value) + gapOfPrice;
                    return (
                      <text
                        x={Number(x) + 20}
                        y={Number(y) - 10}
                        fill="#000"
                        textAnchor="middle"
                        fontSize="14"
                        fontFamily="Pretendard-Bold"
                      >
                        {Number((total / 10000).toFixed(1)).toLocaleString()}만
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Bar>
            <ReferenceLine
              y={medianValue}
              stroke="rgba(55, 56, 60, 0.28)"
              strokeDasharray="3 3"
              label={{
                position: 'insideRight',
                value: '평균',
                fontSize: 12,
                fontFamily: 'Pretendard-Medium',
                dx: 20,
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </TAnalyzeChartBox>
      <AveragePriceListBox>
        <PriceBox>
          <AveragePriceList>
            <div>과거실거래가</div>
            <MaxPrice>
              <Tag>거래일 {dealDate && formatDate(dealDate)}</Tag>
              {formatToKoreanCurrency(price)}
            </MaxPrice>
          </AveragePriceList>
          <ListDivider></ListDivider>
          <AveragePriceList>
            <TextGray>하위추정가</TextGray>
            <AveragePriceContent>
              {formatToKoreanCurrency(data.low_avg * pyeong)}~
            </AveragePriceContent>
          </AveragePriceList>
          <AveragePriceList>
            <TextGray>추정가</TextGray>
            <AveragePriceContent>
              {formatToKoreanCurrency(data.avg * pyeong)}
            </AveragePriceContent>
          </AveragePriceList>
          <AveragePriceList>
            <TextGray>상위추정가</TextGray>
            <AveragePriceContent>
              {'~' + formatToKoreanCurrency(data.high_avg * pyeong)}
            </AveragePriceContent>
          </AveragePriceList>
        </PriceBox>
      </AveragePriceListBox>
    </>
  );
};

export default RealPriceChart;

const TAnalyzeChartBox = styled.div`
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  height: 176px;
`;

const AveragePriceListBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  padding: 16px;
  border-radius: 8px;
  gap: 13px;
`;

const PriceBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
`;

const AveragePriceList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  width: 100%;
  color: #222222;
`;

const AveragePriceContent = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Bold;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
const MaxPrice = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Bold;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1e2e4;
`;

const TextGray = styled.div`
  color: #616161;
`;

const Tag = styled.span`
  padding: 3px 4px;
  border-radius: 4px;
  background-color: rgba(112, 115, 124, 0.08);
  color: rgba(55, 56, 60, 0.61);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
`;
