import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import PointAlert from '../../point/PointAlert';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../../color';
import { isLogin } from '../../../../../utilities/auth';

interface buttonProps {
  buttonActive: boolean;
}

const HousePreview = ({
  usedPoint,
  c_idx,
  address,
  itemType,
  pnu,
  housekye,
  landArea,
  analysisYN,
  land_BCR,
  isLogin2,
}: {
  c_idx: string;
  usedPoint: boolean;
  address: String;
  itemType: string;
  pnu: string;
  housekye: string;
  landArea: number;
  analysisYN: boolean;
  land_BCR: number;
  isLogin2: boolean;
}) => {
  const nav = useNavigate();
  const [pointModal, setPointModal] = useState(false);
  const [stateData, setStateData] = useState({});
  const [url, setUrl] = useState('');
  const [buttonActive, setButtonActive] = useState(true);
  useEffect(() => {
    setStateData({
      c_idx: c_idx,
      address: address,
      itemType: itemType,
      pnu: pnu,
      housekye: housekye,
      landArea: landArea,
      land_BCR: land_BCR,
    });
    setUrl('/house/input?address=' + pnu);

    if (analysisYN) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  }, [pnu]);

  useEffect(() => {
    if (pointModal && usedPoint) {
      nav('/house/input?address=' + pnu, {
        state: {
          stateData,
        },
      });
    }
  }, [pointModal, usedPoint, c_idx, pnu, address, itemType, housekye]);

  const [showTooltip, setShowTooltip] = useState(true);

  const handleClose = () => {
    setShowTooltip(false);
  };

  return (
    <Container>
      {pointModal ? (
        usedPoint ? null : (
          <PointAlert url={url} setModal={setPointModal} state={stateData} />
        )
      ) : null}
      <Title>주택견적</Title>
      <InfoBox>
        <InfoText>
          <div>해당 토지는 건축이 가능한 땅이에요!</div>
          <div>
            <span style={{ color: '#1E9B6B' }}>맞춤 설계와 견적</span>을 받을 수
            있어요
          </div>
        </InfoText>
        <Button
          buttonActive={buttonActive}
          onClick={() => {
            if (!analysisYN) {
              return;
            } else {
              !isLogin2
                ? nav('/login')
                : sessionStorage.setItem('ReportCategory', '주택견적분석');
              setPointModal((prevPointModal) => !prevPointModal);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <ButtonText>주택 견적 확인</ButtonText>
        </Button>
      </InfoBox>
      {!analysisYN && showTooltip && (
        <TooltipContainer>
          <TooltipTextBox>
            <img src="/asset/images/icon/error.svg" alt="" />
            <p>분석을 위한 정보가 충분하지 않아요.</p>
          </TooltipTextBox>
          <img
            src="/asset/images/close/close-white.svg"
            alt=""
            onClick={handleClose}
          />
        </TooltipContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #ffffff;
  position: relative;
`;

const Title = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #222222;
`;

const InfoBox = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #70737c29;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InfoText = styled.div`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Button = styled.div<buttonProps>`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: ${(props) => (!props.buttonActive ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => (!props.buttonActive ? '#F7F7F8' : '#1e9b6b')};
  color: ${(props) =>
    !props.buttonActive ? 'rgba(55, 56, 60, 0.16)' : '#ffffff'};
`;

const ButtonText = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
`;
const TooltipContainer = styled.div`
  width: calc(100% - 65px);

  position: absolute;
  padding: 12px 16px;
  background-color: #5e6263;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  top: 228px;
  right: 33px;
  z-index: 200;
  box-shadow:
    0px 6px 12px 0px rgba(0, 0, 0, 0.12),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    top: -8px; /* Adjust as needed to position the triangle */
    left: 50%; /* Center the triangle */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #5e6263; /* Triangle color matching the background */
  }
  @media (min-width: 360px) {
    right: 33px;
  }
`;

const TooltipTextBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.white};
  white-space: nowrap;
`;

export default HousePreview;
