import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CropListProps } from '../../../data';
import { transNum } from '../../../../../function/function';
import PointAlert from '../../point/PointAlert';
import colors from '../../../../../color';

interface TabContentProps {
  IsActive: boolean;
}

interface PriceTextProps {
  IsSaleprice: boolean;
}

interface CropCategoryProps {
  facility: string;
}

interface BlurProps {
  isLogin: boolean;
}

interface ListProps {
  isCropList: boolean;
  analysisYN: boolean;
  isLogin: boolean;
}

const LandIncome = ({
  cropList,
  c_idx,
  usedPoint,
  pnu,
  area,
  avg,
  isLogin2,
  address,
  itemType,
  housekye,
  analysisYN,
}: {
  cropList: CropListProps[];
  c_idx: string;
  usedPoint: boolean;
  pnu: string;
  area: number;
  avg: number;
  isLogin2: boolean;
  address: string;
  itemType: string;
  housekye: string;
  analysisYN: boolean;
}) => {
  const nav = useNavigate();
  const [selectedState, setSelectedState] = useState('AI');

  const handleStateChange = (state: string) => {
    setSelectedState(state);
  };
  const [pointModal, setPointModal] = useState(false);
  const [url, setUrl] = useState('');
  const [isCropList, setIsCropList] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const handleClose = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    if (avg) {
      setUrl(`/landIncome/input`);
    }
  }, [avg]);

  const filteredCropList = [
    cropList.find((crop) => crop.facility === '노지'),
    cropList.find((crop) => crop.facility === '시설'),
  ].filter(Boolean);

  useEffect(() => {
    if (pointModal && usedPoint) {
      nav(`/landIncome/input`, {
        state: {
          c_idx: c_idx,
          pnu: pnu,
          area: area,
          avg: avg,
          address: address,
          itemType: itemType,
          housekye: housekye,
        },
      });
    }
    if (cropList && cropList.length === 0) {
      setIsCropList(false);
    } else {
      setIsCropList(true);
    }
  }, [pointModal, usedPoint, c_idx, pnu, area, avg, cropList]);

  return (
    <LandIncomeContainer
      isCropList={isCropList}
      analysisYN={analysisYN}
      isLogin={isLogin2}
    >
      {pointModal ? (
        usedPoint ? null : (
          <PointAlert
            url={url}
            setModal={setPointModal}
            state={{
              c_idx: c_idx,
              pnu: pnu,
              area: area,
              avg: avg,
              address: address,
              itemType: itemType,
              housekye: housekye,
            }}
          />
        )
      ) : null}
      <Header>토지 소득 분석</Header>
      <TabBox>
        <TabContent
          IsActive={selectedState === 'AI'}
          onClick={() => handleStateChange('AI')}
        >
          AI작물추천
        </TabContent>
        <TabContent
          IsActive={selectedState === 'Income'}
          onClick={() => {
            if (!isLogin2) {
              nav('/login');
            } else if (!analysisYN) {
              // 아무 동작도 하지 않거나, 필요 시 다른 처리를 수행합니다.
              return; // 이 위치에서 함수를 종료합니다.
            } else {
              sessionStorage.setItem('ReportCategory', '가격분석');
            }
            setPointModal((prevPointModal) => !prevPointModal);
          }}
        >
          경작 예상 소득
        </TabContent>
      </TabBox>
      {!isCropList ? (
        <NoResultBox>해당 토지는 추천 작물 데이터가 없습니다.</NoResultBox>
      ) : (
        <CropBox>
          {filteredCropList.map(
            (crop, index) =>
              crop && (
                <CropContent key={index}>
                  <CropCategory facility={crop.facility}>
                    {crop.facility}
                  </CropCategory>
                  <CropItem isLogin={isLogin2}>{crop.name}</CropItem>
                  <CropImage
                    isLogin={isLogin2}
                    src={`https://back.transfarmer.kr/img/${crop.img}`}
                    onClick={() => {
                      !isLogin2
                        ? nav('/login')
                        : sessionStorage.setItem('ReportCategory', '가격분석');
                      setPointModal((prevPointModal) => !prevPointModal);
                    }}
                  />
                  <PriceBox>
                    <PriceContent>
                      <div>매출액</div>
                      <PriceText IsSaleprice={true}>
                        {/* {isLogin() ? `${transNum(crop.sales)}원` : '???'} */}
                        ???
                      </PriceText>
                    </PriceContent>
                    <PriceContent>
                      <div>판매단가</div>
                      <PriceText IsSaleprice={false}>
                        {transNum(crop.unitPrice)}원
                      </PriceText>
                    </PriceContent>
                  </PriceBox>
                </CropContent>
              ),
          )}
        </CropBox>
      )}
      <RecommendedCrop
        isLogin={isLogin2}
        isCropList={isCropList}
        analysisYN={analysisYN}
        onClick={() => {
          if (!analysisYN) {
            return;
          } else {
            !isLogin2
              ? nav('/login')
              : sessionStorage.setItem('ReportCategory', '가격분석');
            setPointModal((prevPointModal) => !prevPointModal);
          }
        }}
      >
        {isLogin2 ? '가장 돈 되는 작물 알아보기' : '추천 작물 알아보기'}
      </RecommendedCrop>
      {!analysisYN && showTooltip && (
        <TooltipContainer>
          <TooltipTextBox>
            <img src="/asset/images/icon/error.svg" alt="" />
            <p>분석을 위한 데이터가 충분하지 않아요.</p>
          </TooltipTextBox>
          <img
            src="/asset/images/close/close-white.svg"
            alt=""
            onClick={handleClose}
          />
        </TooltipContainer>
      )}
    </LandIncomeContainer>
  );
};
export default LandIncome;

const NoResultBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  paddiing: 0px 16px;
`;

const RecommendedCrop = styled.div<ListProps>`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 24px;
  align-items: center;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26.4px;
  word-wrap: break-word;
  border-radius: 8px;
  cursor: pointer;

  cursor: ${(props) => (!props.analysisYN ? 'default' : 'pointer')};

  background-color: ${(props) => {
    if (!props.isLogin) return '#fff'; // isLogin이 false
    if (props.isLogin && props.analysisYN) return 'rgba(30, 155, 107, 1)'; // isLogin이 true이고 analysisYN이 true
    return '#F7F7F8'; // isLogin이 true이고 analysisYN이 false
  }};

  color: ${(props) => {
    if (!props.isLogin) return '#1e9b6b'; // isLogin이 false
    if (props.isLogin && props.analysisYN) return 'white'; // isLogin이 true이고 analysisYN이 true
    return 'rgba(55, 56, 60, 0.16)'; // isLogin이 true이고 analysisYN이 false
  }};

  border: ${(props) => (!props.analysisYN ? null : '1px #1e9b6b solid')};
`;

const PriceBox = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const PriceContent = styled.div`
  width: 50%;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

const PriceText = styled.div<PriceTextProps>`
  color: #222222;
  color: ${(props) => (props.IsSaleprice ? '#1E9B6B' : '#222222')};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

// 처음
const LandIncomeContainer = styled.div<ListProps>`
  width: 100%;
  height: ${(props) =>
    props.analysisYN ? (props.isCropList ? '532px' : '300px') : '300px'};
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 24px;
  background-color: #ffffff;
  position: relative;
`;

const Header = styled.div`
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const TabBox = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const TabContent = styled.div<TabContentProps>`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  border: 1px rgba(112, 115, 124, 0.16) solid;
  border-radius: 9999px;
  color: ${(props) => (props.IsActive ? 'white' : '#989ba2')};
  background-color: ${(props) => (props.IsActive ? '#222222' : null)};
`;

const CropBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const CropContent = styled.div`
  width: 194px;
  min-width: 194px;
  height: 288px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px rgba(112, 115, 124, 0.16) solid;
  align-items: center;
  cursor: pointer;
`;

const CropCategory = styled.div<CropCategoryProps>`
  width: 53px;
  height: 24px;
  padding: 2px 14px;
  background-color: ${({ facility }) =>
    facility === '노지'
      ? '#f27d00'
      : facility === '시설'
        ? '#358CFF'
        : '#f2c200'};
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-family: Pretendard-Medium;
  line-height: 20px;
  word-wrap: break-word;
`;

const CropItem = styled.div<BlurProps>`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: black;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  filter: ${(props) => (props.isLogin ? 'none' : 'blur(5px)')};
`;

const CropImage = styled.img<BlurProps>`
  width: 142px;
  height: 142px;
  filter: ${(props) => (props.isLogin ? 'none' : 'blur(5px)')};
`;

const TooltipContainer = styled.div`
  width: calc(100% - 28px);

  position: absolute;
  padding: 12px 16px;
  background-color: #5e6263;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  top: 278px;
  right: 15px;
  z-index: 200;
  box-shadow:
    0px 6px 12px 0px rgba(0, 0, 0, 0.12),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    top: -8px; /* Adjust as needed to position the triangle */
    left: 50%; /* Center the triangle */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #5e6263; /* Triangle color matching the background */
  }
  @media (min-width: 360px) {
    right: 15px;
  }
`;

const TooltipTextBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.white};
  white-space: nowrap;
`;
