import { useState } from 'react';
import {
  BoxCard2,
  BoxTable,
} from '../../../house/reportResultHouse/components/report/Box';
import { RowSectionTitle } from '../../components/Row';
import { TextSectionTitle } from '../../components/Text';
import { Tooltip2 } from '../../components/Tooltip';
import {
  formatToKoreanCurrency,
  transNum,
  transNum2,
} from '../../../../../../function/function';
import { ExpectedProfitProps } from '../../../../data';

const ExpectedIncome = ({
  expectedProfit,
}: {
  expectedProfit: ExpectedProfitProps;
}) => {
  const [onToolTip1, setOnToolTip1] = useState(false);

  const table1 = {
    th: ['매출액', `${transNum(expectedProfit.income)}` + '원'],
    tb: [
      ['매출원가', `${transNum(expectedProfit.salesCost)}` + '원'],
      ['판매관리비', `${transNum(expectedProfit.SGA)}` + '원'],
    ],
  };
  const table2 = {
    th: ['영업이익', `${transNum(expectedProfit.businessProfits)}` + '원'],
    tb: [['영업이익률', `${expectedProfit.businessProfitRate}%`]],
  };
  const table3 = {
    th: ['금융비(대출)', `${transNum(expectedProfit.loanCost)}` + '원'],
    tb: [
      ['이자비용', `${transNum(expectedProfit.interest)}` + '원'],
      ['상환원금', `${transNum(expectedProfit.repayment)}` + '원'],
    ],
  };
  const table4 = {
    th: ['순이익', `${transNum2(expectedProfit.realIncome)}` + '원'],
    tb: [['순이익률', `${expectedProfit.realIncomeRate}%`]],
  };
  const [onToolTip2, setOnToolTip2] = useState(false);
  return (
    <div className="padding-b-24 relative" style={{ backgroundColor: 'white' }}>
      <RowSectionTitle>
        <TextSectionTitle>6. 예상 이익</TextSectionTitle>
      </RowSectionTitle>

      <div className="flex-row padding-x-16 relative">
        <BoxCard2
          title="영업이익(률)"
          value={`${formatToKoreanCurrency(expectedProfit.businessProfits)}(${expectedProfit.businessProfitRate}%)`}
          onClick={() => setOnToolTip1(true)}
        />

        <BoxCard2
          title="순이익(률)"
          value={`${formatToKoreanCurrency(expectedProfit.realIncome)}(${expectedProfit.realIncomeRate}%)`}
          onClick={() => setOnToolTip2(true)}
        />
      </div>
      <div style={{ position: 'absolute', zIndex: 10 }}>
        <Tooltip2
          bottom="40"
          left="10"
          width
          on={onToolTip1}
          off={() => setOnToolTip1(false)}
          text={
            <div className="text-left">
              영업이익은 총 매출액에서 금융비를 제외한
              비용(매출원가+판매관리비)을 차감한 금액을 뜻함.
            </div>
          }
        />
      </div>
      <div style={{ position: 'absolute', zIndex: 10 }}>
        <Tooltip2
          bottom="40"
          left="110"
          width
          on={onToolTip2}
          off={() => setOnToolTip2(false)}
          text="순이익은 총 매출액에서 금융비 등 모든 비용을 제외하고 남은 최종 수익을 뜻함."
        />
      </div>
      <div className="padding-8-16 flex-column gap-8">
        <BoxTable table={table1} />
        <BoxTable table={table2} />
        <BoxTable table={table3} />
        <BoxTable table={table4} />
      </div>
    </div>
  );
};

export default ExpectedIncome;
