import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class SearchService {
  static search = async (
    search: string,
    type?: string,
    limit?: number,
    offset?: number,
  ) => {
    const result = await request.get(
      `search?search=${search}&type=${type}&limit=${limit}&offset=${offset}`,
    );
    return result.data.result;
  };

  static coord = async (pnu: string) => {
    const result = await request.get(`search/coord?pnu=${pnu}`);
    return result.data.result;
  };

  static searchLog = async (
    search: string,
    pnu: string,
    input?: string,
    type?: string,
    soldtype?: string,
    roadaddr?: string,
    buildingname?: string,
    jibunaddr?: string,
  ) => {
    try {
      await request.post(`search/log`, {
        search,
        pnu,
        input,
        type,
        soldtype,
        roadaddr,
        buildingname,
        jibunaddr,
      });
    } catch (err) {
      console.log(err);
    }
  };

  static searchLogList = async () => {
    try {
      const result = await request.get(`search/log/list`);
      return result.data.result;
    } catch (err) {}
  };

  static deleteLog = async (id: number, type: string) => {
    const result = await request.patch(`search/log/delete`, { id, type });
    return result.data.result;
  };
  static deleteAllLog = async (type: string) => {
    const result = await request.patch(`search/log/delete/${type}`, {});
    return result.data.result;
  };
}
