import styled from 'styled-components';
import PageTopNav from '../../components/pageTop/PageTop';
import BottomNavigation from '../../components/navigation/Navigation';
import { useState } from 'react';
import colors from '../../../color';

interface EnrollProps {
  saleType: string; // saleType의 타입을 string으로 지정
}

const Enroll = ({ saleType }: EnrollProps) => {
  const renderTabButton = (buttonType: string, saleType?: string) => {
    return (
      <ButtonBox>
        <div>신청 유형</div>
        <TabButtonBox>
          <div>대신 찾아줘</div>
          <div>대신 팔아줘</div>
        </TabButtonBox>
      </ButtonBox>
    );
  };

  const [enrollType, setEnrollType] = useState(saleType);
  const [selectedAffiliation, setSelectedAffiliation] = useState('personal');

  return (
    <>
      <PageTopNav title="맞춤 중개 신청하기" />
      <EnrollWrapeer>
        <EnrollTextBox>
          <ButtonBox>
            <div>신청 유형</div>
            <TabButtonBox>
              <TabButton on={enrollType === 'find'}>대신 찾아줘</TabButton>
              <TabButton on={enrollType === 'sell'}>대신 팔아줘</TabButton>
            </TabButtonBox>
          </ButtonBox>
          <ButtonBox>
            <div>소속 선택</div>
            <TabButtonBox>
              <TabButton on={selectedAffiliation === 'personal'}>
                개인
              </TabButton>
              <TabButton on={selectedAffiliation === 'company'}>
                기업 / 기관
              </TabButton>
            </TabButtonBox>
          </ButtonBox>
          <div>이름</div>
          <div>이메일</div>
          <div>연락처</div>
          <div>신청내용</div>
        </EnrollTextBox>
        <div></div>
        <div></div>
      </EnrollWrapeer>
      <BottomNavigation />
    </>
  );
};

export default Enroll;

interface selectProps {
  on: boolean;
}

const TabButton = styled.div<selectProps>`
  width: 96px;
  padding: 8px;
  border-radius: 8px;
  color: rgba(23, 30, 38, 0.56);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;

  color: ${(props) => (props.on ? 'white' : 'rgba(23,30,38,0.56)')};
  background-color: ${(props) =>
    props.on ? `${colors.primary500}` : '#F7F8F9'};
`;

const TabButtonBox = styled.div`
  display: flex;
  gap: 16px;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #171e26;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;

const EnrollWrapeer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  margin-bottom: 54px;
`;

const EnrollTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
