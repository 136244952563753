import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useRef, useState } from 'react';

interface MoreInfoModalProps {
  setShowMoreInfoModal: (value: boolean) => void;
}

const text1 = [
  '이주 기한 : 농촌 전입 후 만 5년이 경과하지 않은 세대주로, 실제 농촌에 거주하며 농업에 종사 중이거나 종사 예정인 자',
  '거주 기간 : 농촌 전입 전 1년 이상 지속적으로 농촌 외 지역에 거주한 자',
  '독립 세대 : 동일 가족 내 독립 세대로 이주한 경우, 세대주가 이주 전 1년 이상 농촌 외 지역에 거주한 자',
  '재이주 조건 : 농촌에서 다른 농촌으로 재이주한 경우, 최초 농촌 전입일로부터 5년이 경과하지 않았고 연속 거주 중일 것',
  '특수 조건 : 직업군인, 조선업 고용조정자는 퇴직 후 농촌에 거주 시 기존 근무지가 농촌이라도 5년 이내 신청 가능. 북한이탈주민은 최초 농촌 전입일로부터 5년 이내 신청 가능',
];
const text2 = [
  '거주 연속성 : 농촌 지역 거주 기간이 연속되지 않는 경우, 최종 전입일 기준으로 농촌 외 지역에 1년 이상 지속적으로 거주해야 함',
  '교육 이수 실적 : 농업·귀농 관련 교육 8시간 이상 이수해야 하며, 100시간 미만일 경우 최저 등급(D등급)으로 평가됨',
  '특수 조건 예외 : 직업군인, 조선업 고용조정자는 이주 기한이 퇴직 후 5년 이내에 한함. 북한이탈주민의 경우 거주 기간 요건이 적용되지 않음',
];

const MoreInfoModal = ({ setShowMoreInfoModal }: MoreInfoModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [deviceHeight, setDeviceHeight] = useState(0);
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      setDeviceHeight(containerHeight);
      setSmallDevice(containerHeight < 670);
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <ModalContainer ref={containerRef}>
      <ModalInner>
        <ModalTitle>
          <span>귀농 대출 진단</span>
          <img
            src="/asset/images/close/close-gray.svg"
            alt="Close"
            onClick={() => {
              setShowMoreInfoModal(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </ModalTitle>
        <SectionBox
          style={{
            height: smallDevice ? `${deviceHeight - 62 - 84}px` : '452px',
          }}
        >
          <Section>
            <SectionLabel>
              귀농 대출 조건을 충족하기 위해 다음 조건을 가정하여 분석됩니다.
            </SectionLabel>
            <TextBox>
              {text1.map((item, index) => (
                <TextItem key={index}>
                  <ListDot />
                  <span>{item}</span>
                </TextItem>
              ))}
            </TextBox>
          </Section>
          <Section>
            <SectionLabel>
              귀농 대출은 위 조건을 모두 충족하더라도 아래 사유로 제한될 수
              있습니다.
            </SectionLabel>
            <TextBox>
              {text2.map((item, index) => (
                <TextItem key={index}>
                  <ListDot />
                  <span>{item}</span>
                </TextItem>
              ))}
            </TextBox>
          </Section>
        </SectionBox>
        <ApplyBtnBox>
          <ApplyBtn
            onClick={() => {
              setShowMoreInfoModal(false);
            }}
          >
            확인했어요
          </ApplyBtn>
        </ApplyBtnBox>
      </ModalInner>
    </ModalContainer>
  );
};

export default MoreInfoModal;

const ListDot = () => {
  return (
    <div
      style={{
        width: '2px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '2px',
          height: '2px',
          borderRadius: '50px',
          backgroundColor: '#616161',
        }}
      ></div>
    </div>
  );
};

interface FilterModalProps {
  selected?: boolean;
  smallDevice?: boolean;
}

const ModalContainer = styled.div`
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.52);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

const ModalInner = styled.div<FilterModalProps>`
  width: 100%;
  height: ${({ smallDevice }) => (smallDevice ? '100%' : 'auto')};
  background-color: white;
  border-radius: 20px 20px 0 0;
  position: relative;
`;
const ModalTitle = styled.div`
  width: 100%;
  padding: 22px 16px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 24px;
  color: ${colors.labelNeutral};
  border-bottom: 1px solid ${colors.lineNormal};
  height: 62px;
`;

const SectionBox = styled.div<FilterModalProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #fff;
  height: 452px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 16px;
  margin-bottom: 82px;
`;
const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionLabel = styled.div`
  width: 100%;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  color: #222;
`;
const TextBox = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
`;
const TextItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: #616161;
`;

const ApplyBtnBox = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.lineNormal};
`;
const ApplyBtn = styled.span`
  flex: 1;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Pretendard-Bold';
  font-size: 16px;
  color: ${colors.white};
  background-color: ${colors.primary500};
  cursor: pointer;
`;
