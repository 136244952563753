import styled from 'styled-components';
import colors from '../../color';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import './Search.css';
import PopularList from './components/PopularList';
import NoLogin from './components/NoLogin';
import NoResult from './components/NoResult';
import LatestSearchList from './components/LatestSearchList';
import LatestCheckList from './components/LatestCheckList';
import SearchDropDown from './components/SearchDropDown';
import Request from '../../@api/request';
import SearchService from '../../service/Search';
import { useMapContext } from '../../contexts/MapContext';
import { isLogin } from '../../utilities/auth';
import { Helmet } from 'react-helmet';
import { set } from 'lodash';
const baseURL = `https://dev.back.transfarmer.kr/`;
export const request = new Request(baseURL);
interface TopSearchBoxProps {
  padding: string; // padding 값을 string 타입으로 정의
}

interface PopularSearchList {
  region: string;
  state: string;
  pnu: string;
}

interface LatestSearchItem {
  id: number;
  search: string;
  pnu: string;
}

interface LatestCheckItem {
  id: number;
  type: string;
  addr: string;
  soldType: string;
  roadAddr: string;
  pnu: string;
}

interface SearchKeywordItem {
  id?: number;
  type?: string;
  address: string;
  soldType?: string;
  roadAddr?: string;
  code?: string;
}

const dummy_popularSearchList = [
  { pid: 1, region: '이천시 단월동', state: 'up' },
  { pid: 2, region: '달성군 다사읍', state: 'stay' },
  { pid: 3, region: '안동시 외룡면', state: 'up' },
  { pid: 4, region: '수정구 복정동', state: 'new' },
  { pid: 5, region: '부천시 원미구', state: 'down' },
  { pid: 6, region: '성남시 수정구', state: 'down' },
  { pid: 7, region: '안동시 북후면', state: 'down' },
  { pid: 8, region: '이천시 모가면', state: 'up' },
  { pid: 9, region: '옥청군 청성면', state: 'stay' },
  { pid: 10, region: '강남구 양재동', state: 'new' },
];

const dummy_latestSearchList = [
  { id: 1, search: '신기리 20' },
  { id: 2, search: '다사읍 문양리' },
  { id: 3, search: '대구 달성군' },
  { id: 4, search: '신기리 20' },
  { id: 5, search: '다사읍 문양리' },
  { id: 6, search: '대구 달성군' },
  { id: 7, search: '신기리 20' },
  { id: 8, search: '다사읍 문양리' },
  { id: 9, search: '대구 달성군' },
  { id: 10, search: '대구 달성군' },
];

// s : 매물, p : 공매, a : 경매, i : 인터넷 매물, b : 농지은행
const dummy_latestCheckList = [
  {
    id: 1,
    type: 'building',
    addr: '경기 여주시 매룡동 121',
    soldType: 's',
    roadAddr: '경기 여주시 명성로 415',
  },
  {
    id: 2,
    type: 'land',
    addr: '경기 여주시 우만동 192',
    soldType: 'p',
    roadAddr: '경기도 여주시 주내로 609',
  },
  {
    id: 3,
    type: 'land',
    addr: '경기 여주시 흥천면 복대리 107',
    soldType: 'i',
    roadAddr: '경기도 여주시 흥천면 금대울로 204-10',
  },
];

// s : 매물, p : 공매, a : 경매, i : 인터넷 매물, b : 농지은행
const dummy_SearchKeywordList = {
  sale: { list: [], total: 0 },
  location: { list: [], total: 0 },
  transportation: { list: [], total: 0 },
  construction: { list: [], total: 0 },
};

const Search = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const [isSearch, setIsSearch] = useState<boolean>(false); // 최근 검색 여부
  const [isCheck, setIsCheck] = useState<boolean>(false); // 최근 조회 여부
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false); // 검색 결과 여부
  const [inputValue, setInputValue] = useState<string>(''); //검색어 입력
  const [keywordAddress, setKeywordAddress] = useState<string>('');
  const [searchRegion, setSearchRegion] = useState<string>('');
  const [searchKeywordList, setSearchKeywordList] = useState<{
    sale: { list: SearchKeywordItem[]; total: number };
    location: { list: SearchKeywordItem[]; total: number };
    transportation: { list: SearchKeywordItem[]; total: number };
    construction: { list: SearchKeywordItem[]; total: number };
  }>(dummy_SearchKeywordList);

  const { latLng } = useMapContext();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setIsSearchResult(false);
    }

    setInputValue(e.target.value);
    // searchApi(e.target.value);
  };

  const onClickSendAddr = () => {
    if (inputValue.length === 0) {
      return;
    }

    let searchFst;
    if (searchKeywordList.sale.list.length !== 0) {
      searchFst = searchKeywordList.sale.list[0];
    } else if (searchKeywordList.location.list.length !== 0) {
      searchFst = searchKeywordList.location.list[0];
    } else if (searchKeywordList.transportation.list.length !== 0) {
      searchFst = searchKeywordList.transportation.list[0];
    } else if (searchKeywordList.construction.list.length !== 0) {
      searchFst = searchKeywordList.construction.list[0];
    }

    // setSearchResult({
    //   address: searchFst ? searchFst.address : inputValue,
    //   pnu: searchFst ? searchFst.code || '' : '',
    // });
    SearchService.searchLog(
      searchFst ? searchFst.address : inputValue,
      searchFst ? searchFst.code || '' : '',
      inputValue,
      searchFst ? searchFst.type : undefined,
      searchFst ? searchFst.soldType : undefined,
      searchFst ? searchFst.roadAddr : undefined,
      searchFst
        ? (searchFst as { buildingname?: string }).buildingname
        : undefined,
      searchFst ? searchFst.address : undefined,
    );
    // navigate('/');
  };

  const [popularSearchList, setPopularSearchList] = useState<
    PopularSearchList[]
  >([]);

  const [latestSearchItemList, setLatestSearchList] = useState<
    LatestSearchItem[]
  >([]);

  const [latestCheckItemList, setLatestCheckList] = useState<LatestCheckItem[]>(
    [],
  );

  const searchApi = async (address: string) => {
    try {
      const res = await SearchService.search(address);
      if (
        res &&
        (res.sale.list.length > 0 ||
          res.location.list.length > 0 ||
          res.transportation.list.length > 0 ||
          res.construction.list.length > 0)
      ) {
        setSearchKeywordList(res);
        setKeywordAddress(
          res.location.list[0].address.split(' ').slice(1).join(' '),
        );
      } else {
        setSearchKeywordList(dummy_SearchKeywordList);
      }
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
      setSearchKeywordList(dummy_SearchKeywordList);
    }
  };

  useEffect(() => {
    if (inputValue !== '' && keywordAddress !== '') {
      const addressArray = keywordAddress.split(' ');
      const index = addressArray.findIndex((word) => word.includes(inputValue));
      const slicedArray = addressArray.slice(0, index + 1);

      if (index !== -1) {
        setSearchRegion(slicedArray.join(' '));
      }
    } else {
      setSearchRegion('');
    }
  }, [inputValue, keywordAddress]);

  useEffect(() => {
    if (inputValue) {
      const fetchSearchResults = async () => {
        await searchApi(inputValue); // 입력값으로 API 호출
      };
      fetchSearchResults();
    } else {
      setSearchKeywordList(dummy_SearchKeywordList); // 입력값이 없을 경우 검색 결과 초기화
    }
  }, [inputValue]);

  useEffect(() => {
    if (
      searchKeywordList.sale.list.length !== 0 ||
      searchKeywordList.location.list.length !== 0 ||
      searchKeywordList.transportation.list.length !== 0 ||
      searchKeywordList.construction.list.length !== 0
    ) {
      setIsSearchResult(true);
    } else {
      setIsSearchResult(false);
    }
  }, [searchKeywordList]);

  const searchLogList = async () => {
    try {
      const res = await SearchService.searchLogList();
      setLatestCheckList(
        res[1].map((item: any, idx: number) => {
          return {
            id: item.id,
            type: item.type,
            addr: item.search_term,
            soldType: item.sold_type,
            roadAddr: item.road_addr,
            pnu: item.pnu,
          };
        }),
      );

      setLatestSearchList(
        res[0].map((item: any, idx: number) => {
          return { id: item.id, search: item.search_term, pnu: item.pnu };
        }),
      );

      setPopularSearchList(
        res[2] &&
          res[2].map((item: any, idx: number) => {
            return {
              pid: idx + 1,
              region:
                item.search_term &&
                item.search_term.slice(item.search_term.indexOf(' ') + 1),
              state: 'up',
              pnu: item.pnu,
              address: item.search_term,
            };
          }),
      );
      //최근 검색, 조회 주소지가 있는지 확인
      setIsSearch(res[0].length > 0);
      setIsCheck(res[1].length > 0);
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  useEffect(() => {
    searchLogList();
  }, []);

  //로그인 유무
  const [isLogin2, setIsLogin2] = useState(false);
  useEffect(() => {
    if (isLogin()) {
      setIsLogin2(true);
    } else {
      setIsLogin2(false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> {searchRegion} 토지 주택 매물 찾기 | 트랜스파머</title>
        <meta
          name="description"
          content="전국 일반 매물, 지자체 매물, 경매, 공매물 검색 - 안심할 수 있는 가격대 매물만 보기 기능을 제공합니다 ."
        />
        <meta
          name="keywords"
          content="토지 매매, 땅매매, 땅값, 땅 시세, 토지 시세, 농지,  주택매매,  토지실거래가, 토지이음, 토지투자,  땅투자, 경매, 유휴지, 캠핑장 사업, 태양광 발전 임대,  창고임대 사업, 펜션 사업"
        />
        <meta
          property="og:title"
          content="트랜스파머 | 누구나 토지 투자를 똑똑하게"
        />
        <meta
          property="og:description"
          content="전국 토지 매물 시세 분석과 토지에 적합한 투자 상품을 제공합니다"
        />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta property="og:url" content="https://www.transfarmer.co.kr" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container>
        <TopSearchBox padding="8px 16px">
          <ArrowLeft
            onClick={() => navigate(`/?lat=${latLng?.lat}&lng=${latLng?.lng}`)}
          />
          <InputBox>
            <SearchInput>
              <SearchBar
                placeholder="내 땅 찾고 평생 소득분석 무료"
                value={inputValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickSendAddr();
                  }
                }}
                onChange={handleInputChange}
                style={{ width: `${inputValue.length + 1}ch` }}
              />
            </SearchInput>
            <SearchIcon onClick={onClickSendAddr} />
          </InputBox>
        </TopSearchBox>
        {isSearchResult ? (
          <SearchDropDown
            data={searchKeywordList}
            setSearchKeywordList={setSearchKeywordList}
            search={inputValue}
          />
        ) : (
          <div className="SearchContainer">
            {isLogin2 ? (
              <>
                {inputValue.length > 0 ? (
                  <>
                    {isCheck ? (
                      <>
                        <NoResult type="noResult_noCheck" />
                        <LatestCheckList
                          latestCheckItemList={latestCheckItemList}
                          func={searchLogList}
                        />
                      </>
                    ) : (
                      <NoResult type="noResult_noCheck" />
                    )}
                  </>
                ) : (
                  <>
                    {isSearch ? (
                      <LatestSearchList
                        latestSearchItemList={latestSearchItemList}
                        func={searchLogList}
                      />
                    ) : (
                      <NoResult type="search" />
                    )}
                    {isCheck ? (
                      <LatestCheckList
                        latestCheckItemList={latestCheckItemList}
                        func={searchLogList}
                      />
                    ) : (
                      <NoResult type="check" />
                    )}
                  </>
                )}
              </>
            ) : (
              <NoLogin />
            )}
            <PopularList popularSearchList={popularSearchList} />
          </div>
        )}
      </Container>
    </>
  );
};

const ArrowLeft = styled.img`
  content: url('/asset/images/arrow/arrow-left-gray.svg');
  alt: 'logo';
  cursor: pointer;
`;

const SearchIcon = styled.img`
  content: url('/asset/images/icon/search-gray.svg');
  alt: 'search icon';
  cursor: pointer;
`;

const Container = styled.div`
  max-width: 720px;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const TopSearchBox = styled.div<TopSearchBoxProps>`
  width: 100%;
  padding: ${({ padding }) => padding};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  position: fixed;
  top: 0;
  background: #ffffff;
  z-index: 3;
  max-width: inherit;
`;

const InputBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: ${colors.lightGray100};
  border-radius: 9999px;
  box-sizing: border-box;
  overflow: hidden;
`;

const SearchInput = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  flex: 1; /* 남은 공간을 차지하게 함 */
  display: flex; /* 내부 요소들을 flexbox로 정렬 */
  color: ${colors.labelAssistive};
  box-sizing: border-box;
  overflow: hidden;
`;

const SearchBar = styled.input`
  flex: 1;
  max-width: 100%;
  background-color: ${colors.lightGray100};
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  &:focus {
    outline: none; /* 포커스 시 기본 테두리 제거 */
    border: none; /* 포커스 시 테두리 없애기 */
  }
  &::placeholder {
    color: ${colors.labelAssistive}; /* 원하는 색상으로 설정 */
  }
`;

export default Search;

/*
이 작업의 목적
- 검색 페이지를 만들기 위해서

작업의 주요기능
1.검색창에 입력한 내용을 가지고 메인페이지로 이동한 후 검색결과를 보여준다.
1-1.검색창에 입력시 검색보조 드롭다운 생성
1-1-1.드롭다운에 나온 주소지 클릭 시 상세페이지로 이동
1-2. 오름차순정렬, 최대 10개 값
1-3. 최상위 값은 행정지역 값
2.최근검색을 보여준다.
2-1.최대 10개 값
2-2.가장 최근 검색한 값이 가장 위에 보여진다.
2-3.개별단위로 삭제 가능, 삭제해도 최근 11번째 검색 이력 불러오지 않음
3.최근 조회 주소지를 보여준다.
3-1.최대 3개 값
3-2.가장 최근 조회한 값이 가장 위에 보여진다.
3-3.도로명이 있는 경우 같이 보여준다.
3-4.개별 삭제가 가능하며, 삭제해도 최근 4번째 조회 이력을 불러오지 않음
3-5.매물 유형 보조 정보 : 자체매물, 경공매, 네이버매물, 농지은행
4.인기검색을 보여준다.
4-1.인기검색은 10개의 값이 있고, 5개씩 두개의 열로 나누어 보여준다.
4-2.인기검색은 순위, 지역명, 상태(상승, 하락, 유지, 신규)를 보여준다.
4-3.인기검색은 하루마다 업데이트 된다.

[작업순서]
1.검색, 최근검색, 인기검색을 나눈다


2.퍼블리싱 작업을 진행한다
-로그인을 했을 때 안했을 때
-검색을 했을 때 안했을 때
-최근검색값이 있을 때 없을 때
-최근 조회 주소지가 있을 때 없을 때


3.인기검색 데이터를 불러온다.
4.검색 시 검색보조 드롭다운을 생성한다.
5.검색 시 검색결과 페이지로 이동한다.
6.최근검색 데이터를 불러온다.
7.최근 조회 주소지 데이터를 불러온다.
8.최근검색, 최근 조회 주소지 데이터를 삭제한다.
*/
