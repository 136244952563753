import styled from 'styled-components';
import {
  formatToKoreanCurrency,
  transNum,
} from '../../../../function/function';
import UnitSwitch from '../../../components/unitSwitch/UnitSwitch';
import { useState } from 'react';
import { SummaryProps } from '../../data';
import SummaryTitle from './components/SummaryTitle';
import SummaryCategory from './components/SummaryCategory';

interface TextColorProps {
  color: string;
  backgroundColor: string;
}

interface PriceColorProps {
  priceColor: string;
}

interface SaleDateProps {
  fontColor: string;
  fontWeight: number;
}

interface DividerProps {
  height: number;
}

interface SummaryDataProps {
  data: SummaryProps;
  approvalYear?: string;
  roadSurface?: string;
}

const Summary = (data: SummaryDataProps) => {
  const [unit, setUnit] = useState('평');

  const PriceColor = (price: string): PriceColorProps => {
    switch (price) {
      case 'minPrice':
        return { priceColor: '#EC4F4F' };
      case 'appraisedPrice':
        return { priceColor: 'black' };
      default:
        return { priceColor: 'black' };
    }
  };

  const SaleDateFont = (contentType: string): SaleDateProps => {
    switch (contentType) {
      case 'saleDate':
        return { fontColor: '#222222', fontWeight: 700 };
      case 'D_Day':
        return { fontColor: '#1E9B6B', fontWeight: 500 };
      default:
        return { fontColor: '#616161', fontWeight: 500 };
    }
  };

  const DividerHeight = (dataType: string): DividerProps => {
    switch (dataType) {
      case 'auction':
        return { height: 24 };
      case 'summary':
        return { height: 8 };
      default:
        return { height: 8 };
    }
  };

  const renderPrice = (unit: string) => {
    if (unit === '평') {
      return (
        <>
          <div>{transNum(data.data.pricePerPyeong)}원/1평</div>
          <ContentDivider
            height={DividerHeight('summary').height}
          ></ContentDivider>
          <div>{transNum(data.data.pyeong, false, false)}평</div>
        </>
      );
    } else {
      return (
        <>
          <div>{transNum(data.data.pricePerMeter)}원/1m²</div>
          <ContentDivider
            height={DividerHeight('summary').height}
          ></ContentDivider>
          <div>{transNum(data.data.area, false, false)}m²</div>
        </>
      );
    }
  };

  const renderTopInfo = (saleType: string) => {
    if (saleType === '경매' || saleType === '공매') {
      return (
        <>
          <AuctionPriceBox>
            <AuctionContentBox>
              <div>감정가({data.data.saleDate})</div>
              <AuctionContentPrice {...PriceColor('appraisedPrice')}>
                {formatToKoreanCurrency(Number(data.data.appraisedPrice))}
              </AuctionContentPrice>
            </AuctionContentBox>
            <ContentDivider
              height={DividerHeight('auction').height}
            ></ContentDivider>
            <AuctionContentBox>
              <div>
                최저입찰가(
                {(
                  (Number(data.data.lowestPrice) /
                    Number(data.data.appraisedPrice)) *
                  100
                ).toFixed(1)}
                %)
              </div>
              <AuctionContentPrice {...PriceColor('minPrice')}>
                {formatToKoreanCurrency(data.data.lowestPrice)}
              </AuctionContentPrice>
            </AuctionContentBox>
          </AuctionPriceBox>
          <SaleDateBox>
            <SaleDateInner>
              <SaleDateContent {...SaleDateFont('')}>입찰일</SaleDateContent>
              <SaleDateContent {...SaleDateFont('saleDate')}>
                {data.data.saleDate}
              </SaleDateContent>
            </SaleDateInner>
            <SaleDateContent {...SaleDateFont('D_Day')}>
              마감
              {Number(data.data.restBidDays) > 0
                ? ` D-${data.data.restBidDays}`
                : ''}
            </SaleDateContent>
          </SaleDateBox>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <SummaryContainer>
      <SummaryInnerContainer>
        <SummaryTitle
          saleType={data.data.saleType}
          type={data.data.type}
          caseNo={data.data.caseNo || ''}
          court={data.data.court || ''}
          price={data.data.price || 0}
          dealdate={data.data.dealdate || ''}
        ></SummaryTitle>
        {renderTopInfo(data.data.saleType)}
        <SummaryInfoBox>
          {data.data.saleType && data.data.saleType === '인터넷 매물' && (
            <PriceInfo>{formatToKoreanCurrency(data.data.price)}</PriceInfo>
          )}
          {(data.data.saleType && data.data.saleType === '매매') ||
            (data.data.saleType === '지자체 물건' && (
              <PriceInfo>
                매매 {formatToKoreanCurrency(data.data.price)}
              </PriceInfo>
            ))}
          <div>{data.data.address}</div>
          <SummaryContentBox>
            <SummaryContent>
              <div>{data.data.zoning}</div>
              <ContentDivider
                height={DividerHeight('summary').height}
              ></ContentDivider>
              {renderPrice(unit)}
            </SummaryContent>
            <UnitSwitch unit={unit} setUnit={setUnit}></UnitSwitch>
          </SummaryContentBox>
        </SummaryInfoBox>
      </SummaryInnerContainer>
      <SummaryCategory
        unit={unit}
        type={data.data.type}
        saleType={data.data.saleType}
        category={data.data.category}
        pyeong={data.data.pyeong}
        area={data.data.area}
        zoningBadge={data.data.zoningBadge}
        bidCount={data.data.bidCount || 0}
        approval={Number(data.approvalYear)}
        option={'N'}
        road={data.roadSurface || ''}
      ></SummaryCategory>
      {data.data.saleType === '인터넷 매물' ? (
        <NoteInfoBox>
          <div>※</div>
          <div>
            본 정보는 참고용으로만 제공되며, 해당 정보에 대한 직/간접적인 중개
            서비스를 제공하지 않습니다.
          </div>
        </NoteInfoBox>
      ) : null}
    </SummaryContainer>
  );
};
export default Summary;

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 24px;
  justify-content: space-between;
  background-color: #ffffff;
`;

const SummaryInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 16px;
`;

const AuctionPriceBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 83px;
  padding: 12px 16px;
  background-color: #fafafa;
  border-radius: 8px;
  gap: 8px;
`;

const AuctionContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  flex: 1;
`;
const ContentDivider = styled.div<DividerProps>`
  background-color: rgba(112, 115, 124, 0.16);
  width: 1px;
  height: ${(props) => props.height}px;
`;

const AuctionContentPrice = styled.div<PriceColorProps>`
  color: ${(props) => props.priceColor};
  font-size: 20px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 29px;
  word-wrap: break-word;
`;

const SaleDateBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #eaf8f2;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  border-radius: 8px;
`;

const SaleDateInner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const SaleDateContent = styled.div<SaleDateProps>`
  color: ${(props) => props.fontColor};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: ${(props) => props.fontWeight};
  line-height: 20px;
  word-wrap: break-word;
`;

const SummaryInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const SummaryContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const SummaryCategoryInfoBox = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  // background-color: #f5f5f5;
`;

const NoteInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 10px 16px;
  border-radius: 8px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  background-color: rgba(112, 115, 124, 0.05);
`;

const PriceInfo = styled.div`
  color: black;
  font-size: 24px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 34.8px;
  word-wrap: break-word;
`;
