import styled from 'styled-components';
import colors from '../../color';
interface PropertyProps {
  active?: boolean;
}

export const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

export const PageTop = styled.div`
  width: 100%;
`;
export const RegionDropdown = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 15px 16px;
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  color: #000;
  cursor: pointer;
  white-space: nowrap;
`;

export const TabMenuBox = styled.div`
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #e1e2e4;
`;
export const TabMenuInner = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
`;
export const TabMenu = styled.div<PropertyProps>`
  flex: 1;
  border-bottom: 2px solid ${({ active }) => (active ? '#222' : 'transparent')};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ active }) =>
    active ? 'Pretendard-Bold' : 'Pretendard-Medium'};
  font-size: 16px;
  color: ${({ active }) => (active ? '#222' : '#989ba2')};
  cursor: pointer;
`;

export const TabTitleBox = styled.div`
  width: 100%;
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  color: #222;
  margin-top: 24px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TitleGreen = styled.span`
  color: ${colors.primary500};
  margin-left: 6px;
`;

export const FilterTab = styled.div`
  flex: 1;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.lineNeutral};
  cursor: pointer;
`;

export const FilterTabText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelNeutral};
  line-height: 20px;
  white-space: nowrap;
`;

export const FilterContainer = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const RegionDropdownBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const BlueTooltip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #0073f0;
  height: 36px;
  position: relative;
  overflow: visible;

  ::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent #0073f0 transparent transparent;
  }
`;
export const BlueTooltipText = styled.p`
  font-family: 'Pretendard-Medium';
  font-size: 13px;
  color: #fff;
  white-space: nowrap;
`;
export const TextYellow = styled.span`
  color: #ffffa5;
`;

export const SelectedOptionContainer = styled.div`
  padding: 12px 16px;
  display: flex;
  gap: 6px;
  align-items: center;
  overflow-x: auto;
  border-top: 1px solid ${colors.lineNeutral};
  height: 58px;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ResetBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.lineNormal};
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 6px;
`;
export const SelecteOptionBtn = styled.div`
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.primary500};
  cursor: pointer;
`;
export const SelecteOptionBtnText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.primary500};
  white-space: nowrap;
`;
