import { useEffect, useState } from 'react';
import {
  ModalContainer,
  ModalInner,
  ModalTitle,
  Section,
  SectionLabel,
  SectionBox,
  ApplyBtnBox,
  ResetBtn,
  ResetBtnText,
  ApplyBtn,
  SelectedRegionBox,
  SelectedRegionText,
  RegionText,
  OptionItem,
  OptionColumn30,
  OptionColumn40,
  OptionItemBg,
} from './RegionModal.styles';
import Sales from '../../../service/Sales';
import { usePropertyFilter } from '../../../contexts/PropertyContext';

interface RegionModalProps {
  setRegionModalOn: (value: boolean) => void;
  smallDevice: boolean;
  deviceHeight: number;
  setCIdx: (value: string) => void;
}
interface RegionProps {
  code: string;
  region: string;
}

const PropertyRegionModal = ({
  setRegionModalOn,
  deviceHeight,
  smallDevice,
  setCIdx,
}: RegionModalProps) => {
  const [ctp, setCtp] = useState('');
  const [sig, setSig] = useState('');
  const [emd, setEmd] = useState('');
  const [ctpRegion, setCtpRegion] = useState('');
  const [sigRegion, setSigRegion] = useState('');
  const [emdRegion, setEmdRegion] = useState('');
  const [ctpList, setCtpList] = useState<RegionProps[]>([]);
  const [sigList, setSigList] = useState<RegionProps[]>([]);
  const [emdList, setEmdList] = useState<RegionProps[]>([]);

  const { setPropertyPnu, setAddress } = usePropertyFilter();

  const getRegion = async (region: string) => {
    try {
      const res = await Sales.region(region);
      return res.result as RegionProps[];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  useEffect(() => {
    if (ctp === '') {
      getRegion('').then(setCtpList);
    }
  }, []);

  useEffect(() => {
    if (ctp !== '') {
      getRegion(ctp).then(setSigList);
    } else {
      setSigList([]);
    }
  }, [ctp]);

  useEffect(() => {
    const loadEmdList = async () => {
      if (sig !== '') {
        const emdRegions = await getRegion(sig);
        setEmdList(emdRegions);
        if (emdRegions.length > 0) {
          setEmd(emdRegions[0].code);
          setEmdRegion(emdRegions[0].region);
        }
      } else {
        setEmdList([]);
        setEmd('');
        setEmdRegion('');
      }
    };
    loadEmdList();
  }, [sig]);

  const handleReset = () => {
    setCtp('');
    setSig('');
    setEmd('');
    setSigList([]);
    setEmdList([]);
  };

  const checkAllSelected = () => {
    return ctp !== '' && sig !== '' && emd !== '';
  };

  return (
    <ModalContainer>
      <ModalInner smallDevice={smallDevice}>
        <ModalTitle>
          <span>지역 설정</span>
          <img
            src="/asset/images/close/close-gray.svg"
            alt="Close"
            onClick={() => {
              setRegionModalOn(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </ModalTitle>
        <Section>
          <SectionLabel>지역을 선택해 주세요 </SectionLabel>
          <SelectedRegionBox>
            {ctp === '' ? (
              <RegionText>도/시 선택</RegionText>
            ) : (
              <SelectedRegionText>
                {ctpList.find((item) => item.code === ctp)?.region || ctp}
              </SelectedRegionText>
            )}
            <img src="/asset/images/arrow/arrow-right-darkgray.svg" alt="" />
            {sig === '' ? (
              <RegionText>시/군/구 선택</RegionText>
            ) : (
              <SelectedRegionText>
                {sigList.find((item) => item.code === sig)?.region || sig}
              </SelectedRegionText>
            )}
            <img src="/asset/images/arrow/arrow-right-darkgray.svg" alt="" />
            {emd === '' ? (
              <RegionText>읍/면/동 선택</RegionText>
            ) : (
              <SelectedRegionText>
                {emdList.find((item) => item.code === emd)?.region || emd}
              </SelectedRegionText>
            )}
          </SelectedRegionBox>
        </Section>
        <SectionBox>
          <OptionColumn30
            style={{
              height: smallDevice ? `${deviceHeight - 290}px` : '327px',
            }}
          >
            {ctpList.map((item, index) => (
              <OptionItem
                key={index}
                selected={ctp === item.code}
                onClick={() => {
                  setCtp(item.code);
                  setCtpRegion(item.region);
                  if (ctp !== item.code) {
                    setSig('');
                    setEmd('');
                    setSigList([]);
                    setEmdList([]);
                  }
                }}
              >
                {item.region}
              </OptionItem>
            ))}
          </OptionColumn30>
          <OptionColumn40
            style={{
              height: smallDevice ? `${deviceHeight - 290}px` : '327px',
            }}
          >
            {sigList.map((item, index) => (
              <OptionItemBg
                key={index}
                selected={sig === item.code}
                onClick={() => {
                  setSig(item.code);
                  setSigRegion(item.region);
                  if (sig !== item.code) {
                    setEmd('');
                    setEmdList([]);
                  }
                }}
              >
                {item.region}
              </OptionItemBg>
            ))}
          </OptionColumn40>
          <OptionColumn40
            style={{
              height: smallDevice ? `${deviceHeight - 290}px` : '327px',
            }}
          >
            {emdList.map((item, index) => (
              <OptionItemBg
                key={index}
                selected={emd === item.code}
                onClick={() => {
                  setEmd(item.code);
                  setEmdRegion(item.region);
                }}
              >
                {item.region}
              </OptionItemBg>
            ))}
          </OptionColumn40>
        </SectionBox>
        <ApplyBtnBox>
          <ResetBtn onClick={handleReset}>
            <img src="/asset/images/icon/reset.svg" alt="" />
            <ResetBtnText>초기화</ResetBtnText>
          </ResetBtn>
          <ApplyBtn
            onClick={() => {
              setAddress({
                sido: ctpRegion,
                sigungu: sigRegion,
                eupmd: emdRegion === '전체' ? '' : emdRegion,
                ri: '',
              });
              setPropertyPnu(emd);
              setCIdx('');
              setRegionModalOn(false);
            }}
            active={checkAllSelected()}
          >
            {checkAllSelected()
              ? `${emdList.find((item) => item.code === emd)?.region || emd} 결과보기`
              : '지역선택'}
          </ApplyBtn>
        </ApplyBtnBox>
      </ModalInner>
    </ModalContainer>
  );
};

export default PropertyRegionModal;
