import { RowTableValueBold } from './Row';
import WeatherCom from './Weather';
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { BoxGreen1 } from './Box';
import { TempertureDataProps } from '../../../data';

function formatDate(dateString: string): string {
  const [year, month] = dateString.split('-'); // 날짜를 '-'로 분리
  const formattedYear = year.slice(2); // 년도에서 마지막 두 자리만 추출
  return `${formattedYear}-${month}`; // 원하는 형식으로 리턴
}

export default class TempCom {
  static AvgTempOfMonth = ({
    tempertureData,
  }: {
    tempertureData: TempertureDataProps;
  }) => {
    return (
      <div className="flex-column ">
        <div className="padding-16-16-0">
          <RowTableValueBold
            title="월평균 기온"
            value={
              Math.round(
                tempertureData.curWeather.reduce(
                  (acc: any, cur: any, i: number) => {
                    return acc + cur.temp;
                  },
                  0,
                ) / tempertureData.curWeather.length,
              ) + ' ℃'
            }
          />
        </div>
        <div className="flex-row padding-12-16 gap-8 end-x">
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#09CE7D' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-primary-400">
              월평균 기온
            </div>
          </div>
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#869CB6' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
              평년값
            </div>
          </div>
        </div>
        <div className="flex-column of-x-auto" style={{ overflow: 'hidden' }}>
          <ResponsiveContainer width="96%" height={180}>
            <LineChart
              className="flex-medium f-size-11 center-x"
              data={tempertureData.curWeather.map((el: any, i: number) => {
                return {
                  day: `${formatDate(el.date)}`,
                  temp:
                    (tempertureData.avgWeather[i] &&
                      tempertureData.avgWeather[i].temp) ||
                    0,
                  avgTemp: el.temp,
                };
              })}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <Line
                type="monotone"
                dataKey="avgTemp"
                name="월평균 기온"
                stroke="#09CE7D"
              />
              <Line
                type="monotone"
                dataKey="temp"
                name="평년값"
                stroke="#869CB6"
              />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="padding-8-16 gap-10">
          {/* <WeatherCom.WeatherGreenBox1 date={date} data={data} /> */}
          <BoxGreen1>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              {`${formatDate(tempertureData.curWeather[tempertureData.curWeather.length - 1].date).split('-')[1]}` ||
                '0'}
              월 평균 기온은{' '}
              {tempertureData.curWeather[tempertureData.curWeather.length - 1]
                .temp + ' ℃'}
              로
            </div>
            <div
              className="font-bold f-size-16 line-h-24 "
              style={{
                color:
                  tempertureData.curWeather[
                    tempertureData.curWeather.length - 1
                  ].temp <
                  tempertureData.avgWeather[
                    tempertureData.avgWeather.length - 1
                  ].temp
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              평년{' '}
              {tempertureData.avgWeather[tempertureData.avgWeather.length - 1]
                .temp + ' ℃'}
              {(() => {
                let a =
                  tempertureData.curWeather[
                    tempertureData.curWeather.length - 1
                  ].temp;
                let b =
                  tempertureData.avgWeather[
                    tempertureData.avgWeather.length - 1
                  ].temp;
                if (a > b) {
                  return (
                    '에 비해 ' + Math.round((a - b) * 10) / 10 + ' ℃ 높습니다.'
                  );
                } else if (a < b) {
                  return (
                    '에 비해 ' + Math.round((b - a) * 10) / 10 + ' ℃ 낮습니다.'
                  );
                } else {
                  return '와 같습니다.';
                }
              })()}
            </div>
          </BoxGreen1>
        </div>
      </div>
    );
  };

  static AvgMaxTempOfMonth = ({
    tempertureData,
  }: {
    tempertureData: TempertureDataProps;
  }) => {
    return (
      <div className="flex-column">
        <div className="padding-16-16-0">
          <RowTableValueBold
            title="월평균 최고기온"
            value={
              Math.round(
                tempertureData.curWeather.reduce(
                  (acc: any, cur: any, i: number) => {
                    return acc + cur.maxTemp;
                  },
                  0,
                ) / tempertureData.curWeather.length,
              ) + ' ℃'
            }
          />
        </div>
        <div className="flex- row padding-12-16 gap-8 end-x">
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#09CE7D' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-primary-400">
              월평균 최고기온
            </div>
          </div>
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#869CB6' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
              평년값
            </div>
          </div>
        </div>
        <div className="flex-column of-x-auto" style={{ overflow: 'hidden' }}>
          <ResponsiveContainer width="96%" height={180}>
            <LineChart
              className="flex-medium f-size-11 center-x"
              data={tempertureData.curWeather.map((el: any, i: number) => {
                return {
                  day: `${formatDate(el.date)}`,
                  temp:
                    Math.round(tempertureData.avgWeather[i].maxTemp * 100) /
                    100,
                  avgTemp: Math.round(el.maxTemp * 100) / 100,
                };
              })}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <Line
                type="monotone"
                dataKey="avgTemp"
                name="월평균 최고기온"
                stroke="#09CE7D"
              />
              <Line
                type="monotone"
                dataKey="temp"
                name="평년값"
                stroke="#869CB6"
              />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="padding-8-16 gap-10">
          <BoxGreen1>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              {`${formatDate(tempertureData.curWeather[tempertureData.curWeather.length - 1].date).split('-')[1]}` ||
                '0'}
              월 평균 최고기온은{' '}
              {tempertureData.curWeather[tempertureData.curWeather.length - 1]
                .maxTemp + ' ℃'}
              로
            </div>
            <div
              className="font-bold f-size-16 line-h-24 "
              style={{
                color:
                  tempertureData.curWeather[
                    tempertureData.curWeather.length - 1
                  ].maxTemp <
                  tempertureData.avgWeather[
                    tempertureData.avgWeather.length - 1
                  ].maxTemp
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              평년{' '}
              {tempertureData.avgWeather[tempertureData.avgWeather.length - 1]
                .maxTemp + ' ℃'}
              {(() => {
                let a =
                  tempertureData.curWeather[
                    tempertureData.curWeather.length - 1
                  ].maxTemp;
                let b =
                  tempertureData.avgWeather[
                    tempertureData.avgWeather.length - 1
                  ].maxTemp;
                if (a > b) {
                  return (
                    '에 비해 ' + Math.round((a - b) * 10) / 10 + ' ℃ 높습니다.'
                  );
                } else if (a < b) {
                  return (
                    '에 비해 ' + Math.round((b - a) * 10) / 10 + ' ℃ 낮습니다.'
                  );
                } else {
                  return '와 같습니다.';
                }
              })()}
            </div>
          </BoxGreen1>
        </div>
      </div>
    );
  };
}
