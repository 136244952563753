import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  makeAuctionforPriceMarkers,
  makePropertyforPriceMarkers,
  makeReaPriceMarkers,
} from '../../../main/components/map/markerData';
import { MapData } from '../../data';

interface DetailMapProps {
  data: MapData;
  saleType: string;
}

const DetailMap = ({ data, saleType }: DetailMapProps) => {
  const mapElement = useRef<HTMLDivElement>(null);
  const roadViewElement = useRef<HTMLDivElement>(null);
  const mapRef = useRef<any>(null);
  const roadViewRef = useRef<any>(null);
  const markersRef = useRef<any[]>([]);
  const [roadView, setRoadView] = useState(false);
  const roadViewElement2 = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(
    window.innerWidth > 720 ? 720 : window.innerWidth,
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const createMarkers = (map: any) => {
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    if (
      saleType === '인터넷 매물' ||
      saleType === '매물' ||
      saleType === '지자체 물건'
    ) {
      const markerContent = makePropertyforPriceMarkers(data);
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: map,
        title: data.category,
        icon: {
          content: markerContent,
          anchor: new window.naver.maps.Point(40, 50),
        },
      });

      markersRef.current.push(marker);
    }
    if (saleType === '경매' || saleType === '공매') {
      const markerContent = makeAuctionforPriceMarkers(data);
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: map,
        title: data.category,
        icon: {
          content: markerContent,
          anchor: new window.naver.maps.Point(35, 50),
        },
      });
      markersRef.current.push(marker);
    }
    if (saleType === '실거래') {
      const markerContent = makeReaPriceMarkers(data);
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: map,
        title: data.category,
        icon: {
          content: markerContent,
          anchor: new window.naver.maps.Point(35, 50),
        },
      });

      markersRef.current.push(marker);
    }
    if (saleType === '비매물') {
      const marker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(data.lat, data.lng),
        map: mapRef.current,
        title: 'non-property',
        icon: {
          url: '/asset/images/marker/tf-marker.svg',
          anchor: new window.naver.maps.Point(25, 50),
        },
      });

      markersRef.current.push(marker);
    }
  };

  const initializeMap = () => {
    if (!mapElement.current) return;
    const mapOptions = {
      center: new window.naver.maps.LatLng(data.lat, data.lng),
      zoom: 17,
      mapTypeId: window.naver.maps.MapTypeId['SATELLITE'],
      logoControl: false,
      mapDataControl: false,
      mapTypeControl: false,
      draggable: false,
      scrollWheel: false,
      disableDoubleClickZoom: true,
      disableDoubleTapZoom: true,
      size: new window.naver.maps.Size(
        window.innerWidth > 720 ? 720 : window.innerWidth,
        300,
      ),
    };

    // 지도 초기화
    if (mapRef.current) {
      mapRef.current.destroy();
    }
    mapRef.current = new window.naver.maps.Map(mapElement.current, mapOptions);

    const polygon = new window.naver.maps.Polygon({
      map: mapRef.current,
      paths: data.geom,
      fillColor: 'rgba(0, 177, 85, 0.25)',
      fillOpacity: 0.3,
      strokeColor: '#00B155',
      strokeOpacity: 0.6,
      strokeWeight: 3,
    });

    createMarkers(mapRef.current);
  };

  useEffect(() => {
    if (mapElement.current) {
      initializeMap();
    }
  }, [data]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setSize(new window.naver.maps.Size(width, 300));
    }
  }, [width]);

  useEffect(() => {
    if (roadView) {
      mapRef.current.destroy();

      const pano2 = new window.naver.maps.Panorama(roadViewElement2.current, {
        position: new window.naver.maps.LatLng(data.lat, data.lng),

        pov: {
          pan: -135,
          tilt: 29,
          fov: 100,
        },
        zoomControl: false,
        visible: false,
      });

      new window.naver.maps.Event.addListener(pano2, 'pano_status', function (
        status: any,
      ) {
        // console.log(status);
        if (status === 'ERROR') {
          // alert('지원하지 않는 지역입니다.');
          setRoadView(false);
          pano2.setZoomControl(true);
          initializeMap();
        } else {
          const pano = new window.naver.maps.Panorama(roadViewElement.current, {
            position: new window.naver.maps.LatLng(data.lat, data.lng),

            pov: {
              pan: -135,
              tilt: 29,
              fov: 100,
            },
            zoomControl: false,
            visible: false,
          });
          pano.setVisible(true);
          pano.setZoomControl(false);
          roadViewRef.current = pano;
        }
      });
      if (mapElement.current) {
        mapElement.current.style.display = 'none';
      }
    } else {
      if (roadViewRef.current) {
        roadViewRef.current.setVisible(false);
        roadViewRef.current = null;
      }
      if (mapElement.current) {
        mapElement.current.style.display = 'block';
      }

      initializeMap();
    }
  }, [roadView]);

  return (
    <DetailMapContainer>
      {/* 지도와 로드뷰를 조건에 따라 토글 */}
      <div
        ref={roadView ? roadViewElement2 : mapElement}
        style={{ display: 'none' }}
      ></div>
      <NaverMap ref={roadView ? roadViewElement : mapElement}></NaverMap>
      <RoadViewButton>
        <div>로드뷰</div>
        <img
          style={{ cursor: 'pointer' }}
          src={`/asset/images/icon/${roadView ? 'toggleOn-text' : 'toggleOff-text'}.svg`}
          alt=""
          onClick={() => setRoadView(!roadView)}
        />
      </RoadViewButton>
    </DetailMapContainer>
  );
};

export default DetailMap;

const DetailMapContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  margin-top: 56px;
`;

const NaverMap = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  position: absolute;
`;

const RoadViewButton = styled.div`
  width: 103px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.68);
  position: absolute;
  bottom: 24px;
  right: 12px;
  border-radius: 9999px;
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  z-index: 2;
  gap: 8px;
  justify-content: space-between;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08);
  color: white;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;
