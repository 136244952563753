import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Bounds {
  swLat: number;
  swLng: number;
  neLat: number;
  neLng: number;
}

interface MapContextProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  mapType: string;
  cadastralMap: boolean;
  propertyOn: boolean;
  realPriceOn: boolean;
  auctionOn: boolean;
  nonPropertyOn: boolean;
  zoomLevel: number;
  pnu: string;
  bounds: Bounds;
  setBounds: React.Dispatch<React.SetStateAction<Bounds>>;
  latLng: { lat: number; lng: number } | null;
  setMapType: (type: string) => void;
  setCadastralMap: (value: boolean) => void;
  setPropertyOn: (value: boolean) => void;
  setRealPriceOn: (value: boolean) => void;
  setAuctionOn: (value: boolean) => void;
  setNonPropertyOn: (value: boolean) => void;
  setZoomLevel: (value: number) => void;
  setLatLng: (latLng: { lat: number; lng: number }) => void;
  setPnu: (pnu: string) => void;
  geomData: [number, number][] | null;
  setGeomData: React.Dispatch<React.SetStateAction<[number, number][] | null>>;
}

const MapContext = createContext<MapContextProps | undefined>(undefined);

export const MapProvider = ({ children }: { children: ReactNode }) => {
  const [mode, setMode] = useState('가격정보');
  const [mapType, setMapType] = useState('HYBRID');
  const [cadastralMap, setCadastralMap] = useState(false);
  const [propertyOn, setPropertyOn] = useState(true);
  const [realPriceOn, setRealPriceOn] = useState(false);
  const [auctionOn, setAuctionOn] = useState(true);
  const [nonPropertyOn, setNonPropertyOn] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(16);
  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>({
    lat: 37.4640137,
    lng: 127.0361695,
  });
  const [bounds, setBounds] = useState<Bounds>({
    swLat: 0,
    swLng: 0,
    neLat: 0,
    neLng: 0,
  });
  const [pnu, setPnu] = useState('');
  const [geomData, setGeomData] = useState<[number, number][] | null>([]);
  return (
    <MapContext.Provider
      value={{
        mode,
        mapType,
        cadastralMap,
        propertyOn,
        realPriceOn,
        auctionOn,
        nonPropertyOn,
        zoomLevel,
        latLng,
        bounds,
        pnu,
        setMode,
        setMapType,
        setCadastralMap,
        setPropertyOn,
        setRealPriceOn,
        setAuctionOn,
        setNonPropertyOn,
        setZoomLevel,
        setLatLng,
        setBounds,
        setPnu,
        geomData,
        setGeomData,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMapContext must be used within a MapProvider');
  }
  return context;
};
