import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useState } from 'react';
import NoLoginMoreInfo from './NoLoginMoreInfo';
import LoginMoreInfo from './LoginMoreInfo';
import Notice from './Notice';
import MyPoint from './MyPoint';
import { isLogin } from '../../../utilities/auth';

const MoreInfo = () => {
  const [showButton, setShowButton] = useState(true);
  const [myPoint, setMyPoint] = useState<number>(0);
  const [shownTooltip, setShownTooltip] = useState(true);
  useEffect(() => {
    if (isLogin()) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, []);
  // useEffect(() => {
  //   setShowButton(shownTooltip);
  // }, [shownTooltip]);

  return (
    <MoreInfoContainer
      isLogin={isLogin()}
      showButton={showButton}
      shownTooltip={shownTooltip}
    >
      {showButton ? (
        <NoLoginMoreInfo
          shownTooltip={shownTooltip}
          setShownTooltip={setShownTooltip}
        />
      ) : (
        <LoginMoreInfo />
      )}
      <Notice />
      {/* <MyPoint myPoint={myPoint} isLogin={showButton} />
      <BottomNavBarContainer>
        <BottomNavBtn>
          <img src={`/asset/images/icon/myteraOff.svg`} alt="" />
          <span>관심 주소지</span>
        </BottomNavBtn>
        <BottomNavBtn>
          <img src={`/asset/images/icon/gift.svg`} alt="" />
          <span>땅줍</span>
        </BottomNavBtn>
        <BottomNavBtn>
          <img src={`/asset/images/icon/brokerageOff.svg`} alt="" />
          <span>맞춤중개</span>
        </BottomNavBtn>
      </BottomNavBarContainer> */}
      <BannerContainer>
        <BannerTag>
          <BannerImg
            src="/banner_1.gif"
            onClick={() => {
              window.open(
                'https://transfarmer.notion.site/e3eb7cc33250494c96a32ec1c0d4b759',
              );
            }}
          ></BannerImg>
        </BannerTag>
      </BannerContainer>
    </MoreInfoContainer>
  );
};
export default MoreInfo;

interface MoreInfoLoginProps {
  isLogin: boolean;
  showButton: boolean;
  shownTooltip: boolean;
}

const MoreInfoContainer = styled.div<MoreInfoLoginProps>`
  width: 100%;
  height: ${({ isLogin, showButton, shownTooltip }) =>
    isLogin ? '220px' : showButton && shownTooltip ? '350px' : '306px'};
  padding: 16px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  background-color: ${colors.white};
`;

const BottomNavBarContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const BottomNavBtn = styled.div`
  flex: 1;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  fonw-weight: 500;
  line-height: 20px;
  color: #616161;
`;

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
`;

const BannerTag = styled.a`
  width: 100%;
  display: flex;
  text-decoration: none;
`;

const BannerImg = styled.img`
  cursor: pointer;
`;
