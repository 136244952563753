import styled from 'styled-components';
import colors from '../../../../color';
import { useNavigate } from 'react-router-dom';

const MainSearchBox = () => {
  const navigate = useNavigate();

  return (
    <TopSearchBox>
      <img
        src="/asset/images/logo/logo_normal.svg"
        alt="logo"
        onClick={() => {
          navigate(0);
        }}
        style={{ cursor: 'pointer' }}
      />
      <InputBox onClick={() => {}}>
        <SearchInput
          onClick={() => {
            navigate('/search');
          }}
        >
          내 땅 찾고 평생 소득분석 무료
        </SearchInput>
        <img
          src="/asset/images/icon/search-gray.svg"
          alt="search icon"
          style={{ cursor: 'pointer' }}
        />
      </InputBox>
    </TopSearchBox>
  );
};

export default MainSearchBox;

const TopSearchBox = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const InputBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: ${colors.lightGray100};
  border-radius: 9999px;
`;

const SearchInput = styled.div`
  flex: 1;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelAssistive};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 120px;
  cursor: pointer;
`;
