import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '../components/pagination/Pagination';
import EmptyMessage from './component/message/EmptyMessage';
import {
  NoticeList,
  NoticeWrap,
  NoticeWrapTitle,
  NoticeContentBox,
  NoticeInner,
  NoticeTitleBox,
  NoticeTag,
  NoticeTitle,
  NoticeDate,
  TopNav2,
} from './NewsRoom.style';
import styled from 'styled-components';
import BoardService from '../../service/board';

const NewsRoom = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [newsRoomList, setNewsRoomList] = useState([
    {
      title: '뉴스룸 테스트',
      writeTime: '2021-09-01 00:00:00',
    },
    {
      title: '뉴스룸 테스트2',
      writeTime: '2021-09-01 00:00:00',
    },
    {
      title: '뉴스룸 테스트3',
      writeTime: '2021-09-01 00:00:00',
    },
  ]);
  const board = 'B';

  const getNewsRoomList = async () => {
    try {
      const result = await BoardService.list('B', limit, offset);
      setNewsRoomList(result.result.list);
      setTotalCount(result.result.totalCount);
      setTotalPages(Math.ceil(result.result.totalCount / limit));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNewsRoomList();
  }, [offset]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit);
  };

  return (
    <NoticeWrap>
      <TopNav2>
        <span
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src="/asset/images/arrow/arrow-left-gray.svg"
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
        </span>
        뉴스룸
      </TopNav2>
      <NoticeInner>
        <NoticeWrapTitle>뉴스룸</NoticeWrapTitle>
        <NoticeContentBox>
          {newsRoomList.length > 0 && <div className="top-line"></div>}
          {newsRoomList.length === 0 ? (
            <EmptyMessage message="등록된 뉴스가 없습니다." />
          ) : (
            <NoticeList>
              <ul>
                {newsRoomList.map((item: any) => (
                  <li
                    key={item.articleIdx}
                    onClick={() => {
                      navigate(`/newsroom/detail/${item.articleIdx}`);
                    }}
                  >
                    <NoticeTitleBox>
                      <NoticeTag>뉴스</NoticeTag>
                      <NoticeTitle>{item.title}</NoticeTitle>
                    </NoticeTitleBox>
                    <NoticeDate>{item.createTime}</NoticeDate>
                  </li>
                ))}
              </ul>
              {totalPages >= 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </NoticeList>
          )}
        </NoticeContentBox>
      </NoticeInner>
    </NoticeWrap>
  );
};
export default NewsRoom;
