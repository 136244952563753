import { useEffect, useRef, useState } from 'react';
import { formatToKoreanCurrency } from '../../../../function/function';
import { tagOtion } from '../filter/FilterOption';
import { S } from './List.styles';
import SnackBar from '../../../components/snackBar/SnackBar';
import { renderIcon } from '../../render';
import { SaleData } from '../../MainData';
import { useNavigate } from 'react-router-dom';
import Interaction from '../../../../service/interaction';
import { isLogin } from '../../../../utilities/auth';
import { useFilter } from '../../../../contexts/FilterContext';
import { useMapContext } from '../../../../contexts/MapContext';
import { usePropertyFilter } from '../../../../contexts/PropertyContext';

interface SaleListProps {
  data: SaleData[];
  recommendList?: SaleData[];
  showListAll: boolean;
  setShowListAll?: any;
  smallDevice: boolean;
  deviceHeight: number;
  pageTopHeight?: number;
  setIsMoreList?: (value: boolean) => void;
  onChange?: () => void;
}
const getTypeTagStyles = (type: string) => {
  switch (type) {
    case '매물':
      return {
        backgroundColor: '#FFEDED',
        color: '#EC4F4F',
      };
    case '경매':
      return {
        backgroundColor: '#EBEFFA',
        color: '#3160D6',
      };
    case '공매':
      return {
        backgroundColor: '#F2EBFD',
        color: '#7343E7',
      };
    case '지자체 물건':
      return {
        backgroundColor: '#FDF6EE',
        color: '#F47A00',
      };
    case '인터넷 매물':
      return {
        backgroundColor: '#FFF9E6',
        color: '#C28200',
      };
    default:
      return {
        backgroundColor: '#E6FFE9',
        color: '#097212',
      };
  }
};

const renderTagIcon = (tag: string) => {
  return tagOtion.find((option) => option.text === tag)?.icon;
};

const SaleList = ({
  data,
  recommendList,
  showListAll,
  setShowListAll,
  smallDevice,
  deviceHeight,
  setIsMoreList,
  onChange,
}: SaleListProps) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    leftText: '',
    rightText: '',
  });
  const [listData, setListData] = useState<SaleData[]>([]);
  const listRef = useRef<HTMLDivElement | null>(null);
  const [visibleTags, setVisibleTags] = useState<number>(0);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth - 24);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  const navigate = useNavigate();
  const { latLng } = useMapContext();
  const { sort, setSort, safeProperty, setSafeProperty } = useFilter();
  const { propertySort, setPropertySort, isSafeItem, setIsSafeItem, address } =
    usePropertyFilter();

  const isModalType = window.location.pathname === '/';

  useEffect(() => {
    if (!safeProperty) {
      setListData(data);
    } else {
      setListData(data.filter((item) => item.is_safe_to_sell === 'Y'));
    }
  }, [data, safeProperty, onChange]);

  useEffect(() => {
    if (showSnackBar) {
      const timer = setTimeout(() => {
        setShowSnackBar(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSnackBar]);

  const postLike = async (c_idx: number) => {
    const data = {
      c_idx,
    };
    try {
      const res = await Interaction.like(data);
      onChange && onChange();
    } catch (error) {
      console.error('Error toggling favorite', error);
    }
  };

  const disLike = async (c_idx: number, int_idx: number) => {
    setSelectedIdx(c_idx);
    try {
      const res = await Interaction.disLike(int_idx);
      onChange && onChange();
    } catch (e) {
      console.log(e);
    }
  };
  const handleClickLike = (int_idx: any, c_idx: number) => {
    if (!isLogin()) {
      const userConfirmed = window.confirm('로그인이 필요한 서비스입니다.');

      if (userConfirmed) {
        navigate('/login');
      } else {
        return;
      }
    }
    if (int_idx === null) {
      postLike(c_idx);
      setSnackBarMessage({
        leftText: '찜 완료!',
        rightText: '찜한 주소지 보기',
      });
    } else {
      disLike(c_idx, int_idx);
      setSnackBarMessage({
        leftText: '찜을 해제했어요.',
        rightText: '다시 찜하기',
      });
    }

    setShowSnackBar(true);
  };

  const handleTagOverflow = () => {
    let totalWidth = 0;
    let visibleCount = 1;

    const childNodes = tagContainerRef.current?.childNodes;

    if (childNodes) {
      for (let i = 0; i < childNodes.length; i++) {
        const tagNode = childNodes[i] as HTMLElement;
        totalWidth += tagNode.offsetWidth;
        if (totalWidth <= containerWidth) {
          visibleCount += 1;
        } else {
          break;
        }
      }
    }

    setVisibleTags(visibleCount);
  };

  useEffect(() => {
    handleTagOverflow();

    const handleResize = () => handleTagOverflow();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = () => {
    if (!listRef.current) return;

    // console.log('스크롤 시작');

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;

    // 리스트의 맨 아래에 도달했을 때
    if (scrollTop + clientHeight >= scrollHeight - 10 && setIsMoreList) {
      setIsMoreList(true);
      // console.log('스크롤 끝');
    }
  };

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const getPriceType = (saleType: string) => {
    if (saleType === '매물') {
      return '매매 ';
    } else if (saleType === '실거래') {
      return '매매 ';
    } else if (saleType === '경매') {
      return '최저가 ';
    } else if (saleType === '공매') {
      return '최저가 ';
    } else {
      return '희망가 ';
    }
  };

  const renderToggleButton = () => {
    if (isModalType) {
      return (
        <img
          src={`/asset/images/icon/${safeProperty ? 'toggleOn' : 'toggleOff'}.svg`}
          alt=""
          onClick={() => {
            setSafeProperty(!safeProperty);
          }}
          style={{ cursor: 'pointer', marginRight: '2px' }}
        />
      );
    }
    return (
      <img
        src={`/asset/images/icon/${isSafeItem ? 'toggleOn' : 'toggleOff'}.svg`}
        alt=""
        onClick={() => {
          setIsSafeItem && setIsSafeItem(!isSafeItem);
        }}
        style={{ cursor: 'pointer' }}
      />
    );
  };

  return (
    <S.ListContainer
      showListAll={showListAll}
      smallDevice={smallDevice}
      isModalType={isModalType}
    >
      <S.ListInner>
        {isModalType && (
          <S.ListTop
            onClick={() => {
              setShowListAll(!showListAll);
            }}
          >
            <S.ListTopBtn></S.ListTopBtn>
            <p>
              지역목록{' '}
              {/* <S.TextGreen>{listData.length.toLocaleString()}개</S.TextGreen> */}
            </p>
          </S.ListTop>
        )}
        <S.ListBox
          style={{
            height:
              smallDevice && isModalType
                ? `${deviceHeight - 54}px`
                : !smallDevice && !isModalType
                  ? `${deviceHeight}px`
                  : '520px',
          }}
          ref={listRef}
        >
          <S.ListFilterBox>
            <S.FilterLeft>
              {renderToggleButton()}
              <span>안심 매물만 보기</span>
              <img
                src="./asset/images/icon/information-outline.svg"
                alt=""
                style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                onClick={() => {
                  setShowTooltip(true);
                }}
              />
            </S.FilterLeft>
            <S.FilterSelect
              name=""
              id=""
              onChange={(e) => {
                isModalType
                  ? setSort(e.target.value)
                  : setPropertySort(e.target.value);
              }}
              value={isModalType ? sort : propertySort}
            >
              <option value="PA">낮은 금액순</option>
              <option value="PD"> 높은 금액순</option>
              <option value="YD"> 최신순</option>
              <option value="AD"> 면적순</option>
            </S.FilterSelect>
            {showTooltip && (
              <S.TooltipBox>
                <S.TooltipText>
                  안심 매물은 신뢰할 수 있는 합리적 가격대를 갖춘 매물입니다. 이
                  기능은 주변 유사 매물의 평당 평균 가격을 기준으로 하여,
                  평균가에 근접하는 매물만을 선별해 제공합니다.
                </S.TooltipText>
                <img
                  src="/asset/images/close/close.svg"
                  alt=""
                  onClick={() => {
                    setShowTooltip(false);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </S.TooltipBox>
            )}
          </S.ListFilterBox>
          <div></div>
          <S.List
            style={{
              marginBottom:
                recommendList && recommendList.length > 0 ? '0px' : '54px',
            }}
          >
            {safeProperty && isModalType && listData.length < 1 && (
              <S.NoSafeItem>
                <img src="asset/images/icon/sad-face.svg" alt="" />
                <S.NoSafeItemText>
                  <div>해당 지역엔 안심 매물이 없네요</div>
                  <S.NoSafeItemDescText>
                    일반 매물을 보시려면 안심매물 버튼을 꺼주세요
                  </S.NoSafeItemDescText>
                </S.NoSafeItemText>
              </S.NoSafeItem>
            )}
            {!isModalType &&
              listData.length < 1 &&
              recommendList &&
              recommendList.length < 1 && (
                <S.NoSafeItem>
                  <img src="asset/images/icon/fail-face.svg" alt="" />
                  <S.NoSafeItemText>
                    <div>해당 조건에 맞는 매물이 없네요</div>
                    <S.NoSafeItemDescText>
                      다른 조건을 적용해 보세요
                    </S.NoSafeItemDescText>
                  </S.NoSafeItemText>
                </S.NoSafeItem>
              )}

            {listData.map((card, index) => (
              <S.ListCard
                key={index}
                onClick={() => {
                  isModalType
                    ? navigate(
                        `/detail?c_idx=${card.c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                      )
                    : navigate(`/detail?c_idx=${card.c_idx}`);
                }}
              >
                <S.CardInfo>
                  <S.ImageBox>
                    {card.is_safe_to_sell === 'Y' ? (
                      <S.SafeImg src="/asset/images/icon/safe.svg" alt="" />
                    ) : null}
                    {card.img_url && <S.OverRay></S.OverRay>}
                    <img
                      src={
                        card.img_url
                          ? `${card.img_url}`
                          : '/asset/images/no-img.png'
                      }
                      alt=""
                      style={{
                        borderRadius: '4px',
                        width: '114px',
                        height: '114px',
                      }}
                    />
                  </S.ImageBox>
                  <S.TextBox>
                    <S.TypeTextRow>
                      <S.TypeTag style={getTypeTagStyles(card.sale_type)}>
                        {card.sale_type}
                      </S.TypeTag>
                      <S.TypeTag>
                        {card.type === '건물' ? '주택' : card.type}
                      </S.TypeTag>
                    </S.TypeTextRow>
                    <S.PriInfoRow>
                      <S.Price>
                        {getPriceType(card.sale_type)}
                        {formatToKoreanCurrency(card.price)}
                      </S.Price>
                      <S.Address>
                        <img
                          src={`/asset/images/icon/${renderIcon(card.category)}.svg`}
                          alt=""
                          style={{ width: '16px', height: '16px' }}
                        />
                        <span>{card.legaldong}</span>
                      </S.Address>
                    </S.PriInfoRow>
                    <S.Area>
                      면적
                      <S.TextBlack>{`${Number(card.pyeong).toLocaleString()}평`}</S.TextBlack>
                      • 옵션
                      <S.TextBlack>
                        {card.is_option === 'Y' ? ' 있음' : ' 없음'}
                      </S.TextBlack>
                    </S.Area>
                  </S.TextBox>
                </S.CardInfo>
                <S.CardTagBox ref={tagContainerRef}>
                  {card.theme !== null &&
                    card.theme.slice(0, visibleTags).map((tag, index) => (
                      <S.CardTag key={index}>
                        <img
                          src={`/asset/images/icon/${renderTagIcon(tag)}.svg`}
                          alt=""
                          style={{ width: '16px', height: '16px' }}
                        />
                        <span>{tag}</span>
                      </S.CardTag>
                    ))}
                  {card.theme !== null && card.theme.length > visibleTags && (
                    <S.CardTag>+{card.theme.length - visibleTags}</S.CardTag>
                  )}
                </S.CardTagBox>
              </S.ListCard>
            ))}
          </S.List>
          {recommendList && recommendList.length > 0 && (
            <S.RecommendListBox>
              <S.NearText>
                <S.RegionText>
                  {address.eupmd === '' ? address.sigungu : address.eupmd}
                </S.RegionText>
                <span>근처 매물을 찾아봤어요</span>
              </S.NearText>
              <S.List style={{ marginBottom: '54px' }}>
                {recommendList?.map((card, index) => (
                  <S.ListCard
                    key={index}
                    onClick={() => {
                      isModalType
                        ? navigate(
                            `/detail?c_idx=${card.c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                          )
                        : navigate(`/detail?c_idx=${card.c_idx}`);
                    }}
                  >
                    <S.CardInfo>
                      <S.ImageBox>
                        {card.is_safe_to_sell === 'Y' ? (
                          <S.SafeImg src="/asset/images/icon/safe.svg" alt="" />
                        ) : null}

                        {/* <S.HeartImg
                          src={
                            card.int_idx === null
                              ? '/asset/images/icon/heart.svg'
                              : '/asset/images/icon/red-heart.svg'
                          }
                          alt=""
                          onClick={() => {
                            handleClickLike(card.int_idx, card.c_idx);
                          }}
                        /> */}
                        {card.img_url && <S.OverRay></S.OverRay>}
                        <img
                          src={
                            card.img_url
                              ? `${card.img_url}`
                              : '/asset/images/no-img.png'
                          }
                          alt=""
                          style={{
                            borderRadius: '4px',
                            width: '114px',
                            height: '114px',
                          }}
                        />
                      </S.ImageBox>
                      <S.TextBox>
                        <S.TypeTextRow>
                          <S.TypeTag style={getTypeTagStyles(card.sale_type)}>
                            {card.sale_type}
                          </S.TypeTag>
                          <S.TypeTag>
                            {card.type === '건물' ? '주택' : card.type}
                          </S.TypeTag>
                        </S.TypeTextRow>
                        <S.PriInfoRow>
                          <S.Price>
                            {getPriceType(card.sale_type)}
                            {formatToKoreanCurrency(card.price)}
                          </S.Price>
                          <S.Address>
                            <img
                              src={`/asset/images/icon/${renderIcon(card.category)}.svg`}
                              alt=""
                              style={{ width: '16px', height: '16px' }}
                            />
                            <span>{card.legaldong}</span>
                          </S.Address>
                        </S.PriInfoRow>
                        <S.Area>
                          면적
                          <S.TextBlack>{`${Number(card.pyeong).toLocaleString()}평`}</S.TextBlack>
                          • 옵션
                          <S.TextBlack>
                            {card.is_option === 'Y' ? ' 있음' : ' 없음'}
                          </S.TextBlack>
                        </S.Area>
                      </S.TextBox>
                    </S.CardInfo>
                    <S.CardTagBox ref={tagContainerRef}>
                      {card.theme !== null &&
                        card.theme.slice(0, visibleTags).map((tag, index) => (
                          <S.CardTag key={index}>
                            <img
                              src={`/asset/images/icon/${renderTagIcon(tag)}.svg`}
                              alt=""
                              style={{ width: '16px', height: '16px' }}
                            />
                            <span>{tag}</span>
                          </S.CardTag>
                        ))}
                      {card.theme !== null &&
                        card.theme.length > visibleTags && (
                          <S.CardTag>
                            +{card.theme.length - visibleTags}
                          </S.CardTag>
                        )}
                    </S.CardTagBox>
                  </S.ListCard>
                ))}
              </S.List>
            </S.RecommendListBox>
          )}
        </S.ListBox>
        {isModalType && (
          <S.ToMapBtnBox onClick={() => setShowListAll(false)}>
            <img src="/asset/images/icon/location-white.svg" alt="" />
            <span>지도보기</span>
          </S.ToMapBtnBox>
        )}
        {showSnackBar && (
          <SnackBar
            leftText={snackBarMessage.leftText}
            rightText={snackBarMessage.rightText}
            onClick={() => {
              if (snackBarMessage.rightText === '찜한 주소지 보기') {
                alert('찜한 주소지 보기');
              } else {
                postLike(selectedIdx);
                setSnackBarMessage({
                  leftText: '찜 완료!',
                  rightText: '찜한 주소지 보기',
                });
                setShowSnackBar(true);
              }
            }}
          />
        )}
      </S.ListInner>
    </S.ListContainer>
  );
};

export default SaleList;
