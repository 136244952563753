import './LatestCheckList.css';
import { getItemType, getItemImg } from '../util/ItemType';
import { useNavigate } from 'react-router-dom';
import SearchService from '../../../service/Search';
import { useMapContext } from '../../../contexts/MapContext';
interface LatestCheckListProps {
  latestCheckItemList: LatestCheckItem[];
  func: () => void;
}

interface LatestCheckItem {
  id: number;
  type: string;
  addr: string;
  soldType: string;
  roadAddr: string;
  pnu: string;
}

const LatestCheckList = ({
  latestCheckItemList,
  func,
}: LatestCheckListProps) => {
  const nav = useNavigate();

  const { setLatLng, setPnu, setZoomLevel } = useMapContext();

  const getCoord = async (pnu: string) => {
    let res = await SearchService.coord(pnu);
    if (res.length > 0) {
      setLatLng({ lat: res[0].lat, lng: res[0].lng });
      setPnu(pnu);
      setZoomLevel(16);
    }
    nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
  };

  const deleteLog = async (id: number, type: string) => {
    try {
      await SearchService.deleteLog(id, type);
      func();
    } catch (e) {
      console.log(e);
    }
  };
  const deleteAll = async (type: string) => {
    try {
      await SearchService.deleteAllLog(type);
      func();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="LatestCheckList">
      <div className="LatestCheckList_top_bar">
        <div className="LatestCheckList_top_bar_title">최근 조회 주소지</div>
        <div
          className="LatestCheckList_top_bar_delete"
          onClick={() => {
            deleteAll('B');
          }}
        >
          전체삭제
        </div>
      </div>
      <div className="LatestCheckList_content_box_wrapper">
        <div className="LatestCheckList_content_box">
          {latestCheckItemList.map((item) => (
            <div key={item.id} className="LatestCheckList_item_wrapper">
              <div className="LatestCheckList_item">
                <div
                  className="LatestCheckList_item_content"
                  onClick={() => {
                    getCoord(item.pnu);
                  }}
                >
                  <img
                    className="LatestCheckList_item_type"
                    src={getItemImg(item.type)}
                    alt=""
                  />
                  <div className="LatestCheckList_item_addr">{item.addr}</div>
                  <div className="LatestCheckList_item_soldType">
                    {getItemType(item.soldType)}
                  </div>
                </div>
                <div className="div_img">
                  <img
                    className="LatestCheckList_item_img"
                    src="/asset/images/close/close-lightgray.svg"
                    alt="close"
                    onClick={() => {
                      deleteLog(item.id, 'B');
                      window.alert('삭제');
                    }}
                  />
                </div>
              </div>
              <div className="LatestCheckList_item_roadAddr">
                {item.roadAddr}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestCheckList;
