import ScrollToTopButton from '../components/toTopBtn/ScrollToTopButton';
import './Terms.css';
import { useNavigate } from 'react-router-dom';

const PrivacyTerms = ({ SNS, setTermsOpen }: any) => {
  const navigate = useNavigate();

  const dummy = [
    { name: '1. 개인정보처리방침의 목적', href: '#tag1' },
    { name: '2. 개인정보 수집 항목', href: '#tag2' },
    { name: '3. 개인정보의 처리 및 보유 기간 ', href: '#tag3' },
    { name: '4. 개인정보의 제3자 제공에 관한 사항', href: '#tag4' },
    { name: '5. 개인정보의 파기 절차 및 방법에 관한 사항', href: '#tag5' },
    { name: '6. 미이용자의 개인정보 파기 등에 관한 조치', href: '#tag6' },
    { name: '7. 이용자와 법정대리인의 권리·의무 및 행사방법 ', href: '#tag7' },
    { name: '8. 개인정보의 안전성 확보조치에 관한 사항', href: '#tag8' },
    {
      name: '9. 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항',
      href: '#tag9',
    },
    { name: '10. 가명정보 처리에 관한 사항  ', href: '#tag10' },
    { name: '11. 개인정보 보호책임자와 민원처리 부서', href: '#tag11' },
    { name: '12. 이용자의 권익침해에 대한 구제방법', href: '#tag12' },
  ];

  return (
    <div className="terms-wrap" style={{ height: SNS && '100%' }}>
      <ScrollToTopButton />
      <div className="page-top">
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
        <p>개인정보처리방침</p>
      </div>
      <div className="terms-inner">
        <div className="terms-title">개인정보처리방침</div>
        <div className="terms-content-box">
          <div className="terms-privacy-script">
            트랜스파머(주)(이하 ‘트랜스파머’)는 이용자의 자유와 권리 보호를 위해
            「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게
            개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보
            보호법」 제30조에 따라 이용자에게 개인정보 처리에 관한 절차 및
            기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수
            있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.{' '}
          </div>
          <div className="terms-anchor-list">
            {dummy.map((item: any, idx: number) => {
              return (
                <a
                  key={idx}
                  className="terms-content-item-text pointer"
                  href={item.href}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {item.name}
                </a>
              );
            })}
          </div>
          <div className="terms-content-item">
            <div id="tag1" className="terms-content-item-title">
              1. 개인정보처리방침의 목적
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
              있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
              목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의
              동의를 받는 등 필요한 조치를 이행할 예정입니다.
            </div>
            <div className="terms-content-item-text">
              회원 가입 및 관리 <br />
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
              회원자격 유지·관리, 서비스 부정이용 방지, 만 16세 이상 확인, 각종
              고지·통지, 고충처리, 보안정책 수립 목적으로 개인정보를 처리합니다.
            </div>
            <div className="terms-content-item-text">
              회원 가입 및 관리 <br />
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
              회원자격 유지·관리, 서비스 부정이용 방지, 만 16세 이상 확인, 각종
              고지·통지, 고충처리, 보안정책 수립 목적으로 개인정보를 처리합니다.
            </div>
            <div className="terms-content-item-text">
              회원 가입 및 관리 <br />
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
              회원자격 유지·관리, 서비스 부정이용 방지, 만 16세 이상 확인, 각종
              고지·통지, 고충처리, 보안정책 수립 목적으로 개인정보를 처리합니다.
            </div>
            <div className="terms-content-item-text">
              재화 또는 서비스 제공 <br />
              콘텐츠 제공, 신규 서비스 개발 및 맞춤 서비스 제공, 서비스 품질
              확인 및 개선, 만족도 및 설문 조사, 본인인증, 연령인증, 신규서비스
              이용 시 또는 본인인증 시 보유정보를 이용한 입력편의성 제공, 청구서
              발송, 요금 결제·정산, 채권추심의 목적으로 개인정보를 처리합니다.
            </div>
            <div className="terms-content-item-text">
              마케팅 및 광고에의 활용 <br />
              이벤트 및 광고성 정보 제공 및 참여기회 제공, 상품 및 경품의 배송,
              이벤트 당첨자 선정, 인구통계학적 특성과 이용자의 관심 및 성향의
              추정을 통한 서비스 제공 및 광고 게재, 서비스의 유효성 확인,
              접속빈도 파악 또는 회원의 서비스 이용에 대한 통계를 목적으로
              개인정보를 처리합니다.
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 이용자의 개인정보를 가명화하여 관련 법령에서 정한
              바에 따라 처리할 수 있습니다. 또한, 수집 목적과 합리적 관련성이
              있는 범위에서 관련 법령에 따라 이용자의 추가적인 동의 없이
              이용자의 개인정보를 이용하거나 제공할 수 있습니다. 나아가 이용자의
              개인정보를 모두 익명처리한 정보의 집합물을 관련 법령에 따라
              제공하거나 처리할 수 있습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div id="tag2" className="terms-content-item-title">
              2. 개인정보 수집 항목
            </div>
            <div className="terms-content-item-text">
              &lt;개인정보처리자명&gt;은(는) 다음의 개인정보 항목을 처리하고
              있습니다.
            </div>
            <div className="terms-content-item-text">
              회원 가입 및 관리 <br />• 필수항목 : 성명, 생년월, 휴대폰 번호,
              이메일 주소, 서비스 사용 목적과 관련된 정보(직업군, 농업 경영체
              등록 번호), 통신사, 위치정보(위치정보서비스이용약관 동의에 따르며
              별도의 동의 절차에 따라 처리됨) <br />• 선택항목 : SNS (카카오,
              네이버) 로그인 이용 시, 로그인 정보 식별 값, SNS 프로필 사진{' '}
            </div>
            <div className="terms-content-item-text">
              재화 또는 서비스 제공 <br /> • 필수항목 : 성명, 생년월, 휴대폰
              번호, 이메일 주소, 별명 <br /> •선택항목 : 서비스 사용 목적을 위해
              이용자가 직접 입력하는 정보(농장 현황 및 비용 정보, 영농 사업
              계획, 주택 신축 및 리모델링 계획 정보, 농지연금 가입 조건 정보 등)
            </div>
            <div className="terms-content-item-text">
              마케팅 및 광고에의 활용 <br /> • 필수항목 : 성명, 생년월, 휴대폰
              번호, 이메일주소
            </div>
            <div className="terms-content-item-text">
              서비스 이용과정에서 자동수집되는 정보 <br /> •IP주소, 쿠키,
              Android 광고 ID, 서비스 이용기록, 방문기록, 불량 이용기록,
              기기정보(OS정보){' '}
            </div>
          </div>
          <div className="terms-content-item">
            <div id="tag3" className="terms-content-item-title">
              3. 개인정보의 처리 및 보유 기간
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터
              개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
              개인정보를 처리·보유합니다
            </div>
            <div className="terms-content-item-text">
              각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            </div>
            <div className="terms-content-item-text">
              홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴 시까지{' '}
            </div>
            <div className="terms-content-item-text">
              다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
              보관합니다. <br />
              1) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당
              수사·조사 종료 시까지
              <br />
              2) 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당
              채권·채무관계 정산 시까지
            </div>
            <div className="terms-content-item-text">
              재화 또는 서비스 제공: 재화·서비스 공급완료 및 요금결제·정산
              완료시까지
            </div>
            <div className="terms-content-item-text">
              다만, 부정이용 방지 및 서비스 이용 기록 통계용으로 회사 내부
              방침에 따라 개인을 식별할 수 없는 데이터로 10년간 보관하며, 다음의
              사유에 해당하는 경우에는 해당 기간 종료 시까지 보관합니다. <br />
              1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른
              표시·광고, 계약내용 및 이행 등 거래에 관한 기록 <br />
              - 표시·광고에 관한 기록 : 6개월 <br />
              - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 <br />-
              소비자 불만 또는 분쟁처리에 관한 기록 : 3년 <br />
              2) 「통신비밀보호법」에 따른 통신사실확인자료 보관 <br />
              - 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수,
              발신기지국 위치추적자료 : 1년 <br />- 컴퓨터통신, 인터넷
              로그기록자료, 접속지 추적자료 : 3개월
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 개인정보를 파기해야 하는 경우 해당 개인정보를 식별할
              수 없는 방법으로 파기하거나, 더 이상 특정 개인인 이용자를 알아볼
              수 없도록 해당 정보를 익명처리합니다. 회사가 관련 법령에 따라 일정
              기간 보관하는 개인정보의 경우에도 해당 기간이 도과하면 위와 같은
              방법으로 안전하게 파기하거나 익명처리합니다.
            </div>
            <div className="terms-content-item">
              <div id="tag4" className="terms-content-item-title">
                4. 개인정보의 제3자 제공에 관한 사항
              </div>
              <div className="terms-content-item-text">
                트랜스파머는 이용자의 별도 동의가 있거나 법령에 규정된 경우를
                제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.{' '}
                <br /> 다만, 트랜스파머는 정부 관계부처가 합동으로 발표한
                「긴급상황 시 개인정보 처리 및 보호수칙」에 따라 재난, 감염병,
                급박한 생명·신체 위험을 초래하는 사건·사고, 급박한 재산 손실
                등의 긴급상황이 발생하는 경우 이용자의 동의 없이 관계기관에
                개인정보를 제공할 수 있습니다. 자세한 사항은 여기를 눌러
                확인하시기 바랍니다.
              </div>
            </div>
            <div className="terms-content-item">
              <div id="tag5" className="terms-content-item-title">
                5. 개인정보의 파기 절차 및 방법에 관한 사항
              </div>
              <div className="terms-content-item-text">
                트랜스파머는 개인정보 보유기간의 경과, 처리목적 달성 등
                개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
                파기합니다.
              </div>
              <div className="terms-content-item-text">
                이용자로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
                달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
                보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터
                베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
              </div>
              <div className="terms-content-item">
                <div id="tag6" className="terms-content-item-title">
                  6. 미이용자의 개인정보 파기 등에 관한 조치
                </div>
                <div className="terms-content-item-text">
                  트랜스파머는 1년간 서비스를 이용하지 않은 이용자는
                  휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다.
                  분리 보관된 개인정보는 1년간 보관 후 지체없이 파기합니다.
                </div>
                <div className="terms-content-item-text">
                  트랜스파머는 휴먼전환 30일 전까지 휴면예정 회원에게 별도 분리
                  보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보
                  항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고
                  있습니다.
                </div>
                <div className="terms-content-item-text">
                  휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전
                  서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로
                  전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라
                  휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.
                </div>
                <div className="terms-content-item-text">
                  개인정보 파기의 절차 및 방법은 다음과 같습니다.
                  <br /> 1) 파기절차 <br />
                  트랜스파머는 파기 사유가 발생한 개인정보를 선정하고,
                  트랜스파머의 개인 정보 보호책임자의 승인을 받아 개인정보를
                  파기합니다. <br />
                  2)파기방법 <br />
                  트랜스파머는 전자적 파일 형태로 기록·저장된 개인정보는 기록을
                  재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는
                  분쇄기로 분쇄하거나 소각하여 파기합니다.
                </div>
              </div>
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title " id="tag7">
              7. 이용자와 법정대리인의 권리·의무 및 행사방법
            </div>
            <div className="terms-content-item-text">
              이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
              요구 등의 권리를 행사할 수 있습니다. <br />※ 만 16세 미만 아동에
              관한 개인정보의 열람등 요구는 법정대리인이 직접 해야 하며, 만 16세
              이상의 미성년자인 이용자는 이용자의 개인정보에 관하여 미성년자
              본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도
              있습니다.
            </div>
            <div className="terms-content-item-text">
              권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에
              따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며,
              트랜스파머는 이에 대해 지체없이 조치하겠습니다.
            </div>
            <div className="terms-content-item-text">
              권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을
              통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한
              고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다.
            </div>
            <div className="terms-content-item-text">
              개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
              제37조 제2항에 의하여 이용자의 권리가 제한 될 수 있습니다.
            </div>
            <div className="terms-content-item-text">
              개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
              대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구,
              처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
              대리인인지를 확인합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title" id="tag8">
              8. 개인정보의 안전성 확보조치에 관한 사항
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
              취하고 있습니다.
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 이용자의 개인정보를 처리함에 있어 개인정보가 분실 ,
              도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
              트랜스파머는 개인정보 보호를 위한 내부 정책을 수립하여 시행하고
              있습니다.
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 개인정보를 처리하는 직원을 최소 인원으로 하며, 각
              직원별 회원정보 접근 권한을 달리하고 있습니다. 또한 서비스의 개발
              및 운영 과정에서 개인정보가 충분히 보호되는지 점검하고 있으며,
              개인정보처리직원에 대한 수시 교육을 통하여 개인정보보호의 중요성을
              강조하고 있습니다.
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의
              개인정보가 유출되거나 훼손되는 것을 막기 위해 개인정보 접근권한
              관리 및 암호화 등의 기술적 보호조치도 취하고 있습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title " id="tag9">
              9. 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한
              사항
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용
              정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
              쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
              하드디스크에 저장되기도 합니다.
            </div>
            <div className="terms-content-item-text">
              1)쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
              방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
              이용자에게 최적화된 정보 제공을 위해 사용됩니다. <br />
              2)쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
              옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
              있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에
              어려움이 발생할 수 있습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title " id="tag10">
              10. 가명정보 처리에 관한 사항
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여
              필요한 경우 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명
              처리할 수 있습니다.
            </div>
            <div className="terms-content-item-text">
              「개인정보 보호법」 제 28조의2부터 제28조의7에 따라 가명정보
              처리·위탁·제3자제공을 하는 경우, 본 개인정보처리방침을 통하여 공개
              하겠습니다. 이 때 쿠팡은 최소한의 항목을 가명처리하겠으며,
              가명정보가 재식별되지 않도록 분리 및 관리하고 필요한 기술적·관리적
              보호조치를 취하겠습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title " id="tag11">
              11. 개인정보 보호책임자와 민원처리 부서
            </div>
            <div className="terms-content-item-text">
              트랜스파머는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
              개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여
              아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </div>
            <div className="terms-content-item-text">
              ‣ 개인정보 보호책임자 성명 : 박성필 <br />
              ‣ 직책 : CIO <br />
              ‣ 연락처 : 010-8990-7306 sp.park@transfarmer.co.kr <br />
            </div>
            <div className="terms-content-item-text">
              이용자는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를
              아래의 부서에 할 수 있습니다. 트랜스파머는 이용자의 개인정보
              열람청구가 신속하게 처리되도록 노력하겠습니다.
            </div>
            <div className="terms-content-item-text">
              ‣ 대표번호: 02)555-2822 <br />‣ 이메일: help@transfarmer.co.kr
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title " id="tag12">
              12. 이용자의 권익침해에 대한 구제방법
            </div>
            <div className="terms-content-item-text">
              개인정보침해에 대한 신고나 상담이 필요한 경우에는 아래 기관에
              문의하시기 바랍니다.
            </div>
            <div className="terms-content-item-text">
              ‣ 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118) <br />
              ‣ 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301) <br />
              ‣ 경찰청 사이버수사국 (ecrm.cyber.go.kr / 국번없이 182) <br />‣
              개인정보분쟁조정위원회(www.kopico.go.kr / 국번없이 1833-6972)
            </div>
            <div className="terms-content-item-text">
              「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의
              정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대
              하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
              이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
              청구할 수 있습니다.
            </div>
            <div className="terms-content-item-text">
              ‣ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)
            </div>
            <div className="supplementary-provisions">
              이 개인정보 처리방침은 2023. 11. 1일부터 적용됩니다.{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyTerms;
