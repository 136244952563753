export const getItemType = (soldType: string) => {
  switch (soldType) {
    case 's':
      return '매물';
    case 'p':
      return '공매';
    case 'a':
      return '경매';
    case 'i':
      return '인터넷 매물';
    case 'b':
      return '농지은행';
    default:
      return soldType;
  }
};

export const getItemImg = (type: string) => {
  switch (type) {
    case 'building':
      return '/asset/images/icon/search/construction.svg';
    case 'land':
      return '/asset/images/icon/search/land.svg';
    case 'location':
      return '/asset/images/icon/search/markerOff.svg';
    case 'public':
      return '/asset/images/icon/search/transportation.svg';
    default:
      return type;
  }
};
