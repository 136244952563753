import { useState } from 'react';
import { transNum } from '../../../../../../function/function';
import { BoxGreen1 } from '../../components/Box';
import {
  RowSectionTitle,
  RowTableGreenBold,
  RowTableValue,
  RowTableValueBold,
} from '../../components/Row';
import { TextSectionTitle } from '../../components/Text';
import DoughnutChart2 from '../../components/DoughnutChart2';
import { Tooltip2 } from '../../components/Tooltip';
import { ExpectedCostProps } from '../../../../data';

const ExpectedCost = ({
  expectedCost,
}: {
  expectedCost: ExpectedCostProps;
}) => {
  const x =
    expectedCost.laborCost +
    expectedCost.materialCost +
    expectedCost.waterHeatingCost +
    expectedCost.farmMachineryCost +
    expectedCost.SGA +
    expectedCost.orchardCost;
  let x2 = expectedCost.estimatedPrice + expectedCost.facilityCost;

  const startArr = [
    {
      color: '#1E9B6B',
      text: '토지 매입비',
      rate: (expectedCost.estimatedPrice * 100) / x2,
      value: expectedCost.estimatedPrice,
    },
    {
      color: '#09CE7D',
      text: '시설 구축비',
      rate: (expectedCost.facilityCost * 100) / x2,
      value: expectedCost.facilityCost,
    },
  ];

  const costArr = [
    {
      color: '#FF497A',
      text: '인건비',
      rate: (expectedCost.laborCost * 100) / x,
      value: expectedCost.laborCost,
    },
    {
      color: '#F59F00',
      text: '재료비',
      rate: (expectedCost.materialCost * 100) / x,
      value: expectedCost.materialCost,
    },
    {
      color: '#1E9B6B',
      text: '수도 광열비',
      rate: (expectedCost.waterHeatingCost * 100) / x,
      value: expectedCost.waterHeatingCost,
    },
    {
      color: '#F885AD',
      text: '농구비',
      rate: (expectedCost.farmMachineryCost * 100) / x,
      value: expectedCost.farmMachineryCost,
    },
    {
      color: '#1D74F7',
      text: '기타 판매관리비',
      rate: (expectedCost.SGA * 100) / x,
      value: expectedCost.SGA,
    },
    {
      color: '#22B8CF',
      text: '농지 임차료',
      rate: 0,
      value: 0,
    },
    {
      color: '#9775FA',
      text: '과수원 조성비',
      rate: (expectedCost.orchardCost * 100) / x,
      value: expectedCost.orchardCost,
    },
  ];

  const y = expectedCost.laborCost + expectedCost.materialCost;
  const z =
    expectedCost.waterHeatingCost +
    expectedCost.farmMachineryCost +
    expectedCost.SGA +
    expectedCost.orchardCost;

  if (!(costArr[5].value > 0)) {
    costArr.splice(5, 1);
  } else {
    startArr.splice(0, 1);
    x2 = x2 - expectedCost.estimatedPrice;
  }
  if (!(costArr[5].value > 0)) {
    costArr.splice(5, 1);
  }

  const [onToolTip, setOnToolTip] = useState(false);

  return (
    <div
      //   className="padding-b-24 relative"
      style={{
        width: '100%',
        paddingBottom: '24px',
        backgroundColor: 'white',
      }}
    >
      <RowSectionTitle>
        <TextSectionTitle>5. 예상 비용</TextSectionTitle>
      </RowSectionTitle>

      <div className="padding-16-16-0" style={{ width: '100%' }}>
        <RowTableGreenBold
          title="초기 투자비"
          value={`${transNum(x2)}` + '원'}
        />
      </div>
      <div className="padding-24-16 flex-row center-y space-between">
        <div className="w-50per center-y" style={{ height: '132px' }}>
          <DoughnutChart2 list={startArr} />
        </div>
        <div className="flex-column gap-8" style={{ width: '144px' }}>
          {startArr.map((el) => (
            <div className="flex-row space-between">
              <div className="flex-row center-y gap-8">
                <div
                  style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '100%',
                    background: el.color,
                  }}
                ></div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  {el.text}
                </div>
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-primary">
                {Math.round(el.rate * 10) / 10}%
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="padding-8-16-16 gap-8 flex-column">
        <RowTableValue
          title="토지 매입비"
          value={`${transNum(expectedCost.estimatedPrice)}` + '원'}
        />
        <RowTableValue
          title="시설 구축비"
          warn
          onClick={() => setOnToolTip(true)}
          value={`${transNum(expectedCost.facilityCost)}` + '원'}
        />
      </div>
      <Tooltip2
        text="시설 구축비는 평균값을 적용한 것으로 실제 비용과는 일부 차이가 있을 수 있습니다."
        width
        top="0"
        left="8"
        on={onToolTip}
        off={() => setOnToolTip(false)}
      />
      <div className="padding-8-16">
        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
          ※ 농기계 구입비는 변동성이 높아 초기 투자비에 포함시키지 않았습니다.
        </div>
      </div>
      <div className="padding-16 w-100per">
        <div
          className="w-100per"
          style={{ height: '1px', background: '#171e2610' }}
        />
      </div>

      <div className="padding-16-16-0">
        <RowTableGreenBold
          title="연간 운영비"
          value={`${transNum(x)}` + '원'}
        />
      </div>
      <div className="padding-24-16 flex-row center-y space-between">
        <div className="w-50per center-y">
          <DoughnutChart2 list={costArr} />
        </div>
        <div className="flex-column gap-8" style={{ width: '144px' }}>
          {costArr.map((el) => (
            <div className="flex-row space-between">
              <div className="flex-row center-y gap-8">
                <div
                  style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '100%',
                    background: el.color,
                  }}
                ></div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  {el.text}
                </div>
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-primary">
                {Math.round(el.rate * 10) / 10}%
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="padding-8-16-16 gap-8 flex-column">
        <RowTableValueBold
          title="1) 매출원가"
          value={`${transNum(y)}` + '원'}
        />
        <RowTableValue
          title="인건비"
          value={`${transNum(expectedCost.laborCost)}` + '원'}
        />
        <RowTableValue
          title="재료비"
          value={`${transNum(expectedCost.materialCost)}` + '원'}
        />
      </div>

      <div className="padding-8-16-16 flex-column gap-8">
        <RowTableValueBold
          title="2) 판매관리비"
          value={`${transNum(z)}` + '원'}
        />
        <RowTableValue
          title="수도광열비"
          value={`${transNum(expectedCost.waterHeatingCost)}` + '원'}
        />
        <RowTableValue
          title="농구비"
          value={`${transNum(expectedCost.farmMachineryCost)}` + '원'}
        />
        <RowTableValue
          title="기타 판매관리비"
          value={`${transNum(expectedCost.SGA)}` + '원'}
        />
        {/* {expectedCost.landRentalCost !== 0 ? (
          <RowTableValue
            title="농지 임차료"
            value={`${transNum(expectedCost.landRentalCost)}`}
          />
        ) : (
          ''
        )} */}
        {expectedCost.orchardCost !== 0 ? (
          <RowTableValue
            title="과수원 조성비"
            value={`${transNum(expectedCost.orchardCost)}` + '원'}
          />
        ) : (
          ''
        )}
      </div>

      <div className="padding-8-16 gap-10">
        <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            총 사업비는 초기 투자비 {transNum(x2) + '원'}과 <br />
            연간운영비 {transNum(x) + '원'}을 합친 <br />
            {transNum(x + x2) + '원'}으로 예상됩니다
          </div>
        </BoxGreen1>
      </div>
    </div>
  );
};
export default ExpectedCost;
