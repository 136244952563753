import { useEffect, useState } from 'react';
import {
  ModalContainer,
  ModalInner,
  ModalTitle,
  Section,
  SectionLabel,
  SectionBox,
  ApplyBtnBox,
  ResetBtn,
  ResetBtnText,
  ApplyBtn,
  SelectedRegionBox,
  SelectedRegionText,
  RegionText,
  OptionItem,
  OptionColumn30,
  OptionColumn40,
  OptionItemBg,
} from './RegionModal.styles';
import Sales from '../../../../service/Sales';
import { useNavigate } from 'react-router-dom';
import SearchService from '../../../../service/Search';
import { useMapContext } from '../../../../contexts/MapContext';

interface RegionModalProps {
  setRegionModalOn: (value: boolean) => void;
  smallDevice: boolean;
  deviceHeight: number;
}
interface RegionProps {
  code: string;
  region: string;
}

const RegionModal = ({
  setRegionModalOn,
  deviceHeight,
  smallDevice,
}: RegionModalProps) => {
  const nav = useNavigate();
  const [ctp, setCtp] = useState('');
  const [sig, setSig] = useState('');
  const [emd, setEmd] = useState('');
  const [ctpRegion, setCtpRegion] = useState('');
  const [sigRegion, setSigRegion] = useState('');
  const [emdRegion, setEmdRegion] = useState('');
  const [ctpList, setCtpList] = useState<RegionProps[]>([]);
  const [sigList, setSigList] = useState<RegionProps[]>([]);
  const [emdList, setEmdList] = useState<RegionProps[]>([]);
  const { setLatLng, setPnu, setZoomLevel } = useMapContext();

  const getRegion = async (region: string) => {
    try {
      const res = await Sales.region(region);
      return res.result as RegionProps[];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  console.log('emdList',emdList)
  

  useEffect(() => {
    if (ctp === '') {
      getRegion('').then(setCtpList);
    }
  }, []);

  useEffect(() => {
    if (ctp !== '') {
      getRegion(ctp).then(setSigList);
    } else {
      setSigList([]);
    }
  }, [ctp]);

  useEffect(() => {
    const loadEmdList = async () => {
      if (sig !== '') {
        const emdRegions = await getRegion(sig);
        setEmdList(emdRegions);
        if (emdRegions.length > 0) {
          setEmd(emdRegions[0].code);
          setEmdRegion(emdRegions[0].region);
        }
      } else {
        setEmdList([]);
        setEmd('');
        setEmdRegion('');
      }
    };
    loadEmdList();
  }, [sig]);

  const handleReset = () => {
    setCtp('');
    setSig('');
    setEmd('');
    setSigList([]);
    setEmdList([]);
  };

  const checkAllSelected = () => {
    return ctp !== '' && sig !== '';
  };

  const getCoord = async (pnu: string) => {
    console.log('pnu', pnu);
    if (pnu.length < 6) {
      //읍면동까지 선택하지 않았을 경우
      setZoomLevel(12);
    } else {
      //읍면동까지 선택했을 경우
      setZoomLevel(13);
    }

    let res = await SearchService.coord(pnu);
    if (res.length > 0) {
      setLatLng({ lat: res[0].lat, lng: res[0].lng });
      setPnu(pnu);
    }
    nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
  };

  return (
    <ModalContainer>
      <ModalInner smallDevice={smallDevice}>
        <ModalTitle>
          <span>지역 설정</span>
          <img
            src="/asset/images/close/close-gray.svg"
            alt="Close"
            onClick={() => {
              setRegionModalOn(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </ModalTitle>
        <Section>
          <SectionLabel>지역을 선택해 주세요 </SectionLabel>
          <SelectedRegionBox>
            {ctp === '' ? (
              <RegionText>도/시 선택</RegionText>
            ) : (
              <SelectedRegionText>{ctpRegion}</SelectedRegionText>
            )}
            <img src="/asset/images/arrow/arrow-right-darkgray.svg" alt="" />
            {sig === '' ? (
              <RegionText>시/군/구 선택</RegionText>
            ) : (
              <SelectedRegionText>{sigRegion}</SelectedRegionText>
            )}
            <img src="/asset/images/arrow/arrow-right-darkgray.svg" alt="" />
            {emd === '' ? (
              <RegionText>읍/면/동 선택</RegionText>
            ) : (
              <SelectedRegionText>{emdRegion}</SelectedRegionText>
            )}
          </SelectedRegionBox>
        </Section>
        <SectionBox>
          <OptionColumn30
            style={{
              height: smallDevice ? `${deviceHeight - 290}px` : '327px',
            }}
          >
            {ctpList.map((item, index) => (
              <OptionItem
                key={index}
                selected={ctp === item.code}
                onClick={() => {
                  setCtp(item.code);
                  setCtpRegion(item.region);
                  if (ctp !== item.code) {
                    setSig('');
                    setEmd('');
                    setSigList([]);
                    setEmdList([]);
                  }
                }}
              >
                {item.region}
              </OptionItem>
            ))}
          </OptionColumn30>
          <OptionColumn40
            style={{
              height: smallDevice ? `${deviceHeight - 290}px` : '327px',
            }}
          >
            {sigList.map((item, index) => (
              <OptionItemBg
                key={index}
                selected={sig === item.code}
                onClick={() => {
                  setSig(item.code);
                  setSigRegion(item.region);
                  if (sig !== item.code) {
                    setEmd('');
                    setEmdList([]);
                  }
                }}
              >
                {item.region}
              </OptionItemBg>
            ))}
          </OptionColumn40>
          <OptionColumn40
            style={{
              height: smallDevice ? `${deviceHeight - 290}px` : '327px',
            }}
          >
            {emdList.map((item, index) => (
              <OptionItemBg
                key={index}
                selected={emd === item.code}
                onClick={() => {
                  setEmd(item.code);
                  setEmdRegion(item.region);
                }}
              >
                {item.region}
              </OptionItemBg>
            ))}
          </OptionColumn40>
        </SectionBox>
        <ApplyBtnBox>
          <ResetBtn onClick={handleReset}>
            <img src="/asset/images/icon/reset.svg" alt="" />
            <ResetBtnText>초기화</ResetBtnText>
          </ResetBtn>
          <ApplyBtn
            onClick={() => {
              if (!checkAllSelected()) {
                //시도만 선택했을 경우 api호출이 되지 않게하기 위함
                return;
              } else {
                setRegionModalOn(false);
                SearchService.searchLog(
                  ctpRegion + ' ' + sigRegion + ' ' + emdRegion,
                  emd,
                );
                getCoord(emd);
              }
            }}
            active={checkAllSelected()}
          >
            {checkAllSelected()
              ? `${emdList.find((item) => item.code === emd)?.region || emd} 결과보기`
              : '지역선택'}
          </ApplyBtn>
        </ApplyBtnBox>
      </ModalInner>
    </ModalContainer>
  );
};

export default RegionModal;
