import { useState } from 'react';
import { RowSectionTitle } from '../../components/Row';
import { TextSectionTitle } from '../../components/Text';
import { ButtonSelectorBox } from '../../components/Button';
import TempCom from '../../components/Temp';
import { TempertureDataProps } from '../../../../data';

const Temperature = ({
  tempertureData,
}: {
  tempertureData: TempertureDataProps;
}) => {
  const [tab, setTab] = useState(1);

  const btnArr = [
    { children: '월평균 기온', selected: 1 },
    '',
    { children: '월평균 최고기온', selected: 2 },
  ];

  let date = new Date();
  return (
    <div
      className="padding-b-24"
      style={{ width: '100%', opacity: 'none', backgroundColor: 'white' }}
    >
      <RowSectionTitle>
        <TextSectionTitle>2. 농업기상</TextSectionTitle>
      </RowSectionTitle>

      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

      {tab === 1 ? (
        <TempCom.AvgTempOfMonth tempertureData={tempertureData} />
      ) : tab === 2 ? (
        <TempCom.AvgMaxTempOfMonth tempertureData={tempertureData} />
      ) : (
        ''
      )}
    </div>
  );
};

export default Temperature;
