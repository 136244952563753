import './LatestSearchList.css';
import { useNavigate } from 'react-router-dom';
import SearchService from '../../../service/Search';
import { useMapContext } from '../../../contexts/MapContext';
interface LatestSearchListProps {
  latestSearchItemList: latestSearchItem[];
  func: () => void;
}

interface latestSearchItem {
  id: number;
  search: string;
  pnu: string;
}

const LatestSearchList = ({
  latestSearchItemList,
  func,
}: LatestSearchListProps) => {
  const nav = useNavigate();
  const itemList = latestSearchItemList;
  const { setLatLng, setPnu, setZoomLevel } = useMapContext();

  const getCoord = async (pnu: string) => {
    let res = await SearchService.coord(pnu);
    if (res.length > 0) {
      setLatLng({ lat: res[0].lat, lng: res[0].lng });
      setPnu(pnu);
      setZoomLevel(16);
    }
    nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
  };

  const deleteLog = async (id: number, type: string) => {
    try {
      await SearchService.deleteLog(id, type);
      func();
    } catch (e) {
      console.log(e);
    }
  };

  const deleteAll = async (type: string) => {
    try {
      await SearchService.deleteAllLog(type);
      func();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="LatestSearchList">
      <div className="LatestSearchList_top_bar">
        <div className="LatestSearchList_top_bar_title">최근 검색</div>
        <div
          onClick={() => {
            deleteAll('A');
          }}
          className="LatestSearchList_top_bar_delete_text"
        >
          전체삭제
        </div>
      </div>
      <div className="LatestSearchList_content_box_wrapper">
        {itemList.map((item, index) => {
          return (
            <div className="LatestSearchList_content_box">
              <div
                key={index}
                className="LatestSearchList_content_box_text"
                onClick={() => {
                  getCoord(item.pnu);
                }}
              >
                {item.search}
              </div>
              <img
                className="LatestSearchList_content_box_img"
                src="/asset/images/close/close-lightgray.svg"
                alt="close"
                onClick={() => {
                  window.alert('삭제');
                  deleteLog(item.id, 'A');
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LatestSearchList;
