import { useState } from 'react';
import {
  RowSectionTitle,
  RowTableGreenBold,
  RowTableValue2,
  RowTableValueBold,
} from '../../components/Row';
import { TextSectionTitle } from '../../components/Text';
import {
  formatToKoreanCurrency,
  transNum,
  transNum2,
} from '../../../../../../function/function';
import { BoxGreen1 } from '../../components/Box';
import { ExpectedInvestInfoProps } from '../../../../data';

const ExpectedROI = ({
  expectedInvestInfo,
}: {
  expectedInvestInfo: ExpectedInvestInfoProps;
}) => {
  const [onToolTip, setOnToolTip] = useState(false);

  return (
    <div
      className="padding-b-24 relative"
      style={{ width: '100%', backgroundColor: 'white' }}
    >
      <RowSectionTitle>
        <TextSectionTitle>7. 예상 투자수익</TextSectionTitle>
      </RowSectionTitle>
      <div className="padding-16-16-10 relative">
        <RowTableGreenBold
          title="예상 투자수익률"
          value={`${expectedInvestInfo.investRate}%`}
          warn
          onClick={() => setOnToolTip(true)}
        />
        <div className="absolute center-x">
          {onToolTip && (
            <div
              className="flex-row bg-blue-gray-700 border-radius-8 padding-12-16 tool-tip start-y center-x w-218 gap-10 z-10 absolute"
              style={{
                top: `${-60}px`,
                left: '0px',
              }}
            >
              <div className="flex-column gap-16">
                <div className="flex-row center-y gap-3">
                  <div className="font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap">
                    투자 수익률(ROI) =
                  </div>
                  <div className="flex-column gap-10">
                    <div className="font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap center-x">
                      순이익
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '2px',
                        background: '#eff0f6',
                      }}
                    />
                    <div className="font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap center-x">
                      초기 투자비
                    </div>
                  </div>
                  <div className="font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap">
                    × 100
                  </div>
                </div>
                <div className="font-medium f-size-11 line-h-12 color-EFF0F6">
                  투자수익률은 내 투자금에 대한 순이익 비율을 뜻함
                  <br />
                  <br />
                  예시) 3% 투자 수익률 = 100만원 투자 시 3만원의 순이익 창출
                </div>
              </div>
              <img
                alt=""
                className="hover"
                src="/asset/images/close/CloseWhite12.svg"
                onClick={() => setOnToolTip(false)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="padding-8-16-0 flex-column gap-8">
        <RowTableValueBold
          title="초기 투자 비용"
          value={
            `${transNum(expectedInvestInfo.landPurchaseCost + expectedInvestInfo.farmMachineryCost + expectedInvestInfo.facilityCost)}` +
            '원'
          }
        />
      </div>
      <div className="padding-8-16 flex-column gap-8">
        <RowTableValue2
          title="토지 매입비"
          value={`${transNum(expectedInvestInfo.landPurchaseCost)}` + '원'}
        />
        <RowTableValue2
          title="시설 구축비"
          value={`${transNum(expectedInvestInfo.facilityCost)}` + '원'}
        />
        <RowTableValue2
          title="농기계 구입비"
          value={`${transNum(expectedInvestInfo.farmMachineryCost)}` + '원'}
        />
      </div>
      <div className="padding-8-16 flex-column gap-8">
        <RowTableValueBold
          title="순이익"
          value={`${transNum2(expectedInvestInfo.realIncome)}` + '원'}
        />
      </div>
      <div className="padding-8-16 gap-10">
        <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            AI 예상 투자수익률은 {expectedInvestInfo.investRate}%로
          </div>
          <div
            className="font-bold f-size-16 line-h-24 "
            style={{
              color:
                expectedInvestInfo.investRate <= expectedInvestInfo.investRate
                  ? '#1E9B6B'
                  : '#d62554',
            }}
          >
            전국 평균 {expectedInvestInfo.investRate}%
            {expectedInvestInfo.investRate > expectedInvestInfo.investRate
              ? `보다 ${
                  Math.round(
                    (expectedInvestInfo.investRate -
                      expectedInvestInfo.investRate) *
                      10,
                  ) / 10
                }% 높습니다.`
              : expectedInvestInfo.investRate < expectedInvestInfo.investRate
                ? `보다 ${
                    Math.round(
                      (expectedInvestInfo.investRate -
                        expectedInvestInfo.investRate) *
                        10,
                    ) / 10
                  }% 낮습니다.`
                : '와 같습니다.'}
          </div>
        </BoxGreen1>
      </div>
    </div>
  );
};
export default ExpectedROI;
