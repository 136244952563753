import { useNavigate } from 'react-router-dom';
import './Terms.css';
import ScrollToTopButton from '../components/toTopBtn/ScrollToTopButton';

const LocationTerms = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-wrap">
      <ScrollToTopButton />
      <div className="page-top">
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
        <p>위치기반서비스 이용약관</p>
      </div>
      <div className="terms-inner">
        <div className="terms-title">위치기반서비스 이용약관</div>
        <div className="terms-content-box">
          <div className="terms-content-item">
            <div className="terms-content-item-title">제1조(목적)</div>
            <div className="terms-content-item-text">
              이 약관은 트랜스파머(주) (이하 “트랜스파머” 또는 “회사”)가
              제공하는 위치기반서비스와 관련하여 트랜스파머와
              개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을
              규정함을 목적으로 합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제2조(계약의 체결 및 해지){' '}
            </div>
            <div className="terms-content-item-text">
              개인위치정보주체는 트랜스파머의 서비스를 이용하고자 하는 경우,
              약관의 고지 내용에 따라 개인위치정보 서비스에 가입하게 됩니다.
              회원의 경우 회원가입 시 동의절차를 밟으며, 비회원인 경우 서비스를
              이용하는 동안 이 약관에 동의한 것으로 간주합니다. 개인정보주체가
              계약을 해지하고자 할 때에는 가입 회원탈퇴를 하여야 합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제3조(개인위치정보의 이용 또는 제공에 관한 동의)
            </div>

            <div className="terms-content-item-text">
              1. 트랜스파머는 개인위치정보를 이용하여 위치기반서비스를 제공하는
              경우 본 약관에 고지하고 동의를 받습니다.
            </div>
            <div className="terms-content-item-text">
              2. 트랜스파머는 개인위치정보주체의 동의 없이 개인위치정보를
              제3자에게 제공하지 않으며, 제3자에게 제공하는 경우에는 제공받는 자
              및 제공목적을 사전에 개인위치정보주체에게 고지하고 동의를
              받습니다.
            </div>
            <div className="terms-content-item-text">
              3. 2항에 따라 개인위치정보를 개인위치정보주체가 지정하는 제3자에게
              제공하는 경우 개인위치정보를 수집한 통신단말장치 또는
              전자우편주소로 매회 개인위치정보주체에게 제공받는 자, 제공일시 및
              제공목적을 즉시 통지합니다.
            </div>
            <div className="terms-content-item-text">
              4. 트랜스파머는 위치정보의 보호 및 이용 등에 관한 법률 제16조
              제2항에 근거하여 위치정보 수집·이용·제공사실 확인자료를 자동으로
              기록·보존하며, 해당 자료는 6개월간 보관합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">제4조(서비스 내용)</div>
            <div className="terms-content-item-text">
              1. 트랜스파머는 위치정보사업자로부터 위치정보를 전달받아 아래와
              같은 위치기반서비스를 제공합니다.
            </div>
            <div className="terms-content-item-text">
              접속 위치 제공 서비스: 위치 정보 사용을 승인한 이용자들의 서비스
              최종 접속 위치를 기반으로 서비스 내의 정보를 지도 위에 혹은
              리스트를 통해 제공합니다 . 위치정보를 활용한 검색결과 및 콘텐츠
              제공 : 개인정보주체가 정보 검색을 요청하거나 개인위치정보주체의
              이동성 있는 기기의 위치정보를 제공을 통하여 본 위치정보를 이용한
              서비스 내의 기능에 따라 제공되는 정보에 대하여 검색 결과를
              제시합니다.
            </div>
            <div className="terms-content-item-text">
              2. 본 위치기반서비스의 이용요금은 무료입니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제5조(개인위치정보주체의 권리)
            </div>

            <div className="terms-content-item-text">
              1. 개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중
              일부 또는 개인위치정보의 이용ㆍ제공 목적, 제공받는 자의 범위 및
              위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
            </div>
            <div className="terms-content-item-text">
              2. 개인위치정보주체는 개인위치정보의 수집ㆍ이용ㆍ제공에 대한
              동의의 전부 또는 일부를 철회할 수 있습니다.
            </div>
            <div className="terms-content-item-text">
              3. 개인위치정보주체는 언제든지 개인위치정보의 수집ㆍ이용ㆍ제공의
              일시적인 중지를 요구할 수 있습니다. 이 경우 회사는 요구를 거절하지
              아니하며, 이를 위한 기술적 수단을 갖추고 있습니다
            </div>
            <div className="terms-content-item-text">
              4. 개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를
              요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할
              수 있습니다. 이 경우 회사는 정당한 이유 없이 요구를 거절하지
              아니합니다. <br />
              ① 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료
              <br />② 개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용
              등에 관한 법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된
              이유 및 내용
            </div>
            <div className="terms-content-item-text">
              5. 회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한
              경우에는 지체 없이 수집된 개인위치정보 및 위치정보
              수집ㆍ이용ㆍ제공사실 확인자료를 파기합니다. 단, 동의의 일부를
              철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보
              수집ㆍ이용ㆍ제공사실 확인자료에 한합니다.
            </div>
            <div className="terms-content-item-text">
              6. 개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 이
              약관에 명시한 사업자 정보 및 위치정보 관리책임자 연락처를 이용하여
              회사에 요구할 수 있습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제6조(법정대리인의 권리)
            </div>

            <div className="terms-content-item-text">
              1. 회사는 만14세 미만 아동으로부터 개인위치정보를 수집ㆍ이용 또는
              제공하고자 하는 경우에는 만14세 미만 아동과 그 법정대리인의 동의를
              받아야 합니다.
            </div>
            <div className="terms-content-item-text">
              2. 법정대리인은 만14세 미만 아동의 개인위치정보를
              수집ㆍ이용ㆍ제공에 동의하는 경우 동의유보권, 동의철회권 및
              일시중지권, 열람ㆍ고지요구권을 행사할 수 있습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제7조(서비스의 변경 및 중지)
            </div>

            <div className="terms-content-item-text">
              1. 트랜스파머는 위치기반서비스사업자의 정책변경 등과 같이 회사의
              제반 사정 또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우,
              서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다.
            </div>
            <div className="terms-content-item-text">
              2. 제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에
              공지하거나 개인위치정보주체에게 통지합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제8조(개인위치정보의 이용·제공사실 확인자료의 보유기간)
            </div>

            <div className="terms-content-item-text">
              트랜스파머는 본 약관의 목적 외 다른 용도로 개인위치정보의 이용
              또는 제공사실 확인자료를 기록하거나 보존하지 아니하며, 위치정보의
              보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여
              개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를
              위치정보시스템에 자동으로 기록하며, 6개월 이상 보관합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제9조(개인위치정보의 보유목적 및 이용기간)
            </div>

            <div className="terms-content-item-text">
              1. 트랜스파머는 위치기반서비스를 제공하기 위해 필요한 최소한의
              기간 동안 개인위치정보를 보유 및 이용합니다.
            </div>
            <div className="terms-content-item-text">
              2. 트랜스파머는 대부분의 위치기반서비스에서 개인위치정보를 일회성
              또는 임시로 이용 후 지체없이 파기합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제10조(개인위치정보의 제3자 제공 및 통지)
            </div>

            <div className="terms-content-item-text">
              1. 트랜스파머는 이용자의 동의 없이 개인위치정보를 제3자에게
              제공하지 않으며, 제3자에게 제공하는 경우에는 제공받는 자 및
              제공목적을 사전에 이용자에게 고지하고 동의를 받습니다.
            </div>
            <div className="terms-content-item-text">
              2. 이용자의 동의를 거쳐 개인위치정보를 제3자에게 제공하는 경우,
              매회 이용자에게 제공받는 자, 제공일시 및 제공목적을 통지합니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제11조(8세 이하의 아동 등의 보호의무자 권리)
            </div>

            <div className="terms-content-item-text">
              1. 트랜스파머는 아래의 경우에 해당하는 자(이하 “8세 이하의
              아동”등이라 함)의 보호의무자가 8세 이하의 아동 등의 생명 또는
              신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는
              경우에는 본인의 동의가 있는 것으로 봅니다.
            </div>
            <div className="terms-content-item-text">① 8세 이하의 아동</div>
            <div className="terms-content-item-text">② 피성년후견인</div>
            <div className="terms-content-item-text">
              ③ 장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진
              자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한
              중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 의하여
              장애인등록을 한 자에 한정)
            </div>
            <div className="terms-content-item-text">
              2. 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여
              개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는
              서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에
              제출하여야 합니다.
            </div>
            <div className="terms-content-item-text">
              3. 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
              동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">제12조(손해배상)</div>
            <div className="terms-content-item-text">
              개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률
              제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에
              대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는
              과실이 없음을 입증하지 아니하면 책임을 면할 수 없습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">제13조(분쟁의 조정)</div>
            <div className="terms-content-item-text">
              1. 트랜스파머는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와
              협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
              방송통신위원회에 재정을 신청할 수 있습니다.
            </div>
            <div className="terms-content-item-text">
              2. 트랜스파머는 또는 개인위치정보주체는 위치정보와 관련된 분쟁에
              대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는
              경우에는 개인정보보호법에 따라 개인정보분쟁조정위원회에 조정을
              신청할 수 있습니다.
            </div>
          </div>
          <div className="terms-content-item">
            <div className="terms-content-item-title">
              제14조(사업자 정보 및 위치정보 관리책임자)
            </div>
            <div className="terms-content-item-text">
              1. 트랜스파머의 상호, 주소 및 연락처는 다음과 같습니다. <br />{' '}
              상호: 트랜스파머(주) <br /> 주소: 서울특별시 서초구 매헌로 24
              NH농협 디지털혁신캠퍼스 5층 트랜스파머㈜ <br /> 전화번호:
              (02)555-2822
            </div>
            <div className="terms-content-item-text">
              2. 트랜스파머는 다음과 같이 위치정보 관리책임자를 지정하여
              이용자들이 서비스 이용과정에서 발생한 민원사항 처리를 비롯하여
              개인위치정보주체의 권리 보호를 위해 힘쓰고 있습니다.
            </div>
            <div className="terms-content-item-text">
              위치정보 관리책임자 : 박성필 CIO (개인정보 보호책임자 겸직)
            </div>{' '}
            <div className="terms-content-item-text">
              메일 : sp.park@transfarmer.co.kr
            </div>
          </div>
          <div className="terms-content-item-title">- 부칙 -</div>
          <div className="terms-content-item-title">제 1조(적용일자)</div>
          <div className="font-medium f-size-16 line-h-20">
            이 약관은 2023년 11월 1일부터 적용됩니다.
          </div>
        </div>
        {/* <div
          className={` padding-t-50 flex-center  ${
            SNS
              ? `sticky  bg-FFFFFF w-90per padding-b-20`
              : `padding-b-50 w-33per `
          }`}
          style={{ bottom: '-1px' }}
        >
          <Button
            on={true}
            description='확인'
            onClick={() => {
              SNS ? setTermsOpen(false) : navigate(-1);
            }}
          ></Button>
        </div> */}
      </div>
    </div>
  );
};
export default LocationTerms;
