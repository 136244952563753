import './SearchDropDown.css';
import { getItemType, getItemImg } from '../util/ItemType';
import { useNavigate } from 'react-router-dom';
import SearchService from '../../../service/Search';
import React, { useEffect } from 'react';
import { useMapContext } from '../../../contexts/MapContext';
interface SearchDropDownProps {
  data: SearchKeywordItem[];
}

interface SearchKeywordItem {
  id?: number;
  type?: string;
  address: string;
  soldType?: string;
  roadAddr?: string;
}

const type = {
  s: '매물',
  l: '지역',
  t: '교통',
  b: '건물',
};

const SearchDropDown = ({ data, setSearchKeywordList, search }: any) => {
  const nav = useNavigate();
  const { setLatLng, setPnu, setZoomLevel } = useMapContext();

  const getCoord = async (pnu: string, c_idx: number) => {
    let res = await SearchService.coord(pnu);
    if (res.length > 0) {
      setLatLng({ lat: res[0].lat, lng: res[0].lng });
      setPnu(pnu);
      setZoomLevel(16);
    }
    console.log('여기서 이동', pnu, c_idx);
    if (c_idx && c_idx > 0) {
      nav(`/detail?c_idx=${c_idx}&lat=${res[0].lat}&lng=${res[0].lng}`);
    } else {
      nav('/?lat=' + res[0].lat + '&lng=' + res[0].lng);
    }
  };

  const onClickAddr = (
    address: string,
    pnu: string,
    type?: string,
    soldtype?: string,
    roadaddr?: string,
    buildingname?: string,
    jibunaddr?: string,
    c_idx?: number,
  ) => {
    SearchService.searchLog(
      address,
      pnu,
      undefined,
      type,
      soldtype,
      roadaddr,
      buildingname,
      jibunaddr,
    );
    getCoord(pnu, c_idx ?? 0);
  };

  const getList = async (
    type: 's' | 'l' | 't' | 'b',
    limit: number,
    offset: number,
    setOffset: Function,
  ) => {
    try {
      const res = await SearchService.search(search, type, limit, offset);
      let typeObj = {
        s: 'sale',
        l: 'location',
        t: 'transportation',
        b: 'construction',
      };
      setSearchKeywordList((prev: any) => {
        return {
          ...prev,
          [typeObj[type]]: {
            total: res.total,
            list: [...prev[typeObj[type]].list, ...res.list],
          },
        };
      });
      setOffset(offset + limit);
      // setSearchKeywordList
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <SearchNav data={data} />
      <div className="search_list_wrap">
        <SearchResult
          onClick={onClickAddr}
          icon="sale24"
          title="s"
          count={data.sale.total}
          list={data.sale.list}
          setList={getList}
        />
        <SearchResult
          onClick={onClickAddr}
          icon="marker24"
          title="l"
          count={data.location.total}
          list={data.location.list}
          setList={getList}
        />
        <SearchResult
          onClick={onClickAddr}
          icon="transportation24"
          title="t"
          count={data.transportation.total}
          list={data.transportation.list}
          setList={getList}
        />
        <SearchResult
          onClick={onClickAddr}
          icon="construction24"
          title="b"
          count={data.construction.total}
          list={data.construction.list}
          setList={getList}
        />
      </div>
    </div>
  );
};
export default SearchDropDown;

const SearchResult = (data: ISearchResult) => {
  const limit = 5;
  const [offset, setOffset] = React.useState(3);
  const nav = useNavigate();

  useEffect(() => {
    if (data.list.length <= 3) {
      setOffset(3);
    }
  }, [data.list]);

  if (data.list.length === 0 && data.title === 's') {
    return (
      <div className="neighborhood-no-listing">
        <div className="no-listing-message">
          이 동네에는 아직 매물이 없습니다.
        </div>
        <div className="listing-inquiry">
          <div className="inquiry-message">
            이 동네의 매물을 <br /> 찾고 계신가요?
          </div>
          <div className="inquiry-button-container">
            <div className="inquiry-button">매물 문의하기</div>
          </div>
        </div>
      </div>
    );
  } else if (data.list.length === 0) {
    return <></>;
  }

  return (
    <div id={data.title} className="search_list_container">
      <div className="search_list_header">
        <img src={`/asset/images/icon/search/${data.icon}.svg`} alt="" />
        <div className="search_list_header-title">{type[data.title]}</div>
        <div className="search_list_header-count">
          {Number(data.count) === 30 ? data.count + '+' : data.count}
        </div>
      </div>
      <div style={{ paddingInline: '16px' }}>
        {data.list.map((item, index) => {
          return (
            <SearchResultItem
              item={item}
              index={index}
              onClick={data.onClick}
            />
          );
        })}
      </div>
      {data.count > data.list.length ? (
        <div
          className="search_list_footer"
          onClick={() => data.setList(data.title, limit, offset, setOffset)}
        >
          <div className="search_list_footer-more">더보기</div>
        </div>
      ) : (
        <div style={{ paddingBottom: '16px' }} />
      )}
    </div>
  );
};

const SearchResultItem = ({
  item,
  index,
  onClick,
}: {
  item: any;
  index: any;
  onClick: any;
}) => {
  return (
    <div
      className="search_list_main"
      key={item.id || index}
      onClick={() =>
        onClick(
          item.address,
          (item as any).code,
          item.type,
          item.soldType,
          item.roadAddr,
          (item as { buildingname?: string }).buildingname,
          item.address,
          item.c_idx,
        )
      }
    >
      <div className="search_list_main-info">
        <div className="search_list_main-info-header">
          <img src={getItemImg(item.type)} alt="" />
          <div className="search_list_main-info-location">
            {(item as { buildingname?: string }).buildingname
              ? (item as { buildingname?: string }).buildingname
              : getShortAddress(item.address)}
          </div>
        </div>
        {item.soldType || item.line_name ? (
          <div
            className="search_list_main-info-status"
            style={{
              borderColor: subWayLabelColor[item.line_name as '신분당선']
                ? subWayLabelColor[item.line_name as '신분당선'].color
                : '',
            }}
          >
            <div
              className="search_list_status-title"
              style={{
                color: subWayLabelColor[item.line_name as '신분당선']
                  ? subWayLabelColor[item.line_name as '신분당선'].color
                  : '',
              }}
            >
              {item.soldType
                ? getItemType(item.soldType)
                : subWayLabelColor[item.line_name as '신분당선']
                  ? subWayLabelColor[item.line_name as '신분당선'].title
                  : item.line_name}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {item.roadAddr || item.buildingname ? (
        <div className="search_list_main-location-address">
          {(item as { buildingname?: string }).buildingname
            ? getShortAddress(item.address)
            : getShortAddress(item.roadAddr)}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const SearchNav = ({ data }: any) => {
  const underbarRef = React.useRef<HTMLDivElement>(null);
  return (
    <nav className="search_list_navbar">
      <div className="search_list_navbar-container">
        {data.sale.total > 0 && <SearchNavItem title="s" bar={underbarRef} />}
        {data.location.total > 0 && (
          <SearchNavItem title="l" bar={underbarRef} />
        )}
        {data.transportation.total > 0 && (
          <SearchNavItem title="t" bar={underbarRef} />
        )}
        {data.construction.total > 0 && (
          <SearchNavItem title="b" bar={underbarRef} />
        )}
      </div>
      <div className="search_list_navbar_underbar" ref={underbarRef} />
      <div className="search_list_navbar_underbar_gray" />
    </nav>
  );
};

const SearchNavItem = (data: {
  title: 's' | 'l' | 't' | 'b';
  bar: React.RefObject<HTMLDivElement>;
}) => {
  const navRef = React.useRef<HTMLDivElement>(null);

  const move = () => {
    if (data.bar.current) {
      data.bar.current.style.left = `${navRef.current?.offsetLeft}px`;
    }

    window.location.href = '#' + data.title;
  };

  const handleAnchorScroll = () => {
    const navbarHeight = 118; // 네비게이션 바 높이
    const hash = window.location.hash; // 현재 앵커 위치 (예: #section)

    if (hash) {
      const targetElement = document.querySelector(hash);

      if (targetElement) {
        const elementPosition =
          targetElement.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({ top: elementPosition - navbarHeight });
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('hashchange', handleAnchorScroll);
    return () => {
      window.removeEventListener('hashchange', handleAnchorScroll);
    };
  }, []);

  return (
    <div className="search_list_navbar-item" ref={navRef} onClick={move}>
      <div className="search_list_navbar-title">{type[data.title]}</div>
    </div>
  );
};

const subWayLabelColor = {
  '1호선': { color: '#263B96', title: '1' },
  '2호선': { color: '#3CB449', title: '2' },
  '3호선': { color: '#F06E00', title: '3' },
  '4호선': { color: '#2C9EDD', title: '4' },
  '5호선': { color: '#8936E0', title: '5' },
  '6호선': { color: '#B5500B', title: '6' },
  '7호선': { color: '#697214', title: '7' },
  '8호선': { color: '#E41D6E', title: '8' },
  경강선: { color: '#2672F2', title: '경강' },
  경부선: { color: '#263B96', title: '1' }, // 1호선
  경원선: { color: '#263B96', title: '1' }, // 1호선
  경의중앙선: { color: '#7BC4A5', title: '경의중앙' },
  경인선: { color: '#263B96', title: '1' }, // 1호선
  경춘선: { color: '#09AE7B', title: '경춘' },
  '광주도시철도 1호선': { color: '#3CB449', title: '1' },
  김포골드라인: { color: '#96700C', title: '김포골드' },
  '대구 도시철도 1호선': { color: '#F0602F', title: '1' },
  '대구 도시철도 2호선': { color: '#3CB449', title: '2' },
  '대구 도시철도 3호선': { color: '#FDBF56', title: '3' },
  '대전 도시철도 1호선': { color: '#3CB449', title: '1' },
  '도시철도 7호선': { color: '#697214', title: '7' }, //7호선
  동해선: { color: '#80A8D8', title: '동해' },
  '부산 경량도시철도 4호선': { color: '#426FB5', title: '4' },
  '부산 도시철도 1호선': { color: '#F0602F', title: '1' },
  '부산 도시철도 2호선': { color: '#3CB449', title: '2' },
  '부산 도시철도 3호선': { color: '#D4A556', title: '3' },
  부산김해경전철: { color: '#80499C', title: '부산김해' },
  분당선: { color: '#EBA900', title: '수인분당' },
  '서울 도시철도 9호선': { color: '#D0A62B', title: '9' },
  서해선: { color: '#8AC43F', title: '서해' },
  '수도권  도시철도 9호선': { color: '#D0A62B', title: '9' },
  '수도권 경량도시철도 신림선': { color: '#4E67A4', title: '신림' },
  수인선: { color: '#EBA900', title: '수인분당' },
  신분당선: { color: '#A61E31', title: '신분당' },
  안산과천선: { color: '#2C9EDD', title: '4' },
  에버라인: { color: '#77C371', title: '에버라인' },
  우이신설선: { color: '#C6C100', title: '우이신설' },
  의정부: { color: '#FE9D26', title: '의정부' },
  인천국제공항선: { color: '#73B6E4', title: '공항' },
  '인천지하철 1호선': { color: '#6F99CF', title: '인천1' },
  '인천지하철 2호선': { color: '#F4AB3E', title: '인천2' },
  일산선: { color: '#F06E00', title: '3' },
  자기부상철도: { color: '#73B6E4', title: '공항' },
  진접선: { color: '#2C9EDD', title: '4' },
};

//시도 주소를 줄여서 출력하는 함수 정규식으로 ex > 서울특별시 => 서울, 강원특별자치도 => 강원 경상북도 => 경북
// 일단 한국의 시도 전부

export const getShortAddress = (address: string) => {
  const sidoArr = [
    ['서울특별시', '서울'],
    ['부산광역시', '부산'],
    ['대구광역시', '대구'],
    ['인천광역시', '인천'],
    ['광주광역시', '광주'],
    ['대전광역시', '대전'],
    ['울산광역시', '울산'],
    ['세종특별자치시', '세종'],
    ['경기도', '경기'],
    ['강원', '강원'],
    ['충청북도', '충북'],
    ['충청남도', '충남'],
    ['전북특별자치도', '전북'],
    ['전라남도', '전남'],
    ['경상북도', '경북'],
    ['경상남도', '경남'],
    ['제주특별자치도', '제주'],
  ];
  // sidoArr을 이용하여 시도를 줄이기
  for (let i = 0; i < sidoArr.length; i++) {
    address = address.replace(sidoArr[i][0], sidoArr[i][1]);
  }
  return address;
};

interface ISearchResult {
  icon: string;
  title: 's' | 'l' | 't' | 'b';
  count: number;
  list: Array<any>;
  onClick: Function;
  setList: Function;
}
