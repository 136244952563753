import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLogin } from '../../../utilities/auth';

interface OuterContainerProps {
  height: number;
}

const MoreContent = () => {
  const [spreadPartner, setSpreadPartner] = useState(true);
  const [spreadNotice, setSpreadNotice] = useState(true);
  const [spreadCompanyInfo, setSpreadCompanyInfo] = useState(true);
  const [spreadPolicy, setSpreadPolicy] = useState(true);
  const [OuterContainerHeight, setOuterContainerHeight] = useState(584);
  const outerContainerRef = useRef<HTMLDivElement | null>(null); // ref 생성

  const navigate = useNavigate();

  //중개파트너 밑의 이미지 클릭 시 setSpreadParter 상태 변경
  const handleSpreadPartner = (type: string) => {
    switch (type) {
      case 'partner':
        setSpreadPartner(!spreadPartner);
        break;
      case 'notice':
        setSpreadNotice(!spreadNotice);
        break;
      case 'company':
        setSpreadCompanyInfo(!spreadCompanyInfo);
        break;
      case 'policy':
        setSpreadPolicy(!spreadPolicy);
        break;
    }
  };

  // spreadPartner, spreadNotice ,spreadCompanyInfo ,spreadPolicy 상태가 변경될 때마다 OuterContainer의 높이를 변경
  useEffect(() => {
    const height =
      584 +
      (spreadPartner ? 96 : 0) +
      (spreadNotice ? 192 : 0) +
      (spreadCompanyInfo ? 96 : 0) +
      (spreadPolicy ? 144 : 0);
    setOuterContainerHeight(height);
  }, [spreadPartner, spreadNotice, spreadCompanyInfo, spreadPolicy]);

  return (
    <OuterContainer height={OuterContainerHeight} ref={outerContainerRef}>
      <MoreContentContainer>
        <MoreContentBox>
          <ContentTitle>
            <div>중개파트너</div>
            <img
              onClick={() => handleSpreadPartner('partner')}
              src={
                spreadPartner
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadPartner ? null : (
            <ContentBox>
              <ContentBoxContent
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLScpOsy0bsOTdJPXyH6OYs2BsMFqVqFnTntKl478lXA4xgC2Nw/viewform',
                    '_blank',
                  );
                }}
              >
                지역 파트너 중개사 신청
              </ContentBoxContent>
              {/* <ContentBox_Content>매물관리</ContentBox_Content> */}
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>
            <div>문의 및 공지</div>
            <img
              onClick={() => handleSpreadPartner('notice')}
              src={
                spreadNotice
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadNotice ? null : (
            <ContentBox>
              {/* <ContentBox_Content>고객센터</ContentBox_Content> */}
              <ContentBoxContent
                onClick={() => {
                  navigate('/inquiry');
                }}
              >
                1:1 문의
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  window.open(
                    'https://transfarmer.notion.site/20eba2a06fd246d1a1fe45b2e9d9d835',
                    '_blank',
                  );
                }}
              >
                자주 묻는 질문
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/notice');
                }}
              >
                공지사항
              </ContentBoxContent>
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>
            <div>회사 정보</div>
            <img
              onClick={() => handleSpreadPartner('company')}
              src={
                spreadCompanyInfo
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadCompanyInfo ? null : (
            <ContentBox>
              <ContentBoxContent
                onClick={() => {
                  navigate('/companyinfo');
                }}
              >
                회사소개
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/newsRoom');
                }}
              >
                뉴스룸
              </ContentBoxContent>
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>
            <div>이용정책</div>
            <img
              onClick={() => handleSpreadPartner('policy')}
              src={
                spreadPolicy
                  ? './asset/images/arrow/arrow-up.svg'
                  : './asset/images/arrow/arrow-down.svg'
              }
              alt=""
            />
          </ContentTitle>
          {!spreadPolicy ? null : (
            <ContentBox>
              <ContentBoxContent
                onClick={() => {
                  navigate('/terms/service');
                }}
              >
                이용약관
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/terms/privacy');
                }}
              >
                개인정보처리방침
              </ContentBoxContent>
              <ContentBoxContent
                onClick={() => {
                  navigate('/terms/location');
                }}
              >
                위치기반서비스 이용약관
              </ContentBoxContent>
            </ContentBox>
          )}
        </MoreContentBox>
        <MoreContentBox>
          <ContentTitle>채널</ContentTitle>
          <ChannelBox>
            <ChannelBoxContent>
              <img
                src="./facebook_logo_w_1.svg"
                alt=""
                onClick={() => {
                  window.open(
                    'https://ko-kr.facebook.com/people/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8/61554542764224/',
                    '_blank',
                  );
                }}
              />
              <span
                onClick={() => {
                  window.open(
                    'https://ko-kr.facebook.com/people/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8/61554542764224/',
                    '_blank',
                  );
                }}
              >
                페이스북
              </span>
            </ChannelBoxContent>
            <ChannelBoxContent>
              <img
                src="./naver_blog 1.svg"
                alt=""
                onClick={() => {
                  window.open(
                    'https://blog.naver.com/transfarmer2023',
                    '_blank',
                  );
                }}
              />
              <span
                onClick={() => {
                  window.open(
                    'https://blog.naver.com/transfarmer2023',
                    '_blank',
                  );
                }}
              >
                블로그
              </span>
            </ChannelBoxContent>
          </ChannelBox>
        </MoreContentBox>
        <MoreContentBox>
          <BoxLine />
        </MoreContentBox>
        <LoginBox>
          {isLogin() ? (
            <div>
              <span
                onClick={() => {
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('name');
                  localStorage.removeItem('point');
                  localStorage.removeItem('profileImg');
                  localStorage.removeItem('email');
                  localStorage.removeItem('phone');
                  localStorage.removeItem('category');
                  localStorage.removeItem('interest_area');
                  localStorage.removeItem('birth');
                  localStorage.removeItem('farm_id');
                  localStorage.removeItem('loginType');
                  alert('로그아웃 되었습니다.');
                  navigate(0);
                }}
              >
                로그아웃
              </span>
            </div>
          ) : (
            <div>
              <span
                onClick={() => {
                  navigate('/login');
                }}
              >
                로그인
              </span>
            </div>
          )}
        </LoginBox>
      </MoreContentContainer>
    </OuterContainer>
  );
};
export default MoreContent;

const OuterContainer = styled.div<OuterContainerProps>`
  width: 100%;
  height: ${(props) => props.height}px;
  min-height: 584px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const MoreContentContainer = styled.div`
  padding: 16px 16px 72px 16px;
  width: 100%;
  height: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
`;

const MoreContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.div`
  color: rgba(55, 56, 60, 0.28);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding-top: 8px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentBoxContent = styled.div`
  padding: 12px 0;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  cursor: pointer;
`;

const ChannelBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ChannelBoxContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  padding: 12px 0;
  gap: 6px;
  img {
    cursor: pointer;
  }
  span {
    cursor: pointer;
  }
`;

const BoxLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(112, 115, 124, 0.16);
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;
  color: #616161;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  text-decoration: underline;
  line-height: 24px;
  word-wrap: break-word;
  position: relative;
  span {
    cursor: pointer;
  }
`;
