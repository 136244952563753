import { Link, useNavigate } from 'react-router-dom';
import './Login.styles';
import { S } from './Login.styles';
import { useEffect, useState } from 'react';
import { PageTopClose } from '../components/pageTop/PageTop';
import { RedirectHandler } from '../../controller/Redirect';
import AuthService from '../../service/auth';
import cookie from 'react-cookies';
import UserService from '../../service/Users';

const Login = () => {
  const navigate = useNavigate();
  const [id, setId] = useState(cookie.load('rememberId') || '');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [rememberChecked, setRememberChecked] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const validateEmail = (email: string) => {
    if (email === 'ian') return true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const email = localStorage.getItem('email');

  const getUserInfo = async () => {
    try {
      const res = await UserService.info();
      localStorage.setItem('name', res.result.name);
      localStorage.setItem('point', res.result.point);
      localStorage.setItem('profileImg', res.result.profileImage);
      localStorage.setItem('email', res.result.email);
      localStorage.setItem('phone', res.result.phone);
      localStorage.setItem('category', res.result.category);
      localStorage.setItem('interest_area', res.result.interest_area);
      localStorage.setItem('birth', res.result.birth);
      localStorage.setItem('farm_id', res.result.farm_id);
      localStorage.setItem('loginType', res.result.loginType);
    } catch (error) {
      console.error(error);
    }
  };

  const LoginAPI = async () => {
    const data = {
      userId: id,
      password: password,
    };

    try {
      const res = await AuthService.login(data);

      if (rememberChecked) {
        cookie.save('rememberId', id, { path: '/' });
      } else {
        cookie.remove('rememberId');
      }
      localStorage.setItem('accessToken', res.result.accessToken);
      getUserInfo();
      if (email !== '') {
        navigate('/');
      }
      navigate(-1);
    } catch (e) {
      console.log(e);
      setLoginError(true);
    }
  };

  useEffect(() => {
    // console.log(cookie.load('rememberId'));
    if (cookie.load('rememberId')) {
      setId(cookie.load('rememberId'));
      setRememberChecked(true);
    }
  }, []);

  return (
    <S.Container>
      <PageTopClose />
      <S.ContentBox>
        <S.Content>
          <S.Title>이메일로 로그인</S.Title>
          <S.InputContainer>
            <S.InputBox>
              <span>이메일</span>
              <S.Input
                type="text"
                placeholder="이메일 주소를 입력해주세요"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                  setIsEmailValid(validateEmail(e.target.value));
                }}
                isValid={isEmailValid}
              />
              {!isEmailValid && (
                <S.ErrorText>올바른 이메일 주소를 입력해 주세요.</S.ErrorText>
              )}
            </S.InputBox>
            <S.InputBox>
              <span>비밀번호</span>
              <S.Input
                type="password"
                placeholder="비밀번호를 입력해주세요"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                isValid={!loginError}
              />
              {loginError && (
                <S.ErrorText style={{ padding: '0 14px' }}>
                  트랜스파머에 등록되지 않은 아이디거나, 아이디 또는 비밀번호가
                  회원정보와 일치하지 않습니다.
                </S.ErrorText>
              )}
            </S.InputBox>
            <S.Button
              onClick={() => {
                LoginAPI();
              }}
            >
              로그인
            </S.Button>
            <S.ControlId>
              <S.SaveId
                style={{ flex: 1 }}
                onClick={() => {
                  setRememberChecked(!rememberChecked);
                }}
              >
                <img
                  src={`/asset/images/icon/${rememberChecked ? 'checked-black' : 'unChecked'}.svg`}
                  alt=""
                />
                <span>아이디 저장</span>
              </S.SaveId>
              <S.SaveId
                style={{ gap: 0 }}
                onClick={() => {
                  navigate('/find?tab=id&type=D');
                }}
              >
                <span>아이디/비밀번호 찾기</span>
                <img
                  src="/asset/images/arrow/arrow-right-gray-findId.svg"
                  alt=""
                />
              </S.SaveId>
            </S.ControlId>
          </S.InputContainer>
          <S.SocialLogin>
            <S.LineBox>
              <S.Line></S.Line>
              <span>SNS로 간편하게 시작하기</span>
              <S.Line></S.Line>
            </S.LineBox>
            <S.LogoBox>
              <S.Img
                src="/asset/images/logo/kakao.svg"
                alt=""
                onClick={() => {
                  RedirectHandler('Kakao');
                }}
              />
              <S.Img
                src="/asset/images/logo/naver.svg"
                alt=""
                onClick={() => {
                  RedirectHandler('Naver');
                }}
              />
              <S.Img
                src="/asset/images/logo/facebook.svg"
                alt=""
                onClick={() => {
                  RedirectHandler('Facebook');
                }}
              />
              <S.Img
                src="/asset/images/logo/google.svg"
                alt=""
                onClick={() => {
                  RedirectHandler('Google');
                }}
              />
            </S.LogoBox>
          </S.SocialLogin>
        </S.Content>
        <S.SignUpText>
          <span>아직 트랜스파머 회원이 아니신가요?</span>
          <Link
            to="/SignUp"
            style={{ fontFamily: 'Pretendard-SemiBold', color: '#616161' }}
          >
            회원가입 하기
          </Link>
        </S.SignUpText>
      </S.ContentBox>
    </S.Container>
  );
};

export default Login;
