import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BoardService from '../../service/board';
import styled from 'styled-components';
import colors from '../../color';

interface NoticeDetailProps {
  title: string;
  createTime: string;
  content: string;
}

const NoticeDetail = () => {
  const { articleIdx } = useParams<{ articleIdx: string }>();
  const [detail, setDetail] = useState<NoticeDetailProps | undefined>(
    undefined,
  );

  const navigate = useNavigate();

  const GetNoticeDetail = async (id: number) => {
    try {
      const res = await BoardService.detail(id);
      setDetail(res.result);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (articleIdx) {
      GetNoticeDetail(Number(articleIdx));
    }
  }, [articleIdx]);

  const removeTags = (html: string) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };
  return (
    <Container>
      <PageTopContainer>
        <img
          src="/asset/images/close/close-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
      </PageTopContainer>
      <NoticeInner>
        <NoticeContentBox>
          <TitleBox>
            <div>{detail?.title}</div>
            <DateText>{detail?.createTime}</DateText>
          </TitleBox>
          <Content> {detail && removeTags(detail.content)}</Content>
          <ButtonBox>
            <Button
              onClick={() => {
                navigate('/notice');
              }}
            >
              목록으로
            </Button>
          </ButtonBox>
        </NoticeContentBox>
      </NoticeInner>
    </Container>
  );
};

export default NoticeDetail;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
const PageTopContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
`;

const NoticeInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  padding: 8px 16px 44px;
`;

const NoticeContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleBox = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #e8e9ea;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #171e26;
`;
const DateText = styled.div`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(23, 30, 38, 0.56);
`;
const Content = styled.div`
  width: 100%;
  padding: 32px 16px 16px;
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  width: 328px;
  height: 54px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  cursor: pointer;
  font-family: Pretendard-Bold;
  font-size: 16px;
`;
