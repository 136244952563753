import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import colors from '../../color';
import Summary from './components/summary/Summary';
import TAnalyze from './components/tAnalyze/TAnalyze';
import DetailMap from './components/detailMap/DetailMap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DetailInfo from './components/info/DetailInfo';
import PensionPreview from './components/pension/preview/Pension';
import SalesPriceComparison from './components/salesPriceComparison/SalesPriceComparison';
import HousePreview from './components/house/preview/Pension';
import PriceAnalysis from './components/analysisInfo/PriceAnalysis';
import LandIncome from './components/landIncome/preview/LandIncome';
import DataSource from './components/landIncome/result/components/DataSource';
import Sales from '../../service/Sales';
import {
  AnalysisProps,
  MapData,
  OriginProps,
  SummaryProps,
  LegalDongNameProps,
  CropListProps,
  DetailInfoProps,
} from './data';
import ImageSlider from './components/slider/ImageSlider';
import ItemOption from './components/itemOption/ItemOption';
import Loading from '../components/loading/Loading';
import { isLogin } from '../../utilities/auth';
import Interaction from '../../service/interaction';
import SnackBar from '../components/snackBar/SnackBar';
import { useMapContext } from '../../contexts/MapContext';
import { Helmet } from 'react-helmet';

interface CtaTextProps {
  ctaText: string;
}

const Detail = () => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    leftText: '',
    rightText: '',
  });

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const cIdx = query.get('c_idx');
  const itemType = query.get('type');
  const pnu = query.get('pnu');
  const housekye = query.get('housekye');
  const lat = query.get('lat');
  const lng = query.get('lng');
  const analysisType = query.get('analysisType');
  const { setLatLng, setGeomData } = useMapContext();

  useEffect(() => {
    if (showSnackBar) {
      const timer = setTimeout(() => {
        setShowSnackBar(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSnackBar]);

  // 탭 버튼 클릭 시 이동
  const [selectedState, setSelectedState] = useState('PriceAnalysis');
  const [isFirstLoad, setIsFirstLoad] = useState(true); // 초기 로딩 여부 상태 추가
  const priceAnalysisRef = useRef<HTMLDivElement | null>(null);
  const landIncomeRef = useRef<HTMLDivElement | null>(null);
  const housePreviewRef = useRef<HTMLDivElement | null>(null);
  const pensionPreviewRef = useRef<HTMLDivElement | null>(null);
  const threetabRef = useRef<HTMLDivElement | null>(null);
  const ContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!isFirstLoad) {
      // 처음 로딩이 아닐 때만 스크롤

      if (selectedState === 'PriceAnalysis' && priceAnalysisRef.current) {
        priceAnalysisRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (selectedState === 'LandAnalysis' && landIncomeRef.current) {
        landIncomeRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (selectedState === 'HousePreview' && housePreviewRef.current) {
        housePreviewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (selectedState === 'Pension' && pensionPreviewRef.current) {
        pensionPreviewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [selectedState, isFirstLoad]);

  const handleStateChange = (state: string) => {
    setSelectedState(state);
    setTimeout(() => {
      setIsFirstLoad(false);
    }, 1000);
    // setIsFirstLoad(false); // 상태 변경 시 초기 로딩 상태 업데이트
  };

  //탭 버튼 고정
  const scrollViewRef = useRef<HTMLDivElement | null>(null);
  const [isTabFixed, setIsTabFixed] = useState(false);

  //CTA툴팁
  const [showCtaTooltip, setCtaShowTooltip] = useState(true);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // 타임아웃 관리용 ref

  const handleScroll = () => {
    if (scrollViewRef.current) {
      const scrollY = scrollViewRef.current.scrollTop;

      // 스크롤 위치가 100 이상일 경우 텍스트 숨기기
      if (scrollY > 100) {
        setIsTextVisible(false);
      } else {
        setIsTextVisible(true);
      }
    }

    if (scrollViewRef.current && threetabRef.current) {
      const scrollY = scrollViewRef.current.scrollTop;
      // threetabRef의 상단 위치를 기준으로 스크롤 위치를 비교

      const threetabTop = threetabRef.current.offsetTop;
      setIsTabFixed(scrollY > threetabTop); // 탭이 고정되는 기준 설정
    }
    // 툴팁을 일시적으로 숨김
    setCtaShowTooltip(false);

    // 기존 타임아웃이 있다면 해제
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // 스크롤 멈춘 후 100ms 뒤에 툴팁 다시 표시
    timeoutRef.current = setTimeout(() => {
      setCtaShowTooltip(true);
    }, 500);
  };

  //마이촌에서 가격분석, 토지소득 등의 탭을 누를 경우
  //해당 분석화면으로 포커싱을 하기 위해서 추가 해놓음
  const moveAnalysisTab = (analysisType: string) => {
    if (analysisType === 'land') {
      handleStateChange('LandAnalysis');

      landIncomeRef.current &&
        landIncomeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (analysisType === 'house') {
      handleStateChange('HousePreview');

      housePreviewRef.current &&
        housePreviewRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (analysisType === 'pension') {
      handleStateChange('Pension');

      pensionPreviewRef.current &&
        pensionPreviewRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (analysisType === 'sale') {
      handleStateChange('PriceAnalysis');

      priceAnalysisRef.current &&
        priceAnalysisRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  //데이터 출처
  const [arrowDirection, setArrowDirection] = useState('down');
  const changeArrowDirection = () => {
    setArrowDirection((prevDirection) =>
      prevDirection === 'down' ? 'up' : 'down',
    );
  };

  const nav = useNavigate();
  const [address, setAddress] = useState('');
  const [saleType, setSaleType] = useState('');
  const [mapData, setMapData] = useState<MapData>({
    lat: 0,
    lng: 0,
    geom: [],
    price: 0,
    category: '',
    area: 0,
    pyeong: 0,
    bidCount: null,
  });
  const [type, setType] = useState('');
  const [summary, setSummary] = useState<SummaryProps>();
  const [analysis, setAnalysis] = useState<AnalysisProps[]>([]);
  const [imageData, setImageData] = useState([]);
  const [detailInfo, setDetailInfo] = useState<DetailInfoProps>();
  const [trendData, setTrendData] = useState();
  const [officialPriceData, setOfficialPriceData] = useState();
  const [zModelData, setZModelData] = useState();
  const [pyeongRegionData, setPyeongRegionData] = useState();
  const [origin, setOrigin] = useState<OriginProps[]>([]);
  const [landGradeData, setLandGradeData] = useState();
  const [legalDongNameData, setLegalDongNameData] =
    useState<LegalDongNameProps>();
  const [pensionData, setPensionData] = useState(0);
  const [pyeong, setPyeong] = useState<Number>();
  const [price, setPrice] = useState<Number>();
  const [cropListData, setCropListData] = useState<CropListProps[]>([]);
  const [comparisonOfSelling, setComparisonOfSelling] = useState(0);
  const [loading, setLoading] = useState(false);
  const [intIdx, setIntIdx] = useState(null);
  const [analysisYN, setAnalysisYN] = useState(true);
  const [isTextVisible, setIsTextVisible] = useState(true);

  const getDetail = async (c_idx: string) => {
    setLoading(true);
    try {
      const res = await Sales.detail(c_idx);
      setMapData(res.result.mapData);
      setAddress(res.result.summary.address);
      setSaleType(res.result.summary.saleType);
      setType(res.result.summary.type);
      setSummary(res.result.summary);
      setPyeong(res.result.summary.pyeong);
      setPrice(res.result.summary.price);
      setAnalysis(res.result.analysisT);
      setDetailInfo(res.result.detailInfo);
      setImageData(res.result.images);
      setTrendData(res.result.trend);
      setOfficialPriceData(res.result.officialPriceList);
      setZModelData(res.result.zModelData);
      setPyeongRegionData(res.result.pyeongRegion);
      setLandGradeData(res.result.landGrade);
      setLegalDongNameData(res.result.legalDongName);
      setPensionData(res.result.pension);
      setCropListData(res.result.cropList);
      setComparisonOfSelling(res.result.comparisonOfSellingPrice);
      moveAnalysisTab(analysisType || '');
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const nonSaleInfo = async (type: string, pnu: string, housekey: string) => {
    setLoading(true);
    try {
      const res = await Sales.nonSaleInfo(type, pnu, housekey);
      setMapData(res.result.mapData);
      setAddress(res.result.summary.address);
      setSaleType(res.result.summary.saleType);
      setType(res.result.summary.type);
      setSummary(res.result.summary);
      setPyeong(res.result.summary.pyeong);
      setAnalysis(res.result.analysisT);
      setDetailInfo(res.result.detailInfo);
      setImageData(res.result.images);
      setTrendData(res.result.trend);
      setOfficialPriceData(res.result.officialPriceList);
      setZModelData(res.result.zModelData);
      setPyeongRegionData(res.result.pyeongRegion);
      setLandGradeData(res.result.landGrade);
      setLegalDongNameData(res.result.legalDongName);
      setPensionData(res.result.pension);
      setCropListData(res.result.cropList);
      setComparisonOfSelling(res.result.comparisonOfSellingPrice);
      moveAnalysisTab(analysisType || '');
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const getOrigin = async () => {
    try {
      const res = await Sales.origin();
      setOrigin(res.result);
    } catch (e) {
      console.log(e);
    }
  };
  const updateRecent = async () => {
    try {
      const data = { c_idx: cIdx, pnu, type: itemType };
      const res = await Interaction.recent(data);
    } catch (error) {
      console.error('Error updating recent', error);
    }
  };

  useEffect(() => {
    if (cIdx) {
      getDetail(cIdx);
      getOrigin();
      checkLike(Number(cIdx));
    }
    if (pnu) {
      nonSaleInfo(itemType || '', pnu || '', housekye || '');
      getOrigin();
      checkLike(Number(cIdx), pnu || '', itemType || '');
    }
    updateRecent();
  }, [cIdx, pnu]);

  useEffect(() => {
    setAnalysisYN(
      analysis[0]?.avg !== null &&
        analysis[0]?.high_avg !== null &&
        analysis[0]?.low_avg !== null,
    );
  }, [analysis]);
  // 타입 설정(주택, 농지, 대에 따라 가격분석탭의 항목이 다르게 나타냄)
  const [selectedType, setSelectedType] = useState('');

  //summary의 타입이 h인 경우, 주택
  //detailInfo.category가 '대'인 경우, 대
  //나머지는 농지

  useEffect(() => {
    if (summary?.type === 'H') {
      setSelectedType('주택');
    } else if (
      detailInfo?.land?.category === '임야' ||
      detailInfo?.land?.category === '대'
    ) {
      setSelectedType('주택');
    } else {
      setSelectedType('농지');
    }
  }, [summary, detailInfo]);

  //포인트 사용 모달
  const [usedPoint, setUsedPoint] = useState(false);

  const postLike = async (c_idx: number, pnu?: string, type?: string) => {
    let data;
    if (!cIdx) {
      data = { pnu, type };
    } else {
      data = {
        c_idx,
      };
    }

    try {
      const res = await Interaction.like(data);
      setSnackBarMessage({
        leftText: '찜 완료!',
        rightText: '찜한 주소지 보기',
      });
      setShowSnackBar(true);
      if (cIdx) {
        checkLike(Number(cIdx));
      }
      if (pnu) {
        checkLike(Number(cIdx), pnu || '', itemType || '');
      }
      // getDetail(String(c_idx));
    } catch (error) {
      console.error('Error toggling favorite', error);
    }
  };

  const disLike = async (c_idx: number, int_idx: number) => {
    try {
      const res = await Interaction.disLike(int_idx);
      setIntIdx(null);
      setSnackBarMessage({
        leftText: '찜을 해제했어요.',
        rightText: '다시 찜하기',
      });
      setShowSnackBar(true);
    } catch (e) {
      console.log(e);
    }
  };

  const checkLike = async (c_idx: number, pnu?: string, type?: string) => {
    try {
      if (!c_idx) {
        const res = await Interaction.check(c_idx, pnu, type);
        setIntIdx(res.result);
      } else {
        const res = await Interaction.check(c_idx);
        setIntIdx(res.result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [likeYN, setLikeYN] = useState(false);

  const handleClickLike = (
    int_idx: any,
    c_idx: number,
    pnu: string,
    type: string,
  ) => {
    if (!isLogin()) {
      const userConfirmed = window.confirm('로그인이 필요한 서비스입니다.');

      if (userConfirmed) {
        navigate('/login');
      } else {
        return;
      }
    }
    if (int_idx === null) {
      if (!c_idx) {
        postLike(c_idx, pnu, type);
      } else {
        postLike(c_idx);
      }
    } else {
      disLike(c_idx, int_idx);
    }
  };

  //로그인 유무
  const [isLogin2, setIsLogin2] = useState(false);
  useEffect(() => {
    if (isLogin()) {
      setIsLogin2(true);
    } else {
      setIsLogin2(false);
    }
  }, []);

  //유형에 따라 cta버튼을 변경하고, url을 설정, 파트너 유무에 따라 cta버튼을 변경
  const [isPartner, setIsPartner] = useState(false);
  const [ctaText, setCtaText] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (summary?.saleType === '경매') {
      setCtaText('법원경매 바로가기');
      setUrl(``);
    } else if (summary?.saleType === '공매') {
      setCtaText('법원경매 바로가기');
      setUrl(``);
    } else if (summary?.saleType === '지자체 물건') {
      setCtaText('농지은행 바로가기');
      setUrl(``);
    } else if (summary?.saleType === '매물') {
      setCtaText('문의하기');
      setUrl(``);
    } else {
      if (isPartner) {
        setCtaText('지역 전담 중개사 문의');
        setUrl(``);
      } else {
        setCtaText('맞춤중개 바로가기');
        setUrl(``);
      }
    }
  }, [summary]);

  const handleTextClick = () => {
    setGeomData(mapData.geom);
    setLatLng({ lat: mapData.lat, lng: mapData.lng });
    nav(`/?lat=${mapData.lat}&lng=${mapData.lng}`);
  };

  return (
    <>
      <Helmet>
        <title>
          {legalDongNameData
            ? `${legalDongNameData.gugun} ${legalDongNameData.dong} 이 땅의 가치는? - 매매가 비교, 시세 분석, 소득 및 개발 수익 분석 |
          트랜스파머`
            : '누구나 토지 투자를 똑똑하게 | 트랜스파머'}
        </title>
        <meta
          name="description"
          content="전국 일반 매물, 지자체 매물, 경매, 공매물 검색 - 안심할 수 있는 가격대 매물만 보기 기능을 제공합니다."
        />
        <meta
          name="keywords"
          content="토지 매매, 땅매매, 땅값, 땅 시세, 토지 시세, 농지, 주택매매, 토지실거래가, 토지이음, 토지투자, 땅투자, 경매, 유휴지, 캠핑장 사업, 태양광 발전 임대, 창고임대 사업, 펜션 사업"
        />
        <meta
          property="og:title"
          content="트랜스파머 | 누구나 토지 투자를 똑똑하게"
        />
        <meta
          property="og:description"
          content="전국 토지 매물 시세 분석과 토지에 적합한 투자 상품을 제공합니다"
        />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta property="og:url" content="https://www.transfarmer.co.kr" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container ref={ContainerRef}>
        <SearchReasultBox>
          <img
            onClick={() => {
              if (lat && lng) {
                nav(`/?lat=${lat}&lng=${lng}`);
              } else {
                nav(-1);
              }
            }}
            style={{
              width: '24px',
              height: '24px',
              padding: '5px 9px 5px 7px',
              cursor: 'pointer',
            }}
            src="/asset/images/arrow/arrow-left-black.svg"
            alt=""
          />
          <div>{address}</div>
        </SearchReasultBox>
        <DetailContainer onScroll={handleScroll} ref={scrollViewRef}>
          <MapButtonBox onClick={handleTextClick}>
            <MapButton>
              <img src="/asset/images/icon/map.svg" alt="" />
              {isTextVisible && <span>지도</span>}
            </MapButton>
          </MapButtonBox>
          <div style={{ width: '100%' }}>
            {mapData && <DetailMap data={mapData} saleType={saleType} />}

            {summary && (
              <Summary
                data={summary}
                approvalYear={detailInfo?.house?.approvalYear}
                roadSurface={detailInfo?.land?.roadSurface}
              />
            )}
          </div>

          {(saleType === '매물' ||
            saleType === '인터넷 매물' ||
            saleType === '지자체 물건') && (
            <SalesPriceComparison comparisonOfSelling={comparisonOfSelling} />
          )}

          {analysis &&
            analysis.map((item) => (
              <TAnalyze
                data={item}
                saleType={saleType}
                pyeong={Number(pyeong) || undefined}
                price={Number(price) || undefined}
                dealDate={summary?.dealdate || undefined}
              />
            ))}
          {saleType === '매물' && <ItemOption></ItemOption>}
          {saleType !== '공매' && imageData && imageData.length > 0 && (
            <ImageSlider imageData={imageData} />
          )}
          {detailInfo && <DetailInfo data={detailInfo}></DetailInfo>}
          <div style={{ backgroundColor: '#fff' }}>
            <AnalysisInfoContainer>
              <AnalysisInfoTitle ref={threetabRef}>
                이 땅의 가치를 확인해보세요
              </AnalysisInfoTitle>
              {selectedType === '농지' ? (
                <ThreeTabContainer isTabFixed={isTabFixed}>
                  <TabContent
                    onClick={() => handleStateChange('PriceAnalysis')}
                    active={selectedState === 'PriceAnalysis'}
                  >
                    가격분석
                  </TabContent>
                  <TabContent
                    onClick={() => handleStateChange('LandAnalysis')}
                    active={selectedState === 'LandAnalysis'}
                  >
                    토지 소득 분석
                    <Img
                      src={`/asset/images/icon/${
                        usedPoint
                          ? selectedState === 'LandAnalysis'
                            ? 'lockOff-selected'
                            : 'lockOff-noSelected'
                          : selectedState === 'LandAnalysis'
                            ? 'lockOn-selected'
                            : 'lockOn-noSelected'
                      }.svg`}
                      alt=""
                    />
                  </TabContent>
                  <TabContent
                    onClick={() => handleStateChange('Pension')}
                    active={selectedState === 'Pension'}
                  >
                    농지연금
                    <Img
                      src={`/asset/images/icon/${
                        usedPoint
                          ? selectedState === 'Pension'
                            ? 'lockOff-selected'
                            : 'lockOff-noSelected'
                          : selectedState === 'Pension'
                            ? 'lockOn-selected'
                            : 'lockOn-noSelected'
                      }.svg`}
                      alt=""
                    />
                  </TabContent>
                </ThreeTabContainer>
              ) : (
                <ThreeTabContainer isTabFixed={isTabFixed}>
                  <TabContent
                    onClick={() => handleStateChange('PriceAnalysis')}
                    active={selectedState === 'PriceAnalysis'}
                  >
                    가격분석
                  </TabContent>
                  <TabContent
                    onClick={() => handleStateChange('HousePreview')}
                    active={selectedState === 'HousePreview'}
                  >
                    주택분석
                    <Img
                      src={`/asset/images/icon/${
                        usedPoint
                          ? selectedState === 'HousePreview'
                            ? 'lockOff-selected'
                            : 'lockOff-noSelected'
                          : selectedState === 'HousePreview'
                            ? 'lockOn-selected'
                            : 'lockOn-noSelected'
                      }.svg`}
                      alt=""
                    />
                  </TabContent>
                </ThreeTabContainer>
              )}
            </AnalysisInfoContainer>
            <div ref={priceAnalysisRef} style={{ scrollMargin: '75px' }}>
              {trendData &&
                officialPriceData &&
                zModelData &&
                pyeongRegionData &&
                landGradeData &&
                legalDongNameData && (
                  <PriceAnalysis
                    trend={trendData}
                    officalPrice={officialPriceData}
                    zModel={zModelData}
                    type={type}
                    pyeongRegion={pyeongRegionData}
                    landGrade={landGradeData}
                    legalDongName={legalDongNameData}
                    isLogin2={isLogin2}
                  />
                )}
            </div>
          </div>

          {selectedType === '농지' ? (
            <>
              <div ref={landIncomeRef} style={{ scrollMargin: '90px' }}>
                {cropListData && (
                  <LandIncome
                    isLogin2={isLogin2}
                    cropList={cropListData}
                    c_idx={cIdx ?? ''}
                    usedPoint={usedPoint}
                    pnu={detailInfo?.pnu ?? ''}
                    area={summary?.area ?? 0}
                    avg={
                      Math.floor(
                        ((analysis[0]?.avg ?? 0) * (summary?.area ?? 0)) /
                          3.305,
                      ) ||
                      Math.floor(
                        ((analysis[0]?.price ?? 0) * (summary?.area ?? 0)) /
                          3.305,
                      ) ||
                      0
                    }
                    address={address}
                    itemType={itemType ?? ''}
                    housekye={housekye ?? ''}
                    analysisYN={analysisYN}
                  ></LandIncome>
                )}
              </div>
              <div ref={pensionPreviewRef} style={{ scrollMargin: '0px' }}>
                {pensionData !== null &&
                  pensionData !== undefined &&
                  detailInfo && (
                    <PensionPreview
                      high_avg={
                        Math.floor(
                          ((analysis[0]?.high_avg ?? 0) *
                            (summary?.area ?? 0)) /
                            3.305,
                        ) || 0
                      }
                      low_avg={
                        Math.floor(
                          ((analysis[0]?.low_avg ?? 0) * (summary?.area ?? 0)) /
                            3.305,
                        ) || 0
                      }
                      avg={
                        Math.floor(
                          ((analysis[0]?.avg ?? 0) * (summary?.area ?? 0)) /
                            3.305,
                        ) || 0
                      }
                      c_idx={cIdx ?? ''}
                      pension={pensionData}
                      usedPoint={usedPoint}
                      roadSurface={detailInfo?.land?.roadSurface ?? ''}
                      isLogin2={isLogin2}
                      address={address}
                      itemType={itemType ?? ''}
                      pnu={detailInfo?.pnu ?? ''}
                      housekye={housekye ?? ''}
                      analysisYN={analysisYN}
                    />
                  )}
              </div>
            </>
          ) : (
            <div ref={housePreviewRef} style={{ scrollMargin: '90px' }}>
              <HousePreview
                address={address}
                c_idx={cIdx ?? ''}
                usedPoint={usedPoint}
                itemType={itemType ?? ''}
                pnu={detailInfo?.pnu ?? ''}
                housekye={housekye ?? ''}
                landArea={summary?.area ?? 0}
                analysisYN={analysisYN}
                land_BCR={detailInfo?.land?.BCR ?? 0}
                isLogin2={isLogin2}
              />
            </div>
          )}

          <DataSource
            originData={origin}
            arrowDirection={arrowDirection}
            changeArrowDirection={changeArrowDirection}
          />
        </DetailContainer>
        <DetailFooterBox>
          <FooterContentBox>
            <HeartImg
              onClick={() =>
                handleClickLike(intIdx, Number(cIdx), pnu ?? '', type ?? '')
              }
              src={`/asset/images/icon/heart-${intIdx === null ? 'off' : 'on'}.svg`}
            />
            <FooterContent
              ctaText={ctaText}
              onClick={() => {
                if (summary?.link) {
                  window.open(`${summary?.link}`, '_blank');
                }
                if (ctaText === '맞춤중개 바로가기') {
                  navigate('/brokerage');
                }
                if (ctaText === '문의하기') {
                  localStorage.setItem(
                    'address',
                    summary ? summary.address : '',
                  );
                } else {
                  return null;
                }
              }}
            >
              {ctaText}
            </FooterContent>
          </FooterContentBox>
          {showSnackBar && (
            <SnackBar
              leftText={snackBarMessage.leftText}
              rightText={snackBarMessage.rightText}
              onClick={() => {
                if (snackBarMessage.rightText === '찜한 주소지 보기') {
                  navigate('/mychon');
                } else {
                  postLike(Number(cIdx));
                  setSnackBarMessage({
                    leftText: '찜 완료!',
                    rightText: '찜한 주소지 보기',
                  });
                  setShowSnackBar(true);
                }
              }}
            />
          )}
        </DetailFooterBox>
        {(summary?.saleType === '비매물' ||
          summary?.saleType === '실거래' ||
          summary?.saleType === '인터넷 매물') &&
          showCtaTooltip && (
            <TooltipContainer>
              <TooltipTextBox>
                {summary?.saleType === '인터넷 매물' ? (
                  <p>
                    원하는 매물 <span>대신 찾아드려요!</span>
                  </p>
                ) : (
                  <p>
                    소유주세요? <span>대신 팔아드려요!</span>
                  </p>
                )}
              </TooltipTextBox>
            </TooltipContainer>
          )}
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default Detail;

interface ThreeTabContainerProps {
  isTabFixed?: boolean;
}
interface TabContentProps {
  active?: boolean;
}

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow-x: hidden;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
`;

const SearchReasultBox = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 16px;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Bold;
  position: fixed; // 상단에 고정
  top: 0;
  z-index: 200;
  background-color: ${colors.white};
  max-width: inherit;
`;

const DetailContainer = styled.div`
  max-width: 720px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 74px;
  gap: 8px;
  overflow-y: scroll;
`;

const AnalysisInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff; // 배경색
  transition: all 0.3s ease; // 부드러운 전환 효과
`;

const AnalysisInfoTitle = styled.div`
  padding: 32px 24px 16px 16px;
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const ThreeTabContainer = styled.div<ThreeTabContainerProps>`
  max-width: 720px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 12px;
  border-bottom: 1px solid rgba(112, 115, 124, 0.16);
  position: ${({ isTabFixed }) => (isTabFixed ? 'fixed' : 'relative')};
  top: ${({ isTabFixed }) => (isTabFixed ? '50px' : 'initial')};
  z-index: 200;
  background-color: ${colors.white};
`;

const TabContent = styled.div<TabContentProps>`
  display: flex;
  flex-direction: row;
  padding: 12px;
  gap: 2px;
  font-size: 16px;
  font-family: ${({ active }) =>
    active ? 'Pretendard-Bold' : 'Pretendard-Medium'};
  line-height: 24px;
  white-space: nowrap;
  color: ${({ active }) => (active ? '#222' : '#989ba2')};
  border-bottom: ${({ active }) => (active ? '2px solid #222' : 'transparent')};
`;

const DetailFooterBox = styled.div`
  width: 100%;
  height: 114px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, white 20%);
  padding: 24px 16px;
  padding-top: 40px;
  max-width: inherit;
`;

const FooterContentBox = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26.4px;
  word-wrap: break-word;
  gap: 8px;
`;

const HeartImg = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const FooterContent = styled.div<CtaTextProps>`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #1e9b6b;
  cursor: pointer;

  background-color: ${({ ctaText }) =>
    ctaText === '맞춤중개 바로가기' || ctaText === '지역 전담 중개사 문의'
      ? 'white'
      : '#1e9b6b'};
  color: ${({ ctaText }) =>
    ctaText === '맞춤중개 바로가기' || ctaText === '지역 전담 중개사 문의'
      ? '#1e9b6b'
      : '#f5f5f5'};
  border: ${({ ctaText }) =>
    ctaText === '맞춤중개 바로가기' || ctaText === '지역 전담 중개사 문의'
      ? '1px #1E9B6B solid'
      : null};
`;

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed; /* 화면 전체를 덮기 위해 고정 위치 설정 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 98px;
  background-color: rgba(255, 255, 255, 0.15); /* 흰색 배경의 투명도 15% */
  // background-color: purple;
  display: ${({ isVisible }) =>
    isVisible ? 'block' : 'none'}; /* 가시성 조정 */
  // z-index: 3; /* 다른 요소 위에 표시되도록 설정 */
`;

const MessageBox = styled.div`
  width: calc(100% - 32px); /* 좌우 여백을 고려하여 너비 조정 */
  background-color: rgba(80.73, 84.51, 86.19, 0.92);
  position: absolute; /* 부모 박스에 상대적인 절대 위치 설정 */
  bottom: 16px;
  left: 16px;
  padding: 14px 16px;
  border-radius: 8px;
  z-index: 800; /* 오버레이보다 위에 표시되도록 설정 */

  color: white;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MessageContent = styled.div`
  width: 98px;
  height: 23px;

  color: #0dde60;
  font-size: 15px;
  font-family: Pretendard-Medium;
  font-weight: 600;
  line-height: 22.5px;
  word-wrap: break-word;
  white-space: nowrap;
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
`;

const TooltipContainer = styled.div`
  width: auto;
  position: absolute;
  padding: 8px 16px;
  background-color: #5e6263;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  bottom: 86px;
  left: 50%; /* 화면의 가운데 */
  transform: translateX(-50%); /* 가운데 정렬 */
  z-index: 100;
  box-shadow:
    0px 6px 12px 0px rgba(0, 0, 0, 0.12),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    bottom: -8px; /* Adjust as needed to position the triangle */
    left: 50%; /* Center the triangle */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #5e6263; /* Triangle color matching the background */
  }
  // @media (min-width: 360px) {
  //   right: 95px;
  // }
`;

const TooltipTextBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 13px;
  color: ${colors.white};
  white-space: nowrap;
  line-height: 20px;
  span {
    color: #0dde60;
  }
`;

const MapButtonBox = styled.div`
  width: 100%;
  position: fixed;
  bottom: 106px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
  max-width: inherit;
  cursor: pointer;
`;

const MapButton = styled.div`
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 999px;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #222;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;
