import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // 페이지가 스크롤될 때 isVisible 상태 업데이트
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // 스크롤 이벤트 리스너 추가
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 페이지 상단으로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ToTopButtonBox>
      <ToTopButton
        className={`${isVisible ? 'show' : 'hide'}`}
        onClick={scrollToTop}
      >
        <img
          src="/asset/images/arrow/toTop.png"
          alt=""
          style={{ width: '48px' }}
        />
      </ToTopButton>
    </ToTopButtonBox>
  );
};

export default ScrollToTopButton;

const ToTopButtonBox = styled.div`
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 30px;
  curtain: pointer;
  z-index: 1000;
  max-width: inherit;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
`;
const ToTopButton = styled.div`
  cursor: pointer;
`;
