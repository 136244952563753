import styled from 'styled-components';
import colors from '../../color';

const Container = styled.div`
  max-width: 720px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px 106px 16px;
  gap: 32px;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #171e26;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.lineBorder};
`;

const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InputItem = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px 16px;
  border-radius: 13px;
  background-color: #f7f8fc;
`;
const Label = styled.span`
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.textSecondary};
`;

const Input = styled.input`
  width: 100%;
  height: 26px;
  border: none;
  background-color: transparent;
  font-family: Pretendard-Medium;
  font-size: 17px;
  color: ${colors.labelNormal};

  &::placeholder {
    font-family: Pretendard-Medium;
    font-size: 17px;
    color: ${colors.labelAssistive};
    padding-left: 0;
  }

  &:focus {
    border-color: none;
    outline: none;
    font-family: Pretendard-Medium;
    font-size: 17px;
    color: ${colors.labelNormal};
  }
`;

const PhoneNumberBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SendNumberButton = styled.button`
  width: 100px;
  height: 32px;
  background-color: rgba(112, 115, 124, 0.16);
  color: #222;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Medium;
  font-size: 14px;
  cursor: pointer;
`;

const Select = styled.select`
  width: 100%;
  height: 26px;
  border: none;
  background-color: transparent;
  font-family: Pretendard-Medium;
  font-size: 17px;
  color: ${colors.labelNormal};
  padding-left: 0;

  &:focus {
    border-color: none;
    outline: none;
    font-family: Pretendard-Medium;
    font-size: 17px;
    color: ${colors.labelNormal};
  }
`;

const Option = styled.option`
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
`;
const TermsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const AllAgreeBox = styled.div`
  width: 100%;
  padding: 18px 14px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7f8fc;
  border-radius: 13px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
  cursor: pointer;
`;
const AgreeBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const AgreeItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 12px 14px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
`;
const AgreeText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const ArrwoImg = styled.img`
  cursor: pointer;
`;

const SignUpBtnBox = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: white;
  padding: 16px;
  max-width: inherit;
`;
const SignUpBtn = styled.button`
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
`;
const WarningFont = styled.p`
  font-family: Pretendard-Medium;
  color: red;
  font-size: 14px;
  text-align: start;
`;

export const S = {
  Container,
  ContentBox,
  Title,
  Line,
  InputBox,
  InputItem,
  Label,
  Input,
  SendNumberButton,
  PhoneNumberBox,
  Select,
  Option,
  TermsBox,
  AllAgreeBox,
  AgreeBox,
  AgreeItem,
  AgreeText,
  ArrwoImg,
  SignUpBtnBox,
  SignUpBtn,
  WarningFont,
};
