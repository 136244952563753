import { transNum } from '../../../../function/function';

const renderIcon = (category: string) => {
  switch (category) {
    case '전':
      return 'sprout';
    case '답':
      return 'wheat';
    case '과수원':
      return 'apple';
    case '임야':
      return 'mountain';
    case '목장지':
      return 'cow';
    case '잡종지':
      return 'forest';
    case '주택':
      return 'building';
    case '대':
      return 'land';
    default:
      return null;
  }
};

export const calculateScale = (zoomLevel: number) => {
  if (zoomLevel > 20) return '5m';
  if (zoomLevel > 19) return '10m';
  if (zoomLevel > 18) return '20m';
  if (zoomLevel > 17) return '30m';
  if (zoomLevel > 16) return '50m';
  if (zoomLevel > 15) return '100m';
  if (zoomLevel > 14) return '300m';
  if (zoomLevel > 13) return '500m';
  if (zoomLevel > 12) return '1km';
  if (zoomLevel > 11) return '3km';
  if (zoomLevel > 10) return '5km';
  if (zoomLevel > 9) return '10km';
  if (zoomLevel > 8) return '20km';
  return '30km';
};

interface DataType {
  name?: string; // Optional, as it may not always be present
  count?: number;
}

export const makeUnderlevel16 = (data: DataType) => {
  const isCount = data.count
    ? `<div
      style="width:100%;padding: 2px 10px; background-color: white; color: #171E26; font-family: 'Pretendard-Bold'; font-size: 14px; display: flex; justify-content: center; align-items: center;">
      ${Number(data.count).toLocaleString()}
    </div>`
    : '';

  return `
    <div
      style="width: max-content; border-radius: 8px; border: 1px solid #1E9B6B; overflow: hidden; box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.08); display: ${data.name ? 'block' : 'none'};">
      <div
        style="padding: 5px 10px; background-color: #1E9B6B; color: white; font-family: 'Pretendard-Medium'; font-size: 12px; display: flex; flex-direction: row; justify-content: center; align-items: center; white-space:noWrap; text-align:center">
        ${data.name || ''}
      </div>
      ${isCount}
    </div>
  `;
};

export const makePropertyforPriceMarkers = (data: any) => {
  const is_safe_to_sellIcon =
    data.is_safe_to_sell === 'Y'
      ? `<img src="/asset/images/icon/safe-mark.png" alt="" style="position:absolute; top:-10px; left:-10px; width:22px; height:22px"/>`
      : '';

  return `
      <div style="position: relative; padding: 4px; display: flex; justify-content: flex-start; align-items: center; gap: 2px; background: #ffffff; border-radius: 8px; border: 1px solid #1e9b6b;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.08);
    );
      ">
      ${is_safe_to_sellIcon}       
      <div style="width: 22px; height: 22px; border-radius: 100px; background-color: #EAF8F2; display: flex; justify-content: center; align-items: center;">
        <img src="/asset/images/icon/green-${renderIcon(data.category)}.svg" alt="" style="width: 14px; height: 14px;"/>
      </div>
      <div>
        <div style="font-family: 'Pretendard-Bold'; color: #37383C; font-size: 14px; line-height: 20px; white-space: nowrap; text-align:center">
          ${transNum(data.price)}
        </div>
        <div style=" display: flex; flex-direction: row; gap: 2px; justify-content: flex-start; align-items: center;">
          <span style="font-family:'Pretendard-Regular';color: rgba(55, 56, 60, 0.61); font-size: 11px;  white-space: nowrap;">${data.category}</span>
          <div style="width: 1px; height: 8px; background-color: rgba(55, 56, 60, 0.16);"></div>
          <span style="font-family:'Pretendard-Medium'; font-size:11px; color: rgba(55, 56, 60, 0.61); white-space: nowrap;">${data.pyeong.toLocaleString()}평</span>
        </div>
      </div>
      <div style="position: absolute; left: 50%; bottom: -12px; transform:translateX(-50%)">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
          <path d="M4 1.5H2.91289L3.62037 2.3254L8.86111 8.4396C9.45976 9.13801 10.5402 9.13801 11.1389 8.4396L16.3796 2.3254L17.0871 1.5H16H4Z" fill="white" stroke="#1E9B6B" />
          <rect width="20" height="2" fill="white" />
        </svg>
      </div>
    </div>
  `;
};
export const makePropertyforPensionMarkers = (data: any) => {
  if (data.type === '건물') {
    return `<div></div>`;
  }
  if (data.pension === null) {
    return `<div></div>`;
  }

  const is_safe_to_sellIcon =
    data.is_safe_to_sell === 'Y'
      ? `<img src="/asset/images/icon/safe-mark.png" alt="" style="position:absolute; top:-10px; left:-10px; width:22px; height:22px"/>`
      : '';

  return `
    <div
            style=" 
              width: max-content;
              position: relative;
              padding: 5px 6px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 6px;
              background: #ffffff;
              border-radius: 8px;
              border: 1px solid #FB6400;
            "
          >
            ${is_safe_to_sellIcon}       
            <div style="width:100%;">
              <div
                style="width:100%; display: flex; flex-direction: row; align-items: center; gap: 4px;">
                <span 
                style="font-family:'Pretendard-Bold'; color: #FB6400; font-size: 12px; white-space: nowrap;">월 최대 ${transNum(data.pension, true)}원</span>
                <div style="width: 1px; height: 8px; background-color: rgba(55, 56, 60, 0.16);"></div>
                <span
                  style="font-family: 'Pretendard-Medium'; font-weight: 500; font-size: 11px; color: rgba(55, 56, 60, 0.61); white-space: nowrap;">
                  ${data.pyeong.toLocaleString()}평  
                </span>
              </div>
            </div>
            <div style="position: absolute; left: 50%; bottom: -12px; transform: translateX(-50%)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
              >
                <path
                  d="M4 1.5H2.91289L3.62037 2.3254L8.86111 8.4396C9.45976 9.13801 10.5402 9.13801 11.1389 8.4396L16.3796 2.3254L17.0871 1.5H16H4Z"
                  fill="white"
                  stroke="#FB6400"
                />
                <rect width="20" height="2" fill="white" />
              </svg>
            </div>
          </div>
        `;
};

export const makeReaPriceMarkers = (data: any) => {
  return `
    <div style="position: relative; padding: 4px; display: flex; justify-content: flex-start; align-items: center; gap: 2px; background: #ffffff; border-radius: 8px; border: 1px solid #358CFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.08);">     
      <div style="width: 22px; height: 22px; border-radius: 100px; background-color: #E8F2FF; display: flex; justify-content: center; align-items: center;">
        <img src="/asset/images/icon/blue-${renderIcon(data.category)}.svg" alt="" style="width: 14px; height: 14px;"/>
      </div>
      <div>
        <div style="font-family: 'Pretendard-Bold'; color: #37383C; font-size: 14px; line-height: 20px; white-space: nowrap; text-align:center">
          ${transNum(data.price)}
        </div>
        <div style=" display: flex; flex-direction: row; gap: 2px; justify-content: flex-start; align-items: center;">
          <span style="font-family:'Pretendard-Regular';color: rgba(55, 56, 60, 0.61); font-size: 11px;  white-space: nowrap;">${data.category}</span>
          <div style="width: 1px; height: 8px; background-color: rgba(55, 56, 60, 0.16);"></div>
          <span style="font-family:'Pretendard-Medium'; font-size:11px; color: rgba(55, 56, 60, 0.61); white-space: nowrap;"> '${data.dealdate.split('-')[0].slice(2, 4)}.${data.dealdate.split('-')[1]}</span>
        </div>
      </div>
      <div style="position: absolute; left: 50%; bottom: -12px; transform:translateX(-50%)">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
          <path d="M4 1.5H2.91289L3.62037 2.3254L8.86111 8.4396C9.45976 9.13801 10.5402 9.13801 11.1389 8.4396L16.3796 2.3254L17.0871 1.5H16H4Z" fill="white" stroke="#358CFF" />
          <rect width="20" height="2" fill="white" />
        </svg>
      </div>
    </div>
            `;
};

export const makeAuctionforPriceMarkers = (data: any) => {
  const is_safe_to_sellIcon =
    data.is_safe_to_sell === 'Y'
      ? `<img src="/asset/images/icon/safe-mark.png" alt="" style="position:absolute; top:-10px; left:-10px; width:22px; height:22px"/>`
      : '';

  return `<div style="position: relative; padding: 4px; display: flex; justify-content: flex-start; align-items: center; gap: 2px; background: #ffffff; border-radius: 8px; border: 1px solid #EE2C80;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  ">
      ${is_safe_to_sellIcon}
      <div style="
        position: absolute;
        top: -20px;          
        right: 0px;
        padding:4px 8px;
        border-radius: 100px;
        background: #EE2C80;
        color: white;
        font-family:'Pretendard-Bold';
        font-size: 11px;
        white-space: nowrap;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.08);
      ">
        유찰${Number(data.bidcount)}회
                  
      </div>                 
      <div style="width: 22px; height: 22px; border-radius: 100px; background-color: #FFEAF3; display: flex; justify-content: center; align-items: center;">
        <img src="/asset/images/icon/pink-${renderIcon(data.category)}.svg" alt="" style="width: 14px; height: 14px;"/>
      </div>
      <div>
        <div style="font-family: 'Pretendard-Bold'; color: #37383C; font-size: 14px; line-height: 20px; white-space: nowrap; text-align:center">
          ${transNum(data.price)}
        </div>
        <div style=" display: flex; flex-direction: row; gap: 2px; justify-content: flex-start; align-items: center;">
          <span style="font-family:'Pretendard-Regular';color: rgba(55, 56, 60, 0.61); font-size: 11px;  white-space: nowrap;">${data.category}</span>
          <div style="width: 1px; height: 8px; background-color: rgba(55, 56, 60, 0.16);"></div>
          <span style="font-family:'Pretendard-Medium'; font-size:11px; color: rgba(55, 56, 60, 0.61); white-space: nowrap;">${data.pyeong.toLocaleString()}평</span>
        </div>
      </div>
      <div style="position: absolute; left: 50%; bottom: -12px; transform:translateX(-50%)">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
          <path d="M4 1.5H2.91289L3.62037 2.3254L8.86111 8.4396C9.45976 9.13801 10.5402 9.13801 11.1389 8.4396L16.3796 2.3254L17.0871 1.5H16H4Z" fill="white" stroke="#EE2C80" />
          <rect width="20" height="2" fill="white" />
        </svg>
      </div>
    </div>
            `;
};

export const makeAuctionforPensionMarkers = (data: any) => {
  if (data.type === '건물') {
    return `<div></div>`;
  }
  if (data.pension === null) {
    return `<div></div>`;
  }

  const is_safe_to_sellIcon =
    data.is_safe_to_sell === 'Y'
      ? `<img src="/asset/images/icon/safe-mark.png" alt="" style="position:absolute; top:-10px; left:-10px; width:22px; height:22px"/>`
      : '';

  return `
          <div
            style="
              width: max-content;
              position: relative;
              padding: 5px 6px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 6px;
              background: #ffffff;
              border-radius: 8px;
              border: 1px solid #FB6400;
            "
          >   
            ${is_safe_to_sellIcon}
            <div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;  
                  gap: 4px;"
              >
                <span 
                style="
                  font-family:'Pretendard-Bold';
                  font-weight: 500; 
                  color: #FB6400;
                  font-size: 12px;"
                  white-space: nowrap;">월 최대 ${transNum(data.pension, true)}원</span>
                <div
                  style="
                    width: 1px;
                    height: 8px;
                    background-color: rgba(55, 56, 60, 0.16);"
                ></div>
                <span
                  style="
                    font-family: 'Pretendard-Medium';
                    font-weight: 500;
                    color: rgba(55, 56, 60, 0.61);
                    font-size: 11px;
                    white-space: nowrap;"
                >
                  ${data.pyeong.toLocaleString()}평  
                </span>
              </div>
            </div>
            <div style=" position: absolute; left: 50%; bottom: -12px; transform:translateX(-50%);">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
              >
                <path
                  d="M4 1.5H2.91289L3.62037 2.3254L8.86111 8.4396C9.45976 9.13801 10.5402 9.13801 11.1389 8.4396L16.3796 2.3254L17.0871 1.5H16H4Z"
                  fill="white"
                  stroke="#FB6400"
                />
                <rect width="20" height="2" fill="white" />
              </svg>
            </div>
          </div>
        `;
};
