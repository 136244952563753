import styled from 'styled-components';
import colors from '../../color';
import BottomNavigation from '../components/navigation/Navigation';
import MoreInfo from './components/MoreInfo';
import MoreContent from './components/MoreContent';
import { useEffect, useRef, useState } from 'react';

const More = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showButton, setShowButton] = useState(false);
  const onClickTopButton = () => {
    if (!containerRef.current) return;
    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        setShowButton(scrollTop >= 100); // 스크롤이 100px 이상일 때 버튼 표시
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <Header>더보기</Header>
      <MoreInfo></MoreInfo>
      <Divider></Divider>
      <MoreContent></MoreContent>
      {showButton && (
        <TopButton onClick={onClickTopButton}>
          <img src="./asset/images/arrow/arrow-to-top.png" alt="" />
        </TopButton>
      )}
      <BottomNavigation />
    </Container>
  );
};

export default More;

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow-x: hidden; /* 가로 스크롤만 숨기기 */
  overflow-y: auto; /* 필요할 때만 세로 스크롤 허용 */
  background-color: ${colors.white};
`;

const Header = styled.div`
  width: 100%;
  height: 51px;
  padding: 15px 16px;
  color: #222222;
  font-size: 18px;
  font-family: 'Pretendard-Medium';
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
`;

const Divider = styled.div`
  width: 100%;
  height: 8px;
  background-color: #f5f5f5;
`;

const TopButton = styled.div`
  position: fixed;
  bottom: 70px;
  right: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
`;
