import { useSelector } from 'react-redux';
import ReportUtil from '../../components/report';
import { RowSectionTitle, RowUnderLine } from '../../components/Row';
import { TextSectionTitle } from '../../components/Text';
import { BoxCard1, BoxGreen1 } from '../../components/Box';
import { transNum } from '../../../../../../function/function';
import { CultivationIncomeProps } from '../../../../data';
// import '../../../pension/input/Main.css';

const ExpectedCropIncome = ({
  cultivationIncome,
  cropName,
}: {
  cultivationIncome: CultivationIncomeProps;
  cropName: string;
}) => {
  const state = { loading: 'N' };

  const successPossibilityRate = (profitRate: string) => {
    switch (profitRate) {
      case '매우 낮음':
        return 1;
      case '낮음':
        return 2;
      case '보통':
        return 3;
      case '높음':
        return 4;
      case '매우 높음':
        return 5;
      default:
        return 1;
    }
  };

  const cropCompetitive = (profitRate: string) => {
    return successPossibilityRate(profitRate);
  };
  return (
    <div style={{ width: '100%', backgroundColor: 'white' }}>
      <RowSectionTitle>
        <TextSectionTitle>1. 예상 경작 수익</TextSectionTitle>
      </RowSectionTitle>
      <div className="padding-8-16 gap-10">
        <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            해당 농지에{' '}
            <span className="color-primary-500 f-size-16 line-h-24">
              {cropName}
            </span>
            을(를) 재배할 경우,
          </div>
          <div
            className="font-bold f-size-16 line-h-24"
            style={{
              color:
                cropCompetitive(cultivationIncome.successPossibility) < 3
                  ? '#d62554'
                  : '#1E9B6B',
            }}
          >
            성공 가능성은 '
            {state.loading === 'N' ? cultivationIncome.successPossibility : '-'}
            '입니다.
          </div>
        </BoxGreen1>
      </div>
      <div className="padding-0-16-24 gap-8">
        <RowUnderLine>
          <BoxCard1
            title="매출"
            value={
              (state.loading === 'N'
                ? transNum(cultivationIncome.income)
                : '-') + '원'
            }
          />
          <BoxCard1
            title="월 평균"
            value={
              (state.loading === 'N'
                ? transNum(cultivationIncome.income / 12)
                : '-') + '원'
            }
          />
        </RowUnderLine>
        <RowUnderLine>
          <BoxCard1
            title="비용"
            value={
              (state.loading === 'N' ? transNum(cultivationIncome.cost) : '-') +
              '원'
            }
          />
          <BoxCard1
            title="월 평균"
            value={
              (state.loading === 'N'
                ? transNum(cultivationIncome.cost / 12)
                : '-') + '원'
            }
          />
        </RowUnderLine>
        <RowUnderLine>
          <BoxCard1
            title="영업이익(영업이익률)"
            value={
              (state.loading === 'N'
                ? transNum(cultivationIncome.businessProfits)
                : '-') +
              '원(' +
              (state.loading === 'N'
                ? cultivationIncome.businessProfitRate
                : '-') +
              '%)'
            }
          />
          <BoxCard1
            title="월 평균"
            value={
              (state.loading === 'N'
                ? transNum(cultivationIncome.businessProfits / 12)
                : '-') + '원'
            }
          />
        </RowUnderLine>
      </div>
    </div>
  );
};

export default ExpectedCropIncome;
