import { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../color';

const TopTriangleTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const tooltipShown = localStorage.getItem('shownTooltip');
    if (tooltipShown !== 'false') {
      setShowTooltip(true);
      const timer = setTimeout(() => {
        setShowTooltip(false);
        localStorage.setItem('shownTooltip', 'false');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShowTooltip(false);
    localStorage.setItem('shownTooltip', 'false');
  };

  if (!showTooltip) return null;

  return (
    <TooltipContainer>
      <TooltipTextBox>
        <img src="/asset/images/icon/safety.svg" alt="" />
        <p>
          <YellowText>안심 농지 매물</YellowText>을 찾아보세요
        </p>
      </TooltipTextBox>
      <img
        src="/asset/images/close/close-white.svg"
        alt=""
        onClick={handleClose}
      />
    </TooltipContainer>
  );
};

export default TopTriangleTooltip;

const TooltipContainer = styled.div`
  position: absolute;
  padding: 8px 16px;
  background-color: #0073f0;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  top: 97px;
  right: 12px;
  z-index: 9999;
  box-shadow:
    0px 6px 12px 0px rgba(0, 0, 0, 0.12),
    0px 4px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    top: -8px; /* Adjust as needed to position the triangle */
    left: 17%; /* Center the triangle */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #0073f0; /* Triangle color matching the background */
  }
  @media (min-width: 360px) {
    right: 28px;
  }
`;

const TooltipTextBox = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.white};
  white-space: nowrap;
`;

const YellowText = styled.span`
  color: #ffffa5;
`;
