import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../color';

interface PageTopNavProps {
  title: string;
}

const PageTopNav = ({ title }: PageTopNavProps) => {
  const navigate = useNavigate();
  return (
    <PageTopContainer>
      <img
        src="/asset/images/arrow/arrow-left-gray.svg"
        alt=""
        onClick={() => {
          navigate(-1);
        }}
      />
      <PageTopTitle>{title}</PageTopTitle>
    </PageTopContainer>
  );
};

export default PageTopNav;

export const PageTopClose = () => {
  const navigate = useNavigate();
  return (
    <PageTop>
      <img
        src="/asset/images/close/close-darkgray.svg"
        alt=""
        onClick={() => {
          navigate(-1);
        }}
      />
    </PageTop>
  );
};

const PageTop = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const PageTopContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
`;

const PageTopTitle = styled.p`
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelNormal};
`;
