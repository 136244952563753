import styled from 'styled-components';
import { formatToKoreanCurrency } from '../../../function/function';

interface TextColorProps {
  color: string;
  backgroundColor: string;
}

interface SummaryTitleProps {
  saleType: string;
  type: string;
  price?: number;
  caseNo?: string;
  court?: string;
  dealdate?: string;
}

interface SummaryInfoProps {
  saleType: string;
}

const TextColor = (dataType: string): TextColorProps => {
  switch (dataType) {
    case '매물':
      return { color: '#EC4F4F', backgroundColor: '#FFEDED' };
    case '경매':
      return { color: '#3160D6', backgroundColor: '#EBEFFA' };
    case '공매':
      return { color: '#7343E7', backgroundColor: '#F2EBFD' };
    case '지자체 물건':
      return {
        color: '#F47A00',
        backgroundColor: '#FDF6EE',
      };
    case '인터넷 매물':
      return {
        color: '#C28200',
        backgroundColor: '#FFF9E6',
      };
    case '농지은행':
      return { color: '#F47A00', backgroundColor: '#FDF6EE' };
    default:
      return {
        color: 'rgba(55, 56, 60, 0.61)',
        backgroundColor: '#f5f5f5',
      };
  }
};
const landType = (type: string) => {
  if (type !== '토지') {
    return (
      <>
        <TitleContent {...TextColor('')}>토지</TitleContent>
        <TitleContent {...TextColor('')}>주택</TitleContent>
      </>
    );
  } else {
    return <TitleContent {...TextColor('')}>토지</TitleContent>;
  }
};
const formatDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split('-'); // "2024-01-12"을 "-"로 분리
  return `${year.slice(2)}.${month}.${day}`; // 년도는 뒤 두 자리만, 월과 일은 그대로 사용
};

const renderTitleInfo = ({
  saleType,
  type,
  caseNo,
  court,
  price,
  dealdate,
}: SummaryTitleProps) => {
  if (saleType === '매물') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        {landType(type)};
      </TitleContentBox>
    );
  } else if (saleType === '경매' || saleType === '공매') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        {landType(type)}
        <TitleContent {...TextColor('')}>{caseNo}</TitleContent>
      </TitleContentBox>
    );
  } else if (saleType === '지자체 물건') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        <>
          <TitleContent {...TextColor('')}>농지은행</TitleContent>
          <TitleContent {...TextColor('')}>토지</TitleContent>
        </>
      </TitleContentBox>
    );
  } else if (saleType === '인터넷 매물') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        {landType(type)}
      </TitleContentBox>
    );
  } else {
    return (
      <RealDealBox>
        <RealDealDataBox>
          <RealDealContentBox>
            <div>최근실거래</div>
            <img src="/asset/images/icon/info14.svg" alt=""></img>
          </RealDealContentBox>
          {price && price > 0 ? (
            <RealDealPriceData>
              {formatToKoreanCurrency(price)}
            </RealDealPriceData>
          ) : (
            <RealDealPriceData
              style={{
                color: 'rgba(55,56,60,0.28)',
              }}
            >
              실거래가 없음
            </RealDealPriceData>
          )}
        </RealDealDataBox>
        {price && price > 0 ? (
          <RealDealDate>
            최근 실거래일 {dealdate && formatDate(dealdate)}
          </RealDealDate>
        ) : (
          <RealDealDate>-</RealDealDate>
        )}
      </RealDealBox>
    );
  }
};

const ListTitle = ({
  saleType,
  type,
  caseNo,
  court,
  price,
  dealdate,
  address,
  zoning,
  data,
}: any) => {
  const PriceColor = (price: string): PriceColorProps => {
    switch (price) {
      case 'minPrice':
        return { priceColor: '#EC4F4F' };
      case 'appriasedPrice':
        return { priceColor: 'black' };
      default:
        return { priceColor: 'black' };
    }
  };

  const DividerHeight = (dataType: string): DividerProps => {
    switch (dataType) {
      case 'auction':
        return { height: 24 };
      case 'summary':
        return { height: 8 };
      default:
        return { height: 8 };
    }
  };

  const SaleDateFont = (contentType: string): SaleDateProps => {
    switch (contentType) {
      case 'bidDate':
        return { fontColor: '#222222', fontWeight: 700 };
      case 'D_Day':
        return { fontColor: '#1E9B6B', fontWeight: 500 };
      default:
        return { fontColor: '#616161', fontWeight: 500 };
    }
  };

  const renderTopInfo = (data: any, saleType: string) => {
    if (saleType === '경매' || saleType === '공매') {
      return (
        <>
          <AuctionPriceBox>
            <AuctionContentBox>
              <div>감정가({data.bidDate})</div>
              <AuctionContentPrice {...PriceColor('appriasedPrice')}>
                {formatToKoreanCurrency(Number(data.appriasedPrice))}
              </AuctionContentPrice>
            </AuctionContentBox>
            <ContentDivider
              height={DividerHeight('auction').height}
            ></ContentDivider>
            <AuctionContentBox>
              <div>
                최저입찰가(
                {(
                  (Number(data.lowestPrice) / Number(data.appriasedPrice)) *
                  100
                ).toFixed(1)}
                %)
              </div>
              <AuctionContentPrice {...PriceColor('minPrice')}>
                {formatToKoreanCurrency(data.lowestPrice)}
              </AuctionContentPrice>
            </AuctionContentBox>
          </AuctionPriceBox>
          <SaleDateBox>
            <SaleDateInner>
              <SaleDateContent {...SaleDateFont('')}>입찰일</SaleDateContent>
              <SaleDateContent {...SaleDateFont('bidDate')}>
                {data.bidDate}
              </SaleDateContent>
            </SaleDateInner>
            <SaleDateContent {...SaleDateFont('D_Day')}>
              마감
              {Number(data.restBidDays) > 0 ? ` D-${data.restBidDays}` : ''}
            </SaleDateContent>
          </SaleDateBox>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <ListTitleBox saleType={saleType}>
      <SummaryTitleBox>
        {renderTitleInfo({ saleType, type, caseNo, court, price, dealdate })}
      </SummaryTitleBox>
      <SummaryInfoBox saleType={saleType}>
        {saleType && saleType === '인터넷 매물' && (
          <PriceInfo>{formatToKoreanCurrency(price)}</PriceInfo>
        )}
        {(saleType && saleType === '매매') ||
          (saleType === '지자체 물건' && (
            <PriceInfo>매매 {formatToKoreanCurrency(price)}</PriceInfo>
          ))}
        {renderTopInfo(data, saleType)}
        <AddressBox>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            {type === '토지' ? (
              <img src="/asset/images/icon/forest.svg"></img>
            ) : (
              <img src="/asset/images/icon/green-building.svg"></img>
            )}
            {address}
          </div>
        </AddressBox>
        {/* <SummaryContentBox>
          <SummaryContent>
            <div>{zoning}</div>
          </SummaryContent>
        </SummaryContentBox> */}
      </SummaryInfoBox>
    </ListTitleBox>
  );
};

export default ListTitle;

interface SaleDateProps {
  fontColor: string;
  fontWeight: number;
}

interface PriceColorProps {
  priceColor: string;
}

interface DividerProps {
  height: number;
}

const SaleDateBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #eaf8f2;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  border-radius: 8px;
`;

const SaleDateContent = styled.div<SaleDateProps>`
  color: ${(props) => props.fontColor};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: ${(props) => props.fontWeight};
  line-height: 20px;
  word-wrap: break-word;
`;

const AuctionPriceBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 83px;
  padding: 12px 16px;
  background-color: #fafafa;
  border-radius: 8px;
  gap: 8px;
`;

const AuctionContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  flex: 1;
`;

const AuctionContentPrice = styled.div<PriceColorProps>`
  color: ${(props) => props.priceColor};
  font-size: 20px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 29px;
  word-wrap: break-word;
`;

const ContentDivider = styled.div<DividerProps>`
  background-color: rgba(112, 115, 124, 0.16);
  width: 1px;
  height: ${(props) => props.height}px;
`;

const SaleDateInner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const AddressBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
`;

const ListTitleBox = styled.div<SummaryInfoProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) =>
    props.saleType === '인터넷 매물' ||
    props.saleType === '지자체 물건' ||
    props.saleType === '매물'
      ? '8px'
      : '16px'};
  white-space: nowrap;
  width: 100%;
`;

const SummaryTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  width: 100%;
`;

const TitleContentBox = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

const TitleContent = styled.div<TextColorProps>`
  justify-content: center;

  align-items: center;
  gap: 8px;
  border-radius: 4px;
  display: inline-flex;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding: 2px 6px;
`;

const RealDealBox = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 71px;
  padding: 12px 16px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RealDealDataBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const RealDealContentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const RealDealPriceData = styled.div`
  color: #222222;
  font-size: 20px;
  font-family: Pretendard-Bold;
  line-height: 29px;
  word-wrap: break-word;
`;

const RealDealDate = styled.div`
  display: flex;
  justify-content: flex-end;
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const SummaryInfoBox = styled.div<SummaryInfoProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) =>
    props.saleType === '인터넷 매물' ||
    props.saleType === '지자체 물건' ||
    props.saleType === '매물'
      ? '4px'
      : '16px'};
  // gap: 16px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const PriceInfo = styled.div`
  color: black;
  font-size: 24px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 34.8px;
  word-wrap: break-word;
`;

const SummaryContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;
