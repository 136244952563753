import { useEffect, useRef, useState } from 'react';
import {
  Container,
  FilterTab,
  FilterTabText,
  RegionDropdown,
  TabMenu,
  TabMenuBox,
  TabMenuInner,
  TabTitleBox,
  TitleGreen,
  FilterContainer,
  PageTop,
  BlueTooltip,
  RegionDropdownBox,
  TextYellow,
  BlueTooltipText,
  SelectedOptionContainer,
  ResetBtn,
  SelecteOptionBtn,
  SelecteOptionBtnText,
} from './Property.styles';
import SaleList from '../main/components/list/List';
import BottomNavigation from '../components/navigation/Navigation';
import PropertyRegionModal from './regionModal/RegionModal';
import FilterModal from '../main/components/filter/FilterModal';
import { SaleData } from '../main/MainData';
import Sales from '../../service/Sales';
import {
  areaOption,
  priceOption,
  yearOption,
} from '../main/components/filter/FilterOption';
import Loading from '../components/loading/Loading';
import { usePropertyFilter } from '../../contexts/PropertyContext';
import { Helmet } from 'react-helmet';

const Property = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const pageTopRef = useRef<HTMLDivElement>(null);
  const [deviceHeight, setDeviceHeight] = useState(0);
  const [pageTopHeight, setPageTopHeight] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  const [regionModalOn, setRegionModalOn] = useState(false);
  const [filterModalOn, setFilterModalOn] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [cIdx, setCIdx] = useState('');
  const [price, setPrice] = useState('');
  const [area, setArea] = useState('');
  const [regTime, setRegTime] = useState('');
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<SaleData[]>([]);
  const [isMoreList, setIsMoreList] = useState(false);
  const [recommendList, setRecommendList] = useState<SaleData[]>([]);
  const [r_c_idx, setR_CIdx] = useState('');
  const [r_price, setR_Price] = useState('');
  const [r_area, setR_Area] = useState('');
  const [r_regTime, setR_RegTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const {
    tab,
    setTab,
    propertySort,
    propertyFilterOption,
    setPropertyFilterOption,
    isSafeItem,
    propertyPnu,
    address,
  } = usePropertyFilter();

  const filters = [
    '대상',
    '유형',
    '지목',
    '용도',
    '면적',
    '가격',
    '건축연도',
    // '테마',
  ];

  useEffect(() => {
    setCIdx('');
  }, []);

  useEffect(() => {
    if (pageTopRef.current) {
      setPageTopHeight(pageTopRef.current.offsetHeight);
    }
  }, [tab]);

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      setDeviceHeight(containerHeight);
      setListHeight(containerHeight - pageTopHeight);
    }
  }, [pageTopHeight]);

  useEffect(() => {
    // 로컬 스토리지에서 저장된 날짜를 가져옴
    const storedDate = localStorage.getItem('tooltipDismissedDate');
    const today = new Date();
    const currentDateString = today.toISOString().split('T')[0];

    // 오늘 날짜와 로컬 스토리지의 날짜 비교
    if (storedDate === currentDateString) {
      setShowTooltip(false);
    }
  }, []);

  const changeValue = (item: string, options: any) => {
    const foundOption = options.find((option: any) => option.label === item);
    return foundOption ? foundOption.value : '';
  };

  const changeAllText = (item: any) => {
    return item.join(',') === '전체' ? '' : item.join(',');
  };

  const convertSaleTypeToEnglish = (saleTypes: string[]): string => {
    const saleTypeMap: { [key: string]: string } = {
      매물: 'A',
      지자체매물: 'B',
      경매: 'C',
      공매: 'D',
      인터넷매물: 'E',
    };

    // Convert each sale type to its mapped value or an empty string
    const convertedTypes = saleTypes.map(
      (saleType) => saleTypeMap[saleType] || '',
    );

    // Filter out empty strings and join the values with ','
    return convertedTypes.filter((type) => type !== '').join(',');
  };

  const getList = async () => {
    setTotal(0);
    setCIdx('');
    setPrice('');
    setArea('');
    setRegTime('');
    setList([]);
    setLoading(true);
    try {
      const res = await Sales.listAll(
        propertyFilterOption.target === '전체'
          ? ''
          : propertyFilterOption.target === '토지'
            ? 'L'
            : 'H',
        changeAllText(propertyFilterOption.category),
        changeAllText(propertyFilterOption.purpose),
        changeValue(propertyFilterOption.price[0], priceOption),
        changeValue(propertyFilterOption.price[1], priceOption),
        changeValue(propertyFilterOption.area[0], areaOption),
        changeValue(propertyFilterOption.area[1], areaOption),
        changeValue(propertyFilterOption.year[0], yearOption),
        changeValue(propertyFilterOption.year[1], yearOption),
        convertSaleTypeToEnglish(propertyFilterOption.sale_type),
        propertyPnu,
        propertySort,
        '',
        '',
        '',
        '',
        isSafeItem ? 'Y' : 'N',
        '',
        '',
        '',
        '',
      );
      if (res.result.list !== null) {
        setList(res.result.list);
        setTotal(res.result.totalCount);
        setCIdx(res.result.list[res.result.list.length - 1].c_idx);
        setPrice(res.result.list[res.result.list.length - 1].price);
        setArea(res.result.list[res.result.list.length - 1].area);
        setRegTime(res.result.list[res.result.list.length - 1].regtime);
        setRecommendList(res.result.recommendList);
        setR_CIdx(
          res.result.recommendList[res.result.recommendList.length - 1].c_idx,
        );
        setR_Price(
          res.result.recommendList[res.result.recommendList.length - 1].price,
        );
        setR_Area(
          res.result.recommendList[res.result.recommendList.length - 1].area,
        );
        setR_RegTime(
          res.result.recommendList[res.result.recommendList.length - 1].regtime,
        );
        setLoading(false);
      } else {
        setList([]);
        setRecommendList(res.result.recommendList);
        setR_CIdx(
          res.result.recommendList[res.result.recommendList.length - 1].c_idx,
        );
        setR_Price(
          res.result.recommendList[res.result.recommendList.length - 1].price,
        );
        setR_Area(
          res.result.recommendList[res.result.recommendList.length - 1].area,
        );
        setR_RegTime(
          res.result.recommendList[res.result.recommendList.length - 1].regtime,
        );
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching list', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, [propertyFilterOption, propertyPnu, propertySort, isSafeItem]);

  const handleTooltipClose = () => {
    const today = new Date();
    const currentDateString = today.toISOString().split('T')[0];
    localStorage.setItem('tooltipDismissedDate', currentDateString);
    setShowTooltip(false);
  };

  const getMoreList = async () => {
    setLoading(true);
    try {
      const res = await Sales.listAll(
        propertyFilterOption.target === '전체'
          ? ''
          : propertyFilterOption.target === '토지'
            ? 'L'
            : 'H',
        changeAllText(propertyFilterOption.category),
        changeAllText(propertyFilterOption.purpose),
        changeValue(propertyFilterOption.price[0], priceOption),
        changeValue(propertyFilterOption.price[1], priceOption),
        changeValue(propertyFilterOption.area[0], areaOption),
        changeValue(propertyFilterOption.area[1], areaOption),
        changeValue(propertyFilterOption.year[0], yearOption),
        changeValue(propertyFilterOption.year[1], yearOption),
        convertSaleTypeToEnglish(propertyFilterOption.sale_type),
        propertyPnu,
        propertySort,
        cIdx,
        price,
        area,
        regTime,
        isSafeItem ? 'Y' : 'N',
        r_c_idx,
        r_price,
        r_area,
        r_regTime,
      );
      const fetchedList = res.result.list;
      if (fetchedList !== null) {
        setList((prevList) => [...prevList, ...fetchedList]);
        setCIdx(res.result.list[res.result.list.length - 1].c_idx);
        setPrice(res.result.list[res.result.list.length - 1].price);
        setArea(res.result.list[res.result.list.length - 1].area);
        setRegTime(res.result.list[res.result.list.length - 1].regtime);
        setIsMoreList(false);
      } else {
        if (res.result.recommendList !== null) {
          setRecommendList((prev) => [...prev, ...res.result.recommendList]);
          setR_CIdx(
            res.result.recommendList[res.result.recommendList.length - 1].c_idx,
          );
          setR_Price(
            res.result.recommendList[res.result.recommendList.length - 1].price,
          );
          setR_Area(
            res.result.recommendList[res.result.recommendList.length - 1].area,
          );
          setR_RegTime(
            res.result.recommendList[res.result.recommendList.length - 1]
              .regtime,
          );
        }

        setIsMoreList(false);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching more list:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cIdx !== '' || r_c_idx !== '') {
      getMoreList();
    }
  }, [isMoreList]);

  useEffect(() => {
    const defaultFilterOption = {
      sale_type: [],
      category: [],
      purpose: [],
      price: ['', ''],
      area: ['', ''],
      year: ['', ''],
      theme: [],
    };

    const { target, ...restFilterOption } = propertyFilterOption;

    const isChanged =
      JSON.stringify(restFilterOption) !== JSON.stringify(defaultFilterOption);
    setHasFilterChanged(isChanged);
  }, [propertyFilterOption]);

  const renderFilter = () => {
    if (hasFilterChanged) {
      if (propertyFilterOption.target === '전체') {
        return (
          <SelectedOptionContainer>
            <ResetBtn
              onClick={() => {
                setPropertyFilterOption({
                  target: '전체',
                  sale_type: [] as string[],
                  category: [] as string[],
                  purpose: [] as string[],
                  price: ['', ''] as [string, string],
                  area: ['', ''] as [string, string],
                  year: ['', ''] as [string, string],
                  theme: [] as string[],
                });
              }}
            >
              <img src="/asset/images/icon/reset.svg" alt="" />
            </ResetBtn>
            {propertyFilterOption.sale_type.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.sale_type.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      sale_type: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {propertyFilterOption.category.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.category.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      category: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {propertyFilterOption.purpose.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.purpose.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      purpose: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {(propertyFilterOption.price[0] !== '' ||
              propertyFilterOption.price[1] !== '') && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.price[0]} ~
                  {propertyFilterOption.price[1]}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      price: ['', ''],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {(propertyFilterOption.area[0] !== '' ||
              propertyFilterOption.area[1] !== '') && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.area[0] !== '최소'
                    ? propertyFilterOption.area[0] + '평'
                    : '최소'}
                  ~{propertyFilterOption.area[1] + '평'}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      area: ['', ''],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {(propertyFilterOption.year[0] !== '' ||
              propertyFilterOption.year[1] !== '') && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.year[0]} ~{propertyFilterOption.year[1]}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      year: ['', ''],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {propertyFilterOption.theme.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.theme.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      theme: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
          </SelectedOptionContainer>
        );
      }
      if (propertyFilterOption.target === '토지') {
        return (
          <SelectedOptionContainer>
            <ResetBtn
              onClick={() => {
                setPropertyFilterOption({
                  target: '전체',
                  sale_type: [] as string[],
                  category: [] as string[],
                  purpose: [] as string[],
                  price: ['', ''] as [string, string],
                  area: ['', ''] as [string, string],
                  year: ['', ''] as [string, string],
                  theme: [] as string[],
                });
              }}
            >
              <img src="/asset/images/icon/reset.svg" alt="" />
            </ResetBtn>
            {propertyFilterOption.sale_type.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.sale_type.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      sale_type: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {propertyFilterOption.category.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.category.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      category: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {(propertyFilterOption.price[0] !== '' ||
              propertyFilterOption.price[1] !== '') && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.price[0]} ~
                  {propertyFilterOption.price[1]}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      price: ['', ''],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {(propertyFilterOption.area[0] !== '' ||
              propertyFilterOption.area[1] !== '') && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.area[0] !== '최소'
                    ? propertyFilterOption.area[0] + '평'
                    : '최소'}
                  ~{propertyFilterOption.area[1] + '평'}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      area: ['', ''],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {propertyFilterOption.theme.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.theme.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      theme: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
          </SelectedOptionContainer>
        );
      }
      if (propertyFilterOption.target === '주택') {
        return (
          <SelectedOptionContainer>
            <ResetBtn
              onClick={() => {
                setPropertyFilterOption({
                  target: '전체',
                  sale_type: [] as string[],
                  category: [] as string[],
                  purpose: [] as string[],
                  price: ['', ''] as [string, string],
                  area: ['', ''] as [string, string],
                  year: ['', ''] as [string, string],
                  theme: [] as string[],
                });
              }}
            >
              <img src="/asset/images/icon/reset.svg" alt="" />
            </ResetBtn>
            {propertyFilterOption.sale_type.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.sale_type.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      sale_type: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {propertyFilterOption.purpose.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.purpose.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      purpose: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {(propertyFilterOption.price[0] !== '' ||
              propertyFilterOption.price[1] !== '') && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.price[0]} ~
                  {propertyFilterOption.price[1]}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      price: ['', ''],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {(propertyFilterOption.area[0] !== '' ||
              propertyFilterOption.area[1] !== '') && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.area[0] !== '최소'
                    ? propertyFilterOption.area[0] + '평'
                    : '최소'}
                  ~{propertyFilterOption.area[1] + '평'}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      area: ['', ''],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
            {propertyFilterOption.theme.length > 0 && (
              <SelecteOptionBtn>
                <SelecteOptionBtnText>
                  {propertyFilterOption.theme.join(', ')}
                </SelecteOptionBtnText>
                <img
                  src="/asset/images/close/close-green.svg"
                  alt=""
                  onClick={() => {
                    setPropertyFilterOption({
                      ...propertyFilterOption,
                      theme: [],
                    });
                  }}
                />
              </SelecteOptionBtn>
            )}
          </SelectedOptionContainer>
        );
      } else {
        return null;
      }
    } else {
      return (
        <FilterContainer>
          {filters.map((filter, index) => (
            <FilterTab
              key={index}
              onClick={() => {
                setFilterModalOn(true);
              }}
            >
              <FilterTabText>{filter}</FilterTabText>
              <img src="/asset/images/arrow/arrow-down.svg" alt="" />
            </FilterTab>
          ))}
        </FilterContainer>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>
          전국 모든 토지, 주택 매물 검색 - 안심 매물만 콕! | 트랜스파머
        </title>
        <meta
          name="description"
          content="전국 일반 매물, 지자체 매물, 경매, 공매물 검색 - 안심할 수 있는 가격대 매물만 보기 기능을 제공합니다 ."
        />
        <meta
          name="keywords"
          content="토지 매매, 땅매매, 땅값, 땅 시세, 토지 시세, 농지,  주택매매,  토지실거래가, 토지이음, 토지투자,  땅투자, 경매, 유휴지, 캠핑장 사업, 태양광 발전 임대,  창고임대 사업, 펜션 사업"
        />
        <meta
          property="og:title"
          content="트랜스파머 | 누구나 토지 투자를 똑똑하게"
        />
        <meta
          property="og:description"
          content="전국 토지 매물 시세 분석과 토지에 적합한 투자 상품을 제공합니다"
        />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta property="og:url" content="https://www.transfarmer.co.kr" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container ref={containerRef}>
        <PageTop ref={pageTopRef}>
          <RegionDropdownBox>
            <RegionDropdown
              onClick={() => {
                setRegionModalOn(true);
              }}
            >
              {address.sigungu !== ''
                ? address.sigungu + ' ' + address.eupmd
                : '전국'}
              <img src="/asset/images/arrow/arrow-down-black.svg" alt="" />
            </RegionDropdown>
            {showTooltip && (
              <BlueTooltip>
                <BlueTooltipText>
                  원하는 <TextYellow>지역</TextYellow>을 선택해 보세요
                </BlueTooltipText>
                <img
                  src="/asset/images/close/close-white.svg"
                  alt=""
                  style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                  onClick={handleTooltipClose}
                />
              </BlueTooltip>
            )}
          </RegionDropdownBox>

          <TabMenuBox>
            <TabMenuInner>
              <TabMenu
                active={tab === '매물'}
                onClick={() => {
                  setTab('매물');
                }}
              >
                매물
              </TabMenu>
              <TabMenu
                active={tab === '땅줍'}
                onClick={() => {
                  // setTab('땅줍');
                  alert('서비스 오픈 예정입니다.');
                }}
              >
                땅줍
              </TabMenu>
            </TabMenuInner>
          </TabMenuBox>

          <TabTitleBox>
            <span>이 지역 매물</span>
            <img
              src={`/asset/images/icon/${hasFilterChanged ? 'filterOn' : 'filter'}.svg`}
              alt=""
              onClick={() => {
                setFilterModalOn(true);
              }}
              style={{ cursor: 'pointer' }}
            />
          </TabTitleBox>
          {renderFilter()}
        </PageTop>
        <SaleList
          data={list}
          recommendList={recommendList}
          showListAll={true}
          smallDevice={false}
          deviceHeight={listHeight}
          setIsMoreList={setIsMoreList}
          onChange={getList}
        />
        <BottomNavigation />
        {regionModalOn && (
          <PropertyRegionModal
            setRegionModalOn={setRegionModalOn}
            deviceHeight={deviceHeight}
            smallDevice={true}
            setCIdx={setCIdx}
          />
        )}

        {filterModalOn && (
          <FilterModal
            menu="지도"
            setFilterModalOn={setFilterModalOn}
            deviceHeight={deviceHeight}
            smallDevice={true}
            setShowSnackBar={() => {}}
          />
        )}
        {loading && <Loading />}
      </Container>
    </>
  );
};
export default Property;
