import styled from 'styled-components';
import ExpectedCropIncome from './components/ExpectedCropIncome';
import '../../pension/input/Main.css';
import SurroundingTrade from './components/SurroundingTrade';
import Temperature from './components/Temperature';
import LandInfo from './components/LandInfo';
import ExpectedSales from './components/ExpectedSales';
import ExpectedCost from './components/ExpectedCost';
import ExpectedIncome from './components/ExpectedIncome';
import ExpectedROI from './components/ExpectedROI';
import DataSource from './components/DataSource';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LandIncome from '../../../../../service/LandIncome';
import {
  CultivationIncomeProps,
  ExpectedCostProps,
  ExpectedIncomeProps,
  ExpectedInvestInfoProps,
  ExpectedProfitProps,
  OriginProps,
  SoilInfoProps,
  SurroundListProps,
  TempertureDataProps,
} from '../../../data';
import Sales from '../../../../../service/Sales';
import Loading from '../../../../components/loading/Loading';
import { useMapContext } from '../../../../../contexts/MapContext';
interface TabContentProps {
  IsActive: boolean;
}

const LandIncomeResult = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  const {
    data,
    c_idx,
    pnu,
    area,
    landPrice,
    cropName,
    itemType,
    housekye,
    address,
  } = location.state || {};
  const { latLng } = useMapContext();
  const [origin, setOrigin] = useState<OriginProps[]>([]);
  const [expectedInvestInfo, setExpectedInvestInfo] =
    useState<ExpectedInvestInfoProps>();
  const [expectedProfit, setExpectedProfit] = useState<ExpectedProfitProps>();
  const [expectedCost, setExpectedCost] = useState<ExpectedCostProps>();
  const [expectedIncome, setExpectedIncome] = useState<ExpectedIncomeProps>();
  const [cultivationIncome, setCultivationIncome] =
    useState<CultivationIncomeProps>();
  const [soilInfo, setSoilInfo] = useState<SoilInfoProps>();
  const [surroundList, setSurroundList] = useState<SurroundListProps[]>([]);
  const [tempertureData, setTempertureData] = useState<TempertureDataProps>();
  const getResultAPI = async (data: any) => {
    setLoading(true);
    try {
      const res = await LandIncome.cropResult(data);
      setCultivationIncome(res.result.cultivationIncome);
      setExpectedInvestInfo(res.result.expectedInvestInfo);
      setExpectedProfit(res.result.expectedProfit);
      setExpectedCost({
        laborCost:
          res.result.expectedCost.empLaborCost.value +
          res.result.expectedCost.ownLaborCost.value,
        materialCost: res.result.expectedCost.materialCost.value,
        waterHeatingCost: res.result.expectedCost.waterHeatingCost.value,
        farmMachineryCost: res.result.expectedCost.farmMachineryCost.value,
        SGA: res.result.expectedCost.SGA.value,
        orchardCost: res.result.expectedCost.orchardCost.value,
        estimatedPrice: landPrice,
        facilityCost: res.result.expectedCost.facilityCost.value,
      });
      setExpectedIncome(res.result.expectedIncome);
      setCultivationIncome(res.result.cultivationIncome);
      setSoilInfo(res.result.soilInfo);
      setSurroundList(res.result.surroundList);
      setTempertureData(res.result.weatherInfo);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getOrigin = async () => {
    try {
      const res = await Sales.origin();
      setOrigin(res.result);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      getResultAPI(data);
      getOrigin();
    }
  }, [data]);
  const [selectedState, setSelectedState] = useState('expectedCropIncome');
  const tabData = [
    { state: 'expectedCropIncome', label: '1.예상 경작 수익' },
    { state: 'Temperature', label: '2. 농업기상' },
    { state: 'LandInfo', label: '3. 토양' },
    { state: 'ExpectedSales', label: '4. 예상매출' },
    { state: 'ExpectedCost', label: '5. 예상비용' },
    { state: 'ExpectedIncome', label: '6. 예상이익' },
    { state: 'ExpectedROI', label: '7. 예상투자수익' },
  ];
  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);
  const expectedCropIncomeRef = useRef<HTMLDivElement>(null);
  const TemperatureRef = useRef<HTMLDivElement>(null);
  const LandInfoRef = useRef<HTMLDivElement>(null);
  const ExpectedSalesRef = useRef<HTMLDivElement>(null);
  const ExpectedCostRef = useRef<HTMLDivElement>(null);
  const ExpectedIncomeRef = useRef<HTMLDivElement>(null);
  const ExpectedROIRef = useRef<HTMLDivElement>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true); // 초기 로딩 여부 상태 추가

  const scrollToRef = (state: string) => {
    // console.log('func scrollToRef');
    switch (state) {
      case 'expectedCropIncome':
        expectedCropIncomeRef.current?.scrollIntoView({
          behavior: 'smooth',
          // block: 'start',
        });
        break;
      case 'Temperature':
        TemperatureRef.current?.scrollIntoView({
          behavior: 'smooth',
          // block: 'start',
        });
        break;
      case 'LandInfo':
        LandInfoRef.current?.scrollIntoView({
          behavior: 'smooth',
          // block: 'start',
        });
        break;
      case 'ExpectedSales':
        ExpectedSalesRef.current?.scrollIntoView({
          behavior: 'smooth',
          // block: 'start',
        });
        break;
      case 'ExpectedCost':
        ExpectedCostRef.current?.scrollIntoView({
          behavior: 'smooth',
          // block: 'start',
        });
        break;
      case 'ExpectedIncome':
        ExpectedIncomeRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        break;
      case 'ExpectedROI':
        ExpectedROIRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        break;
      default:
        break;
    }
  };

  //버튼클릭으로 스크롤이 이동할 때 true로 변함
  const [isScrolling, setIsScrolling] = useState(false);

  //버튼 클릭으로 스크롤 이동 시 다시 버튼이 눌러지지 않게 수정
  const [buttonActive, setButtonActive] = useState(true);

  const scrollViewRef = useRef<HTMLDivElement | null>(null);

  const handleStateChange = (state: string, index: number, type: string) => {
    // if (type === 'scroll') {
    //   setSelectedState(state);
    //   // 클릭한 탭으로 스크롤 이동
    //   tabRefs.current[index]?.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'nearest',
    //   });
    // }

    if (type === 'tab' && buttonActive) {
      setSelectedState(state);
      // 클릭한 탭으로 스크롤 이동
      tabRefs.current[index]?.scrollIntoView({
        behavior: 'smooth',
        // block: 'nearest',
      });

      setIsScrolling(true);
      setButtonActive(false);
      scrollToRef(state);
      setTimeout(() => {
        setIsFirstLoad(false);
        setIsScrolling(false);
        setButtonActive(true);
      }, 1000);
    }
  };

  const [arrowDirection, setArrowDirection] = useState('down');

  const changeArrowDirection = () => {
    setArrowDirection((prevDirection) =>
      prevDirection === 'down' ? 'up' : 'down',
    );
  };

  ////////////////////////////////////////////////
  //이전에 작성한 코드로 스크롤을 할 때 높이에 따라 탭이 이동하는 코드
  // const scrollTab = (height: number) => {
  //   if (height < 900) {
  //     handleStateChange('expectedCropIncome', 0, 'scroll');
  //   } else if (height < 1333) {
  //     handleStateChange('Temperature', 1, 'scroll');
  //   } else if (height < 2000) {
  //     handleStateChange('LandInfo', 2, 'scroll');
  //   } else if (height < 2266) {
  //     handleStateChange('ExpectedSales', 3, 'scroll');
  //   } else if (height < 3333) {
  //     handleStateChange('ExpectedCost', 4, 'scroll');
  //   } else if (height < 3930) {
  //     handleStateChange('ExpectedIncome', 5, 'scroll');
  //   } else {
  //     handleStateChange('ExpectedROI', 6, 'scroll');
  //   }
  // };

  // const handleScroll = () => {
  //   if (!isScrolling && scrollViewRef.current) {
  //     const scrollY = scrollViewRef.current.scrollTop;
  //     scrollTab(scrollY);
  //   }
  // };
  ////////////////////////////////////////////////

  const refList = {
    scrollViewRef,
    menu1: expectedCropIncomeRef,
    menu2: TemperatureRef,
    menu3: LandInfoRef,
    menu4: ExpectedSalesRef,
    menu5: ExpectedCostRef,
    menu6: ExpectedIncomeRef,
    menu7: ExpectedROIRef,
  };
  const [myScroll, setMyscroll] = useState(0);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const convertTabMenu = (tab: string) => {
    switch (tab) {
      case 'menu1':
        return 'expectedCropIncome';
      case 'menu2':
        return 'Temperature';
      case 'menu3':
        return 'LandInfo';
      case 'menu4':
        return 'ExpectedSales';
      case 'menu5':
        return 'ExpectedCost';
      case 'menu6':
        return 'ExpectedIncome';
      case 'menu7':
        return 'ExpectedROI';
      default:
        return 'expectedCropIncome';
    }
  };

  function onScroll() {
    setMyscroll(refList.scrollViewRef.current?.scrollTop ?? 0);
    for (let i = 10; i > 0; i--) {
      const key = `menu${i}` as keyof typeof refList;
      const ref = refList[key];
      if (
        refList.scrollViewRef.current &&
        ref?.current &&
        refList?.scrollViewRef.current?.scrollTop >
          ref?.current?.getBoundingClientRect().top +
            refList?.scrollViewRef.current?.scrollTop -
            200
      ) {
        setSelectedState(convertTabMenu(`menu${i}`));
        return;
      }
    }
  }

  //스크롤 시 텝 메뉴바도 같이 이동
  useEffect(() => {
    if (
      refList.scrollViewRef.current &&
      refList.menu1?.current &&
      refList.menu7?.current &&
      refList.scrollViewRef.current?.scrollTop >
        refList.menu1.current?.getBoundingClientRect().top +
          refList.scrollViewRef.current.scrollTop -
          150
    ) {
      tabContainerRef.current?.scrollTo(
        ((myScroll - 200) /
          (refList.menu7.current?.getBoundingClientRect().top +
            refList.scrollViewRef.current.scrollTop -
            150)) *
          656,
        0,
      );
    }
  }, [myScroll]);

  useEffect(() => {
    refList.scrollViewRef.current?.addEventListener('scroll', onScroll);
    return () => {
      refList.scrollViewRef.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <LandIncomeResultContainer
      // onScroll={handleScroll}
      ref={scrollViewRef}
    >
      <Header>
        <TitleBox>
          <TitleText>경작 소득 분석</TitleText>
          <CancelButton
            onClick={() => {
              if (c_idx === '') {
                if (housekye !== '') {
                  nav(
                    `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}&c_idx=${c_idx}&housekey=${housekye}`,
                  );
                } else {
                  nav(
                    `/detail?type=${itemType}&pnu=${pnu}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                  );
                }
              } else {
                nav(
                  `/detail?c_idx=${c_idx}&lat=${latLng?.lat}&lng=${latLng?.lng}`,
                );
              }
            }}
          >
            <img src="/asset/images/close/close-black.svg" alt="" />
          </CancelButton>
        </TitleBox>
        <TabBox ref={tabContainerRef}>
          {tabData.map((tab, index) => (
            <TabContent
              ref={(el) => (tabRefs.current[index] = el)}
              key={tab.state}
              IsActive={selectedState === tab.state}
              onClick={() => handleStateChange(tab.state, index, 'tab')}
            >
              {tab.label}
            </TabContent>
          ))}
        </TabBox>
      </Header>
      <LandIncomeResultInnerContainer>
        <div
          style={{ scrollMarginTop: '139.33px' }}
          ref={expectedCropIncomeRef}
        >
          {cultivationIncome && (
            <ExpectedCropIncome
              cultivationIncome={cultivationIncome}
              cropName={cropName}
            />
          )}
        </div>
        <Divider />
        {surroundList && <SurroundingTrade surroundList={surroundList} />}
        <Divider />
        <div style={{ scrollMarginTop: '139.33px' }} ref={TemperatureRef}>
          {tempertureData && (
            <Temperature tempertureData={tempertureData}></Temperature>
          )}
        </div>
        <Divider />
        <div style={{ scrollMarginTop: '139.33px' }} ref={LandInfoRef}>
          {soilInfo && <LandInfo soilInfo={soilInfo}></LandInfo>}
        </div>
        <Divider />
        <div style={{ scrollMarginTop: '139.33px' }} ref={ExpectedSalesRef}>
          {expectedIncome && (
            <ExpectedSales data={expectedIncome}></ExpectedSales>
          )}
        </div>
        <Divider />
        <div style={{ scrollMarginTop: '139.33px' }} ref={ExpectedCostRef}>
          {expectedCost && (
            <ExpectedCost expectedCost={expectedCost}></ExpectedCost>
          )}
        </div>
        <Divider />
        <div style={{ scrollMarginTop: '139.33px' }} ref={ExpectedIncomeRef}>
          {expectedProfit && (
            <ExpectedIncome expectedProfit={expectedProfit}></ExpectedIncome>
          )}
        </div>
        <Divider />
        <div ref={ExpectedROIRef}>
          {expectedInvestInfo && (
            <ExpectedROI expectedInvestInfo={expectedInvestInfo} />
          )}
        </div>
        <Divider />
        <DataSource
          originData={origin}
          arrowDirection={arrowDirection}
          changeArrowDirection={changeArrowDirection}
        />
        <ReSettingBox
          onClick={() => {
            // window.location.href = '/landIncome/input';
            nav('/landIncome/input', {
              state: {
                data: data,
                c_idx: c_idx,
                pnu: pnu,
                area: area,
                avg: landPrice,
                itemType: itemType,
                housekye: housekye,
                address: address,
              },
            });
          }}
        >
          <div>맞춤 조건 재설정하기</div>
        </ReSettingBox>
      </LandIncomeResultInnerContainer>
      {loading && <Loading />}
    </LandIncomeResultContainer>
  );
};

export default LandIncomeResult;

const LandIncomeResultContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100dvh;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
`;

const Header = styled.div`
  // max-width: 720px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-bottom: 0px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
`;

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between; /* 양쪽 정렬 */
  align-items: center;
  padding: 16px;
  color: #171e26;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;

const TabContent = styled.div<TabContentProps>`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
  color: ${(props) => (props.IsActive ? 'white' : '#989ba2')};
  background-color: ${(props) => (props.IsActive ? '#222222' : null)};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  border: 1px rgba(112, 115, 124, 0.16) solid;
  border-radius: 9999px;
  white-space: nowrap;
`;

const TitleText = styled.div`
  flex-grow: 1; /* 남는 공간을 채워서 중앙 배치 */
  text-align: center; /* 텍스트 중앙 정렬 */
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #171e26;
`;

const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const TabBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px 12px;
  border-bottom: 3px rgba(23, 30, 38, 0.1) solid;
  border-top: 1px rgba(23, 30, 38, 0.1) solid;
  gap: 8px;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const LandIncomeResultInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // margin-top: 139.33px;
  background-color: white;
`;

const ReSettingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  color: white;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26.4px;
  word-wrap: break-word;
  padding: 24px 16px;
  padding-top: 0px;
  div {
    width: 100%;
    border-radius: 8px;
    background-color: rgba(30, 155, 107, 1);
    display: flex;
    justify-content: center;
    padding: 12px 16px;
  }
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 8px;
  background: #171e2610;
`;
