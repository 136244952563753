import styled from 'styled-components';

export const NoticeWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoticeList = styled.div`
  width: 100%;
  max-height: 730px;

  ul {
    margin-bottom: 40px;
    width: 100%;
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px 0px;
      border-bottom: 1px solid #e8e9ea;
      cursor: pointer;

      @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
`;

export const NoticeInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  padding: 16px 16px 80px;

  @media (min-width: 1024px) {
    padding: 80px 60px;
    max-width: 1320px;
    gap: 24px;
  }
`;

export const NoticeWrapTitle = styled.div`
  display: none;

  @media (min-width: 1024px) {
    width: 100%;
    font-family: Pretendard-bold;
    font-size: 26px;
    display: flex;
  }
`;

export const NoticeContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoticeTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const NoticeTag = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    width: 45px;
    height: 24px;
    justify-content: center;
    align-items: center;
    font-family: Pretendard-SemilBold;
    font-size: 14px;
    color: #171e26;
    border: 1px solid black;
    border-radius: 4px;
  }
`;

export const NoticeDetailContent = styled.div`
  text-align: left;
  width: 100%;
  font-family: Pretendard-Medium;
  font-size: 16px;
  padding: 32px 16px 0;
  color: #171e26;
`;

export const NoticeDate = styled.div`
  color: #7d8185;
  width: 100px;
  font-family: Pretendard-Medium;
  font-size: 14px;
`;

export const NoticeTitle = styled.div`
  line-height: 150%;
  max-width: 296px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #171e26;

  @media (min-width: 768px) {
    max-width: 640px;
  }
`;

export const NoticeDetailTitle = styled.div`
  line-height: 150%;
  max-width: 296px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #171e26;

  @media (min-width: 768px) {
    max-width: 640px;
  }
`;

export const NoticeDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (min-width: 1024px) {
    border-top: 1px solid #171e26;
  }
`;

export const NoticeDetail = styled.div`
  border: none;
  width: 100%;
`;

export const NoticeDetailItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #e8e9ea;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const ToTheListBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 328px;
    height: 54px;
    background-color: white;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    cursor: pointer;
    font-family: Pretendard-Bold;
    font-size: 16px;
  }
`;

export const TopLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #171e26;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const TopNav2 = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 16px;

  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  span {
    display: flex;
    align-items: center;
  }
`;
